import React, { createContext, useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ToastProvider from "./HOC/ToastProvider";
import authRoutes from "./routers/authRoutes";
import commonRoutes from "./routers/commonRoutes";
import brandRoutes from "./routers/privateRoute/brandRoutes";
import adminRoutes from "./routers/adminRoutes";
import creatorRoutes from "./routers/privateRoute/creatorRoutes";
import schoolRoutes from "./routers/privateRoute/schoolRoutes";
import GlobalLoader from "./components/loaders/GlobalLoader";
import { AuthProvider } from "./contexts/AuthContext";
import "./App.css";
import { SearchProvider } from "./contexts/SearchContext";

export const SidebarContext = createContext();


function App() {
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);
  const [router, setRouter] = useState(null);
  const [globalLoader, setGlobalLoader] = useState(false)
  const toggleSidebar = () => {
    setIsSidebarClosed(!isSidebarClosed);
  };

  const [refresh, setRefresh] = useState(false)

  const userRole = localStorage.getItem("userRole");


  useEffect(() => {
    let routes = [
      {
        path: "/",
        children: [...authRoutes, ...commonRoutes],
      },
    ];
    if (userRole === "brand") {
      routes[0].children = [...routes[0].children, ...brandRoutes, ...adminRoutes];
    } else if (userRole === "creator") {
      routes[0].children = [...routes[0].children, ...creatorRoutes];
    } else if (userRole === 'school') {
      routes[0].children = [...routes[0].children, ...schoolRoutes];
    } else if (userRole === 'admin') {
      routes[0].children = [...routes[0].children, ...authRoutes, ...adminRoutes];
    }

    setRouter(createBrowserRouter(routes));
  }, [userRole, refresh]);

  if (!router) {
    return <div></div>;
  }

  return (
    <ToastProvider>
      <GlobalLoader isLoading={globalLoader} />
      <AuthProvider>
      <SearchProvider>
        <SidebarContext.Provider value={{ isSidebarClosed, toggleSidebar, refresh, setRefresh, globalLoader, setGlobalLoader }}>
          <RouterProvider router={router} />
        </SidebarContext.Provider>
        </SearchProvider>
      </AuthProvider>
    </ToastProvider>
  );
}
export default App;


