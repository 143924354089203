import React from 'react'

function ReplaceIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1413 10.2594H1.51892M1.51892 10.2594L4.17452 7.60384M1.51892 10.2594L4.17452 12.915M1.51892 3.62044H12.1413M12.1413 3.62044L9.48572 0.964844M12.1413 3.62044L9.48572 6.27604" stroke="white" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default ReplaceIcon