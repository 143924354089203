import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./CommissionListing.css";
import LoadingWrapper from "./loadingWrapper/LoadingWrapper";

import CardContainer from "./CardContainer";
import { getAllCommissionWithImages } from "../services/brandServices";
import MediaGridCard from "./cards/MediaGridCard";

function CommissionListing({displayMore=false, commissions=[], isLatestCommission = false }) {

  return (
    <>
      {isLatestCommission && <div className="intro-commission">
        <div className="new-symbol">NEW</div>
        <div className="new-commission-text">
          Your latest commission is ready!
        </div>
      </div>}
      <CardContainer displayMore={displayMore} cards={commissions} />
    </>
  );
}

export default CommissionListing;
