import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import CommissionTabView from '../../CommissionTabView/CommissionTabView';
import CommissionReviewAndContent from '../../commission-content/CommissionReviewAndContent';

function CommissionDetails() {
    const [showBreadCrumb, setShowBreadCrumb] = useState(true)
    const location = useLocation();
    const commissionToDisplay = location.state?.commissionToDisplay
    const hideBreadCrumb  = location.state?.hideBreadCrumb
  
    useEffect(() => {
      let id
      if (hideBreadCrumb) {
        id = setTimeout(() => setShowBreadCrumb(true), 15000)
        setShowBreadCrumb(false)
      }
      return () => {
        clearTimeout(id)
      }
    }, [])
  
    return (
      <div className="w-full ps-12">
        {showBreadCrumb && <Breadcrumb
          paths={[
            { name: "Home", link: "/galleries" },
            { name: "Commissions", link: "/commissions" },
            { name: "Content", link: "/details" },
          ]}
        />}
        {
            !!commissionToDisplay &&
            <CommissionTabView commissionToDisplay={commissionToDisplay} />
        }

        {
            <CommissionReviewAndContent isAccepted={commissionToDisplay?.isAccepted} />
        }
      </div>
    );
}

export default CommissionDetails