import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import useToast from '../../../hooks/useToast';
import CrossIcon from '../../../icons/CrossIcon';
import { validateEmail } from '../../../utils/validateEmail';
import { sendInviteContributorsMail } from '../../../services/schoolServices';
import { SidebarContext } from '../../../App';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
const InviteContributors = () => {
    const navigate = useNavigate()
    const [emails, setEmails] = useState([])
    const [message, setMessage] = useState([])
    const [emailValue, setEmailValue] = useState('')
    const [disableCopySection, setDisableCopySection] = useState(true)
    const showToast = useToast()
    const [links, setLinks] = useState([
        // { value: "invitationLink", label: "email" }
    ])
    const [copyText, setCopyText] = useState('')
    const { setGlobalLoader } = useContext(SidebarContext)
    const { username: authMail, userType } = useSelector(state => state.user)
    const location = useLocation();
    const { commissionXrefId } = location.state || {};
    const commissionToDisplay = location.state?.commissionToDisplay || {}



    const handleSendInvitation = async () => {
        if (!commissionXrefId) {
            showToast("Project ID is required", "error");
            return;
        }
        if (!authMail?.length || !emails.length) {
            showToast("Organization email and invite mails are required")
            return
        }

        try {
            setGlobalLoader(true)
            const payload = {
                "emails": emails,
                "invitedBy": authMail,
                "projectId": commissionXrefId,
                "message": message
            }


            let res
            if (userType === 'school') {
                res = await sendInviteContributorsMail({ payload })
            }
            const invitedMails = res.map(item => item.label)
            const failedInviteMails = emails.filter(item => !invitedMails.includes(item))
            setEmails(prev => prev.filter(item => failedInviteMails.includes(item)))
            setMessage("");
            setEmails([]);
            showToast("Invitation link sent", "success")
            setLinks(res)
            setDisableCopySection(false)
            navigate(`/school/project-details/${commissionXrefId}`, { state: { commissionToDisplay, messageType: "success", message: "Success! You have successfully invited contributor" } });
            if (!failedInviteMails.length) {
                return true
            }
            showToast(`Invitation failed for: ${failedInviteMails.join(', ')}`, "error")
            return false
        } catch (e) {
            console.log(e, "err in inviteTeammates")
            showToast('Something went wrong', 'error')
        } finally {
            setGlobalLoader(false)
        }
    }
    const handleAddEmail = () => {
        const trimmedEmail = emailValue.trim();
        if (trimmedEmail) {
            if (validateEmail(trimmedEmail)) {
                setEmails([...emails, trimmedEmail]);
                setEmailValue('');
            } else {
                showToast('Enter Valid email.', 'error');
            }
        }
    };

    return (
        <div className="p-8">
            <div className="flex items-center space-x-2 mb-4">
                <button onClick={() => navigate(-1)} className="text-[#6941C6]">
                    <span className="text-4xl relative -top-1">&#8592;</span>
                </button>
                <h1 className="font-inter text-[30px] font-semibold leading-[44px] tracking-[-0.02em] text-left decoration-skip-ink text-[#101828]">
                    Invite contributors
                </h1>
            </div>
            <p className="text-[#475467] font-sans text-[20px] font-normal leading-6 mb-8">
                Invite artists you would like to participate in this project
            </p>
            <div className="mb-6 flex gap-4 w-1/2 flex-col">
                {/* <div className='flex gap-4 w-1/2 flex-col '> */}
                <div className="flex flex-col">
                    <p className="mb-1 text-slate-700">Invite with email</p>
                    <div className='border-1 min-h-[20vh] p-3 '>
                        <div className='flex flex-wrap gap-3'>

                            {
                                emails && emails.map((item, index) => {
                                    return (
                                        item &&
                                        <div className='rounded-lg border-1 p-2 flex items-center' key={index}>
                                            <span>
                                                {item}
                                            </span>
                                            <span className='w-[20px] ms-2 cursor-pointer  ' onClick={() => {
                                                setEmails(emails.filter((itesm, ind) => ind !== index));
                                                setLinks(emails.filter((itesm, ind) => ind !== index));

                                            }}>
                                                <CrossIcon />
                                            </span>
                                        </div>
                                    )
                                })
                            }
                             <input
                    className='outline-none border-b-2'
                    onChange={(e) => setEmailValue(e.target.value.replace(/^\s+/, ''))}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') handleAddEmail();
                    }}
                    onBlur={handleAddEmail}
                    value={emailValue}
                    placeholder='Enter email'
                />
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-col">
                    <p className="mb-1 text-slate-700">Your message</p>


                    <textarea
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        placeholder="Enter your message here"
                        className=" p-3 border border-gray-300 rounded-lg focus:outline-none text-[#667085]"
                        rows="10"
                    ></textarea>


                </div> */}

                <PrimaryButton
                    onClick={handleSendInvitation}
                    className={`w-[164.47px] h-[41.92px] ms-auto px-[14.64px] py-[10.46px] bg-[#7F56D9] rounded-md border-opacity-0 text-m font-semibold shadow-md border-t-[1.05px] border-transparent opacity-100 flex items-center justify-center z-100`}
                    text="Invite"
                />
            </div>

        </div>

    );
};

export default InviteContributors;
