import React, { useEffect, useState } from 'react'
import { DownloadIcon } from './SendIcon'
import CrossInCircleIcon from './icons/CrossInCircleIcon'
import { downloadImageUtil } from '../../utils/downloadImage'
import HeartIcon from './icons/HeartIcon';
import { rejectCommissionContent, saveFavorites } from '../../services/brandServices';
import useToast from '../../hooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRevisionData, setRevisionData } from '../../redux/reducerFiles/commission-content/commissionContentReducer';


function BrandOptions({ contentDetail, contentId }) {  
  

  const [isRejected, setIsRejected] = useState(contentDetail?.is_rejected ? true : false)
  const {commissionDetails} = useSelector(state=>state.commissionContent)
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isFavourite, setIsFavourite] = useState(contentDetail?.isFavourite ? true : false)
  const [isFavouriteLoading, setIsFavouriteLoading] = useState(false)
  const showToast = useToast()
  const { brandId } = useSelector(state => state.user)
  const location = useLocation()
  const pageType = location?.state?.pageType || ''
  const revisionData = useSelector((state) => getRevisionData(state, pageType));
  let revision = pageType === 'revision_1' ? 'revision1' : pageType === 'revision_2' ? 'revision2' : 'finalWork'
  const dispatch = useDispatch()
  const imageUrl = contentDetail?.thumbnail_url || contentDetail?.url;
  const originalImageUrl = contentDetail?.url
  const imageName = contentDetail?.id

  const rejectContent = async (contentId) => {
    try {
      setIsLoading(true);
      await rejectCommissionContent(contentId,!isRejected)
      const updatedData = revisionData?.data?.map((item) =>
        item.id === contentId ? { ...item, is_rejected: !isRejected } : item
      )
      dispatch(setRevisionData({ revision, data: updatedData,is_rejected:!isRejected }))
      setIsRejected(!isRejected)

      showToast("Content rejected successfully!", "success");
    } catch (error) {
      setIsLoading(false);
      showToast("Failed to reject content.", "error");
    } finally {
      setIsLoading(false);
    }
  };


  const handleFavourite = async () => {
    if (isFavouriteLoading) return
    try {
      setIsFavouriteLoading(true);
      await saveFavorites({ type: 'commission', brand_id: brandId, content_id: contentId })
      setIsFavourite(!isFavourite)
      const updatedData = revisionData?.data?.map((item) =>
        item.id === contentId ? { ...item, isFavourite: !isFavourite } : item
      )
      dispatch(setRevisionData({ revision, data: updatedData }))
    } catch (error) {
      setIsFavouriteLoading(false);
      showToast("Failed to favourite this content.", "error");
    } finally {
      setIsFavouriteLoading(false);
    }
  }

  useEffect(()=>{
    setIsFavourite(contentDetail?.isFavourite ? true : false)
    setIsRejected(contentDetail?.is_rejected ? true : false)
  },[contentId])


  return (
    <ul className='mb-0 flex gap-4 items-center'>
      <li onClick={handleFavourite} className={`flex items-center gap-2 p-2 rounded-full  ${commissionDetails.isSubmitted  ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}>
        <HeartIcon filled={isFavourite} /> <span className='text-xl text-[#FFFFFF]'>{isFavouriteLoading ? isFavourite ? 'Loading ...' : 'Loading ...' : isFavourite ?  'Unfavourite' : 'Favourite'}</span>
      </li>
      <li className={`flex items-center gap-2 p-2 rounded-full cursor-pointer ${commissionDetails.isSubmitted || isDownload ? "cursor-not-allowed" : ""}`} onClick={() => !isDownload && downloadImageUtil({ setLoading: setIsDownload, imageUrl: originalImageUrl || imageUrl, imageName })}>
        <DownloadIcon /> <span className='text-xl text-[#FFFFFF]'>{isDownload ? "Downloading..." : "Download"}</span>
      </li>
      <li className={`flex items-center gap-2 p-2 rounded-full ${commissionDetails.isSubmitted ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} onClick={
        () => rejectContent(contentId)}>
        <CrossInCircleIcon /><span className='text-xl text-[#FFFFFF]'>{ isLoading ? isRejected ? 'Loading ...' : 'Loading ...': isRejected ? 'Rejected' : "Reject"}</span>
      </li>
      <span className="font-medium text-xl text-[#FFFFFF]">{contentDetail?.creator_name || ""}</span>
    </ul>
  )
}

export default BrandOptions