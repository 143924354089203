import React, { useState, useEffect } from "react";
import "./Home.css"; // Import CSS file
import EditIcon from "../../../icons/Edit";
import { Link, useNavigate } from "react-router-dom";
import ImageGallery from "../../../components/Galleries";
import { useSelector } from "react-redux";
import CommissionListing from "../../../components/CommissionListing";
import LoadingWrapper from "../../../components/loadingWrapper/LoadingWrapper";
import { getAllCommissionWithImages } from "../../../services/brandServices";
import NoCommission from "../../../components/cards/NoCommission";

const Home = () => {
  const [commissionData, setCommissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const { username, userId, brandId, profileUrl } = useSelector((state) => state.user);
  const userRole = localStorage.getItem("userRole");
  const state = useSelector(state => state.user)
  const navigate = useNavigate()
  const [isLatestCommission, setIsLatestCommission] = useState(true)




  useEffect(() => {
    if (commissionData && commissionData.length > 0) {
      const checkExpire7day = (createdDate) => {
        if (!createdDate) return false;
      
        // Ensure createdDate is parsed correctly
        const createdAt = new Date(createdDate);
        const currentDate = new Date();
      
        if (isNaN(createdAt.getTime())) {
          return false;
        }
      
        // Calculate the difference in days
        const diffInMilliseconds = currentDate - createdAt;
        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
        return diffInDays > 7?false:true;
      };
      const comm = commissionData[0];
      const submitted_at = comm?.submitted_at;
      const expired = checkExpire7day(submitted_at);
      setIsLatestCommission(expired);
    }
  }, [commissionData]);

  useEffect(() => {
    if (userRole == "creator") {
      navigate('/creator')
    } else {

    }
    const fetchAllCommission = async () => {
      try {
        const data = await getAllCommissionWithImages({ brandId, status: "submitted", media_content_per_commission: 6 });
        setCommissionData(data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }

    }
    fetchAllCommission()
  }, [brandId])

  // Check if there are commissions
  const hasCommission = commissionData && commissionData.length > 0;
  return (
    <div className="content-area">
      <div className="row m-0 ">
        <div className="col-lg-12 px-0 ">
          <div className="h-[185px] relative">
            <img
              src="/home/header.png"
              alt="Home Page Banner"
              className="w-full max-h-full min-h-full object-cover"
            />
            <div className="rounded-full absolute z-10 overflow-hidden top-[98px] w-[174px] h-[174px] left-[50px] ">
              {
                profileUrl?.length ?
                  <img src={profileUrl} className="w-full h-full rounded-full object-cover" alt="Circle Page Banner" />
                  :
                  <img src="/home/circle2.png" className="max-w-[100%] scale-[103%] object-cover" alt="Circle Page Banner" />
              }
            </div>
          </div>
        </div>

        <div className="col-lg-12 mt-3 mb-5 custom-max-width">
          <div className="row align-items-center">
            <div className="col-lg-12 ">
              <div className="flex justify-between">
                <div className='pl-[225px]'><p className='text-[30px] font-[600] text-primary-black'>{username}</p></div>
                <div className="button-placement pt-2">
                  <Link to="/my-content" style={{ textDecoration: "none" }}>
                    <button className="button-secondary-sw text-primary-black ">
                      View Content
                    </button>
                  </Link>
                  <Link
                    to="/commissions/create"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="button-primary-sw ">New Commission</button>
                  </Link>
                </div>
              </div>
              {!isLoading && !hasCommission &&
                <NoCommission
                  Icon={EditIcon}
                  mainText="You do not have any art commissioned yet."
                  supportingText="Dive into the editor and start creating"
                />}
            </div>
          </div>
        </div>

        <div className="col-lg-12 mt-3 custom-max-width">
          <LoadingWrapper loading={isLoading}>
            {hasCommission && (
              <CommissionListing displayMore={true} commissions={[commissionData[0]]} isLatestCommission={isLatestCommission} />
            )}
          </LoadingWrapper>
          <ImageGallery filterDropDownKey="filter_for_home" shopButton />
          {/*<div className="flex justify-center mt-3">
            <button onClick={() => navigate('/shop')} className="custom-border-violet rounded-lg px-3 py-2 text-violet">View more in the shop</button>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Home;
