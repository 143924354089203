import React from 'react';


function Filter() {
    return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.14732 11.0039H15.3772M2.58984 5.88892H17.9347M7.7048 16.1188H12.8198" stroke="#344054" stroke-width="1.70499" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Filter