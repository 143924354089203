import React from 'react'

function CrossInCircleIcon({ color = "white", size = 24, fill = "white"}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="10" fill="none" stroke={color} stroke-width="2" />
            <path d="M15.535 8.465a1 1 0 0 0-1.414 0L12 10.586 9.879 8.465a1 1 0 0 0-1.414 1.414L10.586 12l-2.121 2.121a1 1 0 0 0 1.414 1.414L12 13.414l2.121 2.121a1 1 0 0 0 1.414-1.414L13.414 12l2.121-2.121a1 1 0 0 0 0-1.414z" fill={fill} />
        </svg>
    )
}

export default CrossInCircleIcon