import React from "react"

const ProgressBar = ({ title, progress }) => {
  return (
    <div className="w-full bg-gray-100 rounded p-2 hover:bg-gray-200 transition-colors duration-300">
      <h2 className="text-sm font-medium text-gray-800 mb-1 truncate">{title}</h2>
      <div className="w-full bg-gray-200 rounded-full h-2">
        <div
          className="bg-purple-600 h-full rounded-full transition-all duration-500 ease-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="text-right text-xs font-medium text-purple-600 mt-1">{progress}%</p>
    </div>
  )
}

const ProgressBarList = ({ progressData }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-xl p-4 max-h-[60vh] w-[90vw] max-w-sm overflow-y-auto">
        <div className="space-y-3">
          {Object.values(progressData)?.map((item, index) => (
            <ProgressBar key={index} title={item.title} progress={item.progress} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProgressBarList

