"use client";

import { Navigate } from "react-router-dom";
import { BackIcon } from "./SendIcon";
import { useEffect, useState } from "react";
import HeartIcon from "./icons/HeartIcon";
import { saveFavorites } from "../../services/brandServices";
import { useSelector } from "react-redux";
import useToast from "../../hooks/useToast";

export default function ImageReview({ src, onClose, media }) {
  const { brandId } = useSelector((state) => state.user);
  const [isFavouriteLoading, setIsFavouriteLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState(media.isFavorite);
  const showToast = useToast();

  const handleFavourite = async () => {
    if (isFavouriteLoading) return;
    try {
      await saveFavorites({
        type: "commission",
        brand_id: brandId,
        content_id: media.id,
      });
      setIsFavourite(!isFavourite);
    } catch (error) {
      setIsFavouriteLoading(false);
      showToast("Failed to favourite this content.", "error");
    } finally {
      setIsFavouriteLoading(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    // if (showComments) {
    // } else {
    //   document.body.style.overflow = "auto";
    // }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed inset-0 z-[99999] h-screen bg-black">
      {/* Main Content Container */}
      <div className="w-full py-2 justify-between fixed top-0 flex px-4">
        <button onClick={onClose}>
          <BackIcon />
        </button>
        <button onClick={onClose}>
          <svg
            className="w-6 h-6 text-white cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="w-full h-full flex flex-row">
        <div className="w-full"></div>
        <img src={src} alt="" className="h-full aspect-auto mx-auto" />
        <button  className="mt-auto text-white text-start w-full mr-auto m-4" onClick={handleFavourite}>
          <HeartIcon filled={isFavourite}  />
        </button>
      </div>
    </div>
  );
}

// <div
//   className="fixed inset-0 bg-black w-screen h-screen flex items-center justify-center"
//   onClick={onClose} // Close when clicking the black background
// >
//   <div
//     className="relative w-full h-full"
//     onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
//   >
//     {/* Navigation */}
//     <div className="absolute left-4 top-4 z-10">
//       <span
//         className="text-green-400 cursor-pointer text-lg"
//         onClick={onClose}
//       >
//         Back
//       </span>
//     </div>

//     <div className="absolute right-4 top-4 z-10">
//       <span className="text-white cursor-pointer text-lg" onClick={onClose}>
//         Close
//       </span>
//     </div>

//     {/* Full-Screen Image */}
//     <div className="absolute inset-0">
//       <img
//         src={src}
//         alt="Expanded image"
//         className="w-full h-full object-cover" // Ensures the image fills the full screen
//       />
//     </div>
//   </div>
