import React, { useEffect, useState } from 'react'
import LoadingWrapper from '../../../components/loadingWrapper/LoadingWrapper';
import MediaGridCard from '../../../components/cards/MediaGridCard';
import { useSelector } from 'react-redux';
import useDebounce from '../../../utils/debounce';
import { getAllProjectsByCreatorId } from '../../../services/creatorService';
import OutllinedPrimaryBtn from '../../../components/buttons/OutllinedPrimaryBtn';
import { useNavigate } from 'react-router-dom';

const ProjectListing = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState([])

    const LIMIT = 6
    const { creatorId, userId } = useSelector(state => state.user);
    const userData = useSelector(state => state.user);
    const [searchText, setSearchText] = useState('')
    const [offset, setOffset] = useState(0)
    const [fetchMore, setFetchMore] = useState(true)
    const debounceSearchText = useDebounce(searchText, 500);



    const getProjectsByCreatorId = async ({ limit, offset, creator_id, searchText }) => {

        try {
            setIsLoading(true)
            const projectData = await getAllProjectsByCreatorId({ limit, offset, creator_id, searchText, projectStatus: "in_progress" })// getCollectionById('01J4RJH225DDVFB1AP9SZNKMGN')
            setOffset(prev => prev + LIMIT)
            setFetchMore(!((projectData?.data.length < LIMIT) || (projectData?.data.length === 0)));
            const data = searchText ? [...projectData?.data] : [...projects, ...projectData?.data]

            setProjects(data)
            setIsLoading(false);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }

    }


    useEffect(() => {
        const offset = 0
        setOffset(0)
        getProjectsByCreatorId({ limit: LIMIT, offset, creator_id: userId, searchText: debounceSearchText })
    }, [debounceSearchText, userId])

    async function handleLoadMore() {
        if (!fetchMore) return
        getProjectsByCreatorId({ limit: LIMIT, offset, creator_id: userId, searchText: debounceSearchText })
    }

    return (
        <div className='w-full flex flex-col gap-6 mt-16'>
            <div className="ml-12 pr-4">
                <p className="text-4xl text-slate-700 font-bold">My projects</p>
                {
                    !!projects?.length && 
                    <p className='text-gray-400 p-0 text-[20px]'> You have been invited to participate in these projects</p>
                }
                <div className="flex flex-col gap-y-16 mt-12">
                    <LoadingWrapper loading={isLoading}>
                        <div className='card-container mx-0 px-0 grid grid-cols-3 '>
                            {
                               ( projects && projects.length > 0) ?
                                    projects?.map(card => (
                                        <MediaGridCard
                                            containerClass="h-[300px] w-full"
                                            mediaCount={card?.images?.length || 0}
                                            mediaItems={card?.images || []}
                                            onCardClick={() => {
                                                navigate(`/creator/project-details/${card?.commissionSchoolXrefId}`, { state: {commissionToDisplay:card, isCreator: true} })
                                            }}
                                        >
                                            <div className="flex flex-col">
                                                <p className="text-[20px] font-[600] text-primary-black mt-3">{card.name || "Project Name"}</p>
                                                <div className="flex flex-row gap-4 justify-between">
                                                    <p className="flex flex-row gap-2">
                                                        <span className="text-lg text-slate-700">
                                                            {card?.school_name || " ORG name"}
                                                        </span>
                                                    </p>
                                                    <p className="flex flex-row gap-2">
                                                        <span className="text-lg text-slate-700">
                                                            {card?.invited_by || " Invited by "}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </MediaGridCard>
                                    ))
                                    :
                                    <p className="text-xl text-slate-600 -mt-6">No Projects </p>
                            }
                           

                        </div>

                    </LoadingWrapper>
                    {!isLoading && fetchMore &&
                        <div className="w-full flex justify-center">
                            {
                                projects.length ?
                                    <OutllinedPrimaryBtn onClick={handleLoadMore} style={{ width: '100px' }} disabled={!fetchMore} label='Load more' />
                                    : null
                            }
                        </div>}

                </div>
            </div>
        </div>
    )
}

export default ProjectListing