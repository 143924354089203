import React, { useEffect, useState } from 'react'
import { getAllProjectsBySchoolId } from '../../../services/schoolServices'
import { useNavigate } from 'react-router-dom'
import useDebounce from '../../../utils/debounce'
import { getLocalStorage, setLocalStorage } from '../../../lib/session'
import { useDispatch, useSelector } from 'react-redux'
import LoadingWrapper from '../../../components/loadingWrapper/LoadingWrapper'
import OutllinedPrimaryBtn from '../../../components/buttons/OutllinedPrimaryBtn'
import { LOADING } from './SchoolHomePage'
import MediaGridCard from '../../../components/cards/MediaGridCard'
import moment from 'moment'
import AccountIcon from '../../../icons/AccountIcon'
import CalenderIcon from '../../../icons/CalenderIcon'
import { setCommissionDetails } from '../../../redux/reducerFiles/commission-content/commissionContentReducer'

function ProjectList({ listTitle = '', projectStatus = 'in_progress', setHaveProjects, haveProjects }) {
    const { commissionDetails } = useSelector((state) => state.commissionContent);

    const navigate = useNavigate()
    const LIMIT = 6
    const [startScreen, setStartScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { schoolId, userId } = useSelector(state => state.user);
    const [searchText, setSearchText] = useState('')
    const [collections, setCollections] = useState([])
    const [offset, setOffset] = useState(0)
    const [fetchMore, setFetchMore] = useState(true)
    const isNew = getLocalStorage('isNew')
    const debounceSearchText = useDebounce(searchText, 500);
    const dispatch = useDispatch()


    const getProjectsBySchoolId = async ({ limit, offset, school_id, searchText }) => {

        try {
            setIsLoading(true)
            const collectionData = await getAllProjectsBySchoolId({ limit, offset, school_id, searchText, projectStatus })// getCollectionById('01J4RJH225DDVFB1AP9SZNKMGN')
            setStartScreen(collectionData?.data.length === 0);
            setOffset(prev => prev + LIMIT)
            setFetchMore(!((collectionData?.data.length < LIMIT) || (collectionData?.data.length === 0)));
            const data = searchText ? [...collectionData?.data] : [...collections, ...collectionData?.data]
            if(haveProjects=== LOADING){
                if (data?.length > 0) {
                    setHaveProjects(true)
                } else{
                    setHaveProjects(false)
                }
            }
            setCollections(data)
            setIsLoading(false);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }

    }


    useEffect(() => {
        const offset = 0
        setOffset(0)
        getProjectsBySchoolId({ limit: LIMIT, offset, school_id: schoolId, searchText: debounceSearchText })
    }, [debounceSearchText, userId])

    async function handleLoadMore() {
        if (!fetchMore) return
        getProjectsBySchoolId({ limit: LIMIT, offset, school_id: schoolId, searchText: debounceSearchText })
    }

    return (
        <div className="flex flex-col gap-y-4">
            <p className="font-semibold text-3xl">{listTitle}</p>
            <LoadingWrapper loading={isLoading}>
                <div className='card-container mx-0 px-0 grid grid-cols-3 '>
                    {
                        collections?.map(card => (
                            <MediaGridCard
                                containerClass="h-[300px] w-full"
                                mediaCount={card.images.length}
                                mediaItems={card.images}
                                onCardClick={()=>{
                                    dispatch(setCommissionDetails(card));
                                    navigate(`/school/project-details/${card?.commissionSchoolXrefId}`,{state:{commissionToDisplay:card}})
                                }}
                            >
                                <div className="flex flex-col">
                                    <p className="text-[20px] font-[600] text-primary-black mt-3">{card.name}</p>
                                    <div className="flex flex-row gap-4 justify-between">
                                    <p className="flex flex-row gap-2">
                                        <CalenderIcon />
                                        <span className="text-lg text-slate-700">
                                        {moment(card.startDate).format('MMM DD')}-{moment(card.endDate).format('MMM DD, YYYY')}
                                        </span>
                                    </p>
                                    <p className="flex flex-row gap-2">
                                        <AccountIcon />
                                        <span className="text-lg text-slate-700">
                                        {card.participantsCount} participants
                                        </span>
                                    </p>
                                    </div>
                                </div>
                            </MediaGridCard>
                        ))
                    }
                    {
                        !collections?.length &&
                        <p className="text-xl text-slate-600 -mt-6">No Projects</p>
                    }
                </div>
            </LoadingWrapper>
            {!isLoading && fetchMore && 
            <div className="w-full flex justify-center">
                {
                    collections.length ?
                        <OutllinedPrimaryBtn onClick={handleLoadMore} style={{ width: '100px' }} disabled={!fetchMore} label='Load more' />
                        : null
                }
            </div>}
        </div>
    )
}

export default ProjectList