import React, { useEffect, useState } from 'react'
import { StyledTableBodyCell, StyledTableHeadCell } from '../StyledTableCell/StyledTableCell'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCommission } from '../../services/brandServices';
import moment from 'moment';
import Badge from '../badge';
import DatePicker from '../dateRangePicker.js/dateRangePicker';
import LoadingWrapper from '../loadingWrapper/LoadingWrapper';
import CustomDateRangePicker from '../dateRangePicker.js/dateRangePicker/CustomDateRangePicker';
import Filter from '../../icons/Filter';
import { setCommissionDetails } from '../../redux/reducerFiles/commission-content/commissionContentReducer';


// status ====== in_progress or submitted
function CommissionListingTable({ status }) {
    const [commissions, setCommissions] = useState([]);
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [openCalendar, setOpenCalender] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const [isLoading, setIsLoading] = useState(false);
    const [showNotify, setShowNotify] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const message = location.state?.message
    const messageType = location.state?.messageType
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const dispatch = useDispatch()
    useEffect(() => {

        let id
        if (message) {
            id = setTimeout(() => { setShowNotify(false) }, 15000)
            setShowNotify(true)
        }
        return () => {
            clearTimeout(id)
        }

    }, [message])

    const { username, brandId } = useSelector((state) => state.user);

    const fetchCommissions = async (fromDateFilter = false) => {
        try {
            setIsLoading(true)
            const response = await getAllCommission({status, user_id: brandId, page: currentPage, limit: itemsPerPage, 
            dateRanges: dateRange.startDate && dateRange.endDate
                ? {
                      startDate: new Date(dateRange.startDate).toISOString(),
                      endDate: new Date(dateRange.endDate).toISOString(),
                  }
                : null, });
            if (response?.data?.length) {
                const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setCommissions(sortedData);
            }
            else {
                setCommissions([{}, {}, {}]);
            }
            setTotalCount(response.totalCount);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error("Error fetching commissions", error);
        }
    };

    useEffect(() => {
        fetchCommissions();
    }, [username, currentPage,dateRange]);

    // const handleCalendarClose = () => {
    //     fetchCommissions(true);
    // }
    

    const totalPages = Math.ceil(totalCount / itemsPerPage);

    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers.map((number) => (
            <li
                key={number}
                id={number}
                onClick={handleClick}
                className={currentPage === number ? "active" : null}
            >
                {number}
            </li>
        ));
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleViewClick = (commission) => {
        dispatch(setCommissionDetails({...commission,cs_id:commission.commissionSchoolXrefId}));
        navigate('/commissions/details',{state:{commissionToDisplay:commission}})
    };

    const makeTableData = (field) => {
        if (field) return field
        else return '-'
    }

    const statusTypeMapping = {
        in_progress: 'info',
        submitted: 'success',
    };


    return (
        <>
        <div className='flex justify-end gap-2 mt-3 w-auto'>
                <CustomDateRangePicker 
                setDateRange={setDateRange}
                setFrom={setFrom}
                setTo={setTo}
                selectionRange={dateRange}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                showCalendarIcon={true}
                />
                <div className="flex px-2 py-1 rounded-md text-[14px] font-[600] gap-2 items-center border border-silver cursor-pointer">
                <Filter />
                <span>Filter</span>
              </div>
                </div>
        <LoadingWrapper loading={isLoading}>
            {
                !commissions?.length ?
                    <p className="noActiveCommissionText">You don't have any active commissions</p>
                    : null
            }
            <div className="table-responsives mt-3">
                {/* <div className="w-full flex justify-between my-2 items-end">
                    <div className="flex flex-col gap-3 h-[85px]">
                        <p className="text-[#101828] text-[18px] my-auto font-semibold">{status==='history' ? "Commission history":"Active Commissions"}</p>
                        {
                            !commissions?.length ?
                                <p className="font-normal text-[16px] text-[#475467] my-0">Nothing to show here yet</p>
                                : null
                        }
                    </div>
                </div> */}
                <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse', border: '2px solid #0c0d0f' }}>
                        <thead>
                            <tr className="font-style-grid">
                                <StyledTableHeadCell>Name</StyledTableHeadCell>
                                <StyledTableHeadCell>Due date</StyledTableHeadCell>
                                <StyledTableHeadCell>Amount Paid</StyledTableHeadCell>
                                <StyledTableHeadCell>Type</StyledTableHeadCell>
                                <StyledTableHeadCell>Status</StyledTableHeadCell>
                                <StyledTableHeadCell>Details</StyledTableHeadCell>
                            </tr>
                        </thead>
                        <tbody>
                            {commissions.map((commission, index) => (
                                <tr
                                    key={commission.id}
                                    className={
                                        index % 2 === 0
                                            ? "odd-row font-style-grid"
                                            : "even-row font-style-grid"
                                    }
                                >
                                    <StyledTableBodyCell >
                                    <button className="bg-transparent text-violet font-semibold text-left truncate w-full" onClick={() => handleViewClick(commission)}>
                                        {makeTableData(commission.name)}
                                        </button>
                                    </StyledTableBodyCell>
                                    <StyledTableBodyCell>
                                        {
                                            commission.end_date ?
                                                moment(commission.end_date).format('MMMM DD, YYYY')
                                                : '-'
                                        }
                                    </StyledTableBodyCell>
                                    <StyledTableBodyCell style={{ whiteSpace: "pre-wrap" }}>
                                        {makeTableData(commission.id ? "Monthly subscription":'-')}
                                    </StyledTableBodyCell>
                                    <StyledTableBodyCell style={{ whiteSpace: "pre-wrap" }}>
                                        {makeTableData(commission.media_type)?.replace(/,/g, ",\n")}
                                    </StyledTableBodyCell>
                                    <StyledTableBodyCell className="uppercase">
                                        {
                                            commission.id ? 
                                            <Badge
                                                text={makeTableData((commission.status === "submitted") ? "Completed" : "in progress")}
                                                type={statusTypeMapping[commission.status]}
                                                width="125px"
                                            /> :
                                            '-'
                                        }
                                    </StyledTableBodyCell>
                                    <StyledTableBodyCell>
                                        <button className="bg-none text-violet font-[600]" onClick={() => handleViewClick(commission)}>
                                            {Object.values(commission)?.length ? 'VIEW' : '-'}
                                        </button>
                                    </StyledTableBodyCell>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                !!totalCount &&
                <div className="pagination-container mt-4">
                    <button
                        className="previous-button"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="arrow-left">
                                <path
                                    id="Icon"
                                    d="M16.0436 10.8603H4.37695M4.37695 10.8603L10.2103 16.6936M4.37695 10.8603L10.2103 5.02698"
                                    stroke="#344054"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </g>
                        </svg>
                        Previous
                    </button>
                    <ul className="pagination">{renderPageNumbers()}</ul>
                    <button
                        className="next-button"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                        <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="arrow-right">
                                <path
                                    id="Icon"
                                    d="M4.37646 10.8603H16.0431M16.0431 10.8603L10.2098 5.02698M16.0431 10.8603L10.2098 16.6936"
                                    stroke="#344054"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </g>
                        </svg>
                    </button>
                </div>
            }
        </LoadingWrapper>
        </>
    )
}

export default CommissionListingTable