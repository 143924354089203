import PrivateRoute from "../../HOC/PrivateRouteLayout";
import RootLayout from "../../pages/Root";
import SchoolProjectContentForm from "../../pages/school/upload-project-content/SchoolProjectContentForm";
import SchoolHomePage from "../../pages/school/home/SchoolHomePage";
import GalleryCollectionPreviewForSchool from "../../pages/school/my-gallery/GalleryCollectionPreviewForSchool";
import GalleryCollectionDetailsForSchool from "../../pages/school/my-gallery/GalleryCollectionDetailsForSchool";
import SchoolProjectDetails from "../../pages/school/project-details/SchoolProjectDetails";
import SchoolCollectionContentForm from "../../pages/school/upload-collection-by-school/SchoolCollectionContentForm";
import InviteTeammates from "../../pages/invite-teammates/InviteTeammates";
import SchoolSupport from "../../pages/support/SchoolSupport";
import InviteContributors from "../../pages/school/invite-contributors/InviteContributors";
import ImageReviewAndComment from "../../components/commission-content/ImageReviewAndComment";

const schoolRoutes = [

  {
    path: "/school",
    element: (
      <PrivateRoute>
        <RootLayout>
          <SchoolHomePage />
        </RootLayout>
      </PrivateRoute>
    ),
  },
  {
    path: "/school",
    children: [
      {
        path: "commissions/details/image-review",
        element: (
          <PrivateRoute>
            <RootLayout>
              <ImageReviewAndComment />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "project-details/:commissionXrefId",
        element: (
          <PrivateRoute>
            <RootLayout>
              <SchoolProjectDetails />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "project/upload",
        element: (
          <PrivateRoute>
            <RootLayout>
              <SchoolProjectContentForm />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "gallery",
        element: (
          <PrivateRoute>
            <RootLayout>
              <GalleryCollectionPreviewForSchool />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "gallery/:collectionId",
        element: (
          <PrivateRoute>
            <RootLayout>
              <GalleryCollectionDetailsForSchool />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "gallery/upload",
        element: (
          <PrivateRoute>
            <RootLayout>
              <SchoolCollectionContentForm />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "invite-teammates",
        element: (
          <PrivateRoute>
            <RootLayout>
              <InviteTeammates />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "invite-contributors",
        element: (
          <PrivateRoute>
            <RootLayout>
              <InviteContributors />
            </RootLayout>
          </PrivateRoute>
        ),
      },

      {
        path: "support",
        element: (
          <PrivateRoute>
            <RootLayout>
              <SchoolSupport />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      

    ],
  },
];


export default schoolRoutes