import React from 'react'

function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11506 11.0762H11.268M0.962158 11.0762H1.92091C2.20099 11.0762 2.34103 11.0762 2.47281 11.0445C2.58966 11.0165 2.70135 10.9702 2.80381 10.9074C2.91937 10.8366 3.01839 10.7376 3.21643 10.5396L10.4092 3.34681C10.8835 2.8725 10.8835 2.10348 10.4092 1.62917C9.93487 1.15486 9.16585 1.15486 8.69154 1.62917L1.49878 8.82192C1.30074 9.01996 1.20171 9.11899 1.1309 9.23454C1.06812 9.337 1.02185 9.4487 0.993797 9.56554C0.962158 9.69732 0.962158 9.83736 0.962158 10.1174V11.0762Z"
        stroke="#6941C6"
        stroke-width="1.14509"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default EditIcon