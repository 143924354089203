// PreviousRouteContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousRouteContext = createContext();

export const usePreviousRoute = () => {
  return useContext(PreviousRouteContext);
};

export const PreviousRouteProvider = ({ children }) => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);

  useEffect(() => {
    // Update the previous route on location change
    setPrevLocation(location);
  }, [location]);

  return (
    <PreviousRouteContext.Provider value={prevLocation}>
      {children}
    </PreviousRouteContext.Provider>
  );
};
