import React from "react";
import CommissionGalleries from "../../../components/CommissionGalleries";
import BackNavigator from "../../../components/BackNavigator/BackNavigator";

const CommGalleries = () => {
  return (
    <div className="custom-max-width mt-5">
      <BackNavigator className="text-lg font-semibold" iconSize={25} text="Back to all content" link="/my-content"/>
      <CommissionGalleries />
    </div>
  );
};

export default CommGalleries;
