import { combineReducers } from "redux";
import userReducer from "./reducerFiles/userreducer";
import galleryReducer from "./reducerFiles/galleryReducer";
import creatorCollectionReducer from "./reducerFiles/creatorCollectionReducer";
import profileReducer from "./reducerFiles/profile/profileReducer";
import commissionContentReducer from './reducerFiles/commission-content/commissionContentReducer'

const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  galleryData: galleryReducer,
  creatorCollection: creatorCollectionReducer,
  commissionContent: commissionContentReducer,
});

export default rootReducer;
