
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InviteIcon from '../../icons/InviteIcon';
import { confirmRegistration, deleteUserByEmail, resendConfirmationCode } from '../../services/aws/AwsService';
import useToast from '../../hooks/useToast';
import { signup } from '../../services/AuthServices';
import PrimaryFilledBtn from '../form-elements/PrimaryFilledBtn';
import { setLocalStorage } from '../../lib/session';
import { useDispatch } from 'react-redux';
import { loginSuccess, profile, profileData } from '../../redux/actions';
import { SidebarContext } from '../../App';
import { SignupContext } from '../../pages/auth/registration/Registration';
import { useAuthProvider } from '../../contexts/AuthContext';

const OTPScreen = ({ email, changeScreen, emailExistNotVeryfied, cognitoId, signupMode, invitationState }) => {
    const { signupData, changeSignupData } = useAuthProvider()
    const { userType, code } = signupData

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const showToast = useToast();
    const [otp, setOtp] = useState(['', '', '', '', '', '']);

    const [confirmationCode, setConfirmationCode] = useState("");
    const [loading, setLoading] = useState(false)
    const [resendLinkVisible, setResendLinkVisible] = useState(true);
    const [resendMessage, setResendMessage] = useState("");
    const [resendTimer, setResendTimer] = useState(15);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationFailure, setRegistrationFailure] = useState(false);
    const [error, setError] = useState('')
    const { setGlobalLoader } = useContext(SidebarContext)
    const handleChange = (e, index) => {

        const value = e.target.value;
        if (isNaN(value)) return; // Only allow numbers

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to the next input if a digit is entered
        if (value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };



    const handleSubmit = async () => {
        const otpCode = otp.join('');


        try {
            setGlobalLoader(true)
            setLoading(true)
            // Add your OTP verification logic here
            const res = await handleConfirmCode(email, otpCode)
            if (res == "SUCCESS") {
                if (cognitoId) {
                    let payload = {
                        userType,
                        email: email,
                        fullName: email?.split("@")[0] || "",
                        role: "editor",
                        cognito_id: cognitoId,
                        signupMethod: signupMode || "password",
                        invitedBy: invitationState.invitedByEmail
                    }

                    if (userType === "creator") {
                        payload = {
                            email: email,
                            "userType": "creator",
                            "cognito_id": cognitoId,
                            "refferal": code || signupData?.invitedBy|| "",
                            assigned_project_id :signupData?.assignedProject||"",
                            school_id :signupData?.school_id||"",
                            school_user_id :signupData?.school_user_id||""
                        }
                    }
                    if (userType == "school") {
                        payload = {
                            ...payload,
                            userType: "school",
                            role: "teacher",
                        }
                    }



                    signup({ ...payload }).then((result) => {
                        setLocalStorage("token", JSON.stringify(result.token))
                        setLocalStorage("userRole", userType || "brand");
                        dispatch(loginSuccess({
                            isLoggedIn: false,
                            username: email.toLowerCase(),
                            token: result.token,
                            userId: result.userId,
                            brandId: result.brand_id,
                            schoolId: result.school_id,
                            creatorId: result.creator_id,
                            userRole: userType || "brand",
                            isOwner: result?.isOwner,
                            role: result?.role,
                            userType:result.userType
                        }));;
                        dispatch(profileData({
                            isLoggedIn: false,
                            username: email.toLowerCase(),
                            token: result.token,
                            userId: result.user_id,
                            brandId: result.brand_id,
                            schoolId: result.school_id,
                            creatorId: result.creator_id,
                            userRole: userType || "brand",
                            isOwner: result?.isOwner,
                            role: result?.role,
                            id: result.creator_id || result.school_id || result.brand_id
                        }));
                        dispatch(profile(result?.profileImg || ""));

                        changeScreen(3)
                        showToast(`${userType} register succesfully`, 'success')
                        setLoading(false)
                        setGlobalLoader(false)
                    }).catch((err) => {
                        const delResp = deleteUserByEmail(email)
                        setLoading(false)
                        showToast('Registration Failed, try again.')
                        changeScreen(1)
                        console.log(" congnito account deleted succfully")
                        setGlobalLoader(false)

                    });

                }
                else {
                    showToast('something went wrong.Pls try again.')
                    setLoading(false)
                    setGlobalLoader(false)
                }

            }
            else {
                setGlobalLoader(false)
                setLoading(false)
            }
        } catch (e) {
            setGlobalLoader(false)
            setLoading(false)
        }

    };





    const handleConfirmCode = async (email, otpCode) => {
        try {
            const resp = await confirmRegistration(email, otpCode);
            setRegistrationSuccess(true);
            return resp
        } catch (confirmationError) {
            console.error("Error confirming registration:", confirmationError);
            showToast("Wrong OTP. Please enter again!", 'error')
            setGlobalLoader(false)
            setRegistrationFailure(true);
        }
    };


    //  -------resend otp-------------------
    const handleResendCode = async () => {
        try {
            const message = await resendConfirmationCode(email);
            setResendMessage(message);

            let timer = 15;
            const intervalId = setInterval(() => {
                timer--;
                setResendTimer(timer);

                if (timer === 0) {
                    setResendLinkVisible(true);
                    setResendMessage("");
                    setResendTimer(15);
                    clearInterval(intervalId);
                }
            }, 1000);
        } catch (resendError) {
            console.error("Error while resending confirmation code:", resendError);
            setResendMessage(resendError);
        }
    };


    useEffect(() => {
        if (email && emailExistNotVeryfied) {
            handleResendCode()   // send otp of new user as well as exited but not verified user of cognito
        }
        // else{
        //     navigate('/login/brand',{replace:true})
        // }
    }, [email])

    return (
        <div className=" p-8 ">
            <div className="flex justify-center mb-6">
                <InviteIcon />
            </div>
            <h2 className="text-3xl font-bold text-center mb-4">Check your email</h2>
            <p className="text-center mb-6 text-gray-600">
                We sent a verification link to <span className="font-medium">{email}</span>
                <p>Change Email <Link onClick={() => changeScreen(1)}>click here!</Link> </p>

            </p>
            <div className="flex justify-center mb-6 space-x-4">
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        className="w-16 h-16 text-center border-3 border-violet focus:border-violet rounded text-3xl font-[500] text-violet"
                    />
                ))}
            </div>
            {/* <button
                onClick={handleSubmit}
                className="w-full py-3 bg-purple-500 text-white font-bold rounded hover:bg-purple-600 transition"
            >
                Verify email
            </button> */}
            <PrimaryFilledBtn disable={loading} label={loading ? "Verifying email ..." : 'Verify email'} className='mt-top' onClick={handleSubmit} />

            <div className="text-center mt-4 text-sm text-gray-600">
                Didn’t receive the email?{' '}
                {
                    resendLinkVisible ?
                        <button onClick={handleResendCode} className="text-purple-500 underline">
                            Click to resend
                        </button>
                        :
                        <span>Resend in {resendTimer} seconds </span>
                }
            </div>
            <div className="text-center mt-4">
                <button onClick={() => changeScreen(1)} className="text-sm text-gray-500 hover:text-gray-700">
                    &larr; Back to log in
                </button>
            </div>
            {/* {resendLinkVisible && (
                <div className="login-form-row">
                    <div className="login-form-group">
                        <div className="forgot-password-link">
                            <button className="button-link" onClick={handleResendCode}>
                                Resend Confirmation Code
                            </button>
                        </div>
                    </div>
                </div>
            )} */}
            {resendMessage && (
                <div className="login-form-row">
                    <div className="login-form-group">
                        <div class="alert alert-success">{resendMessage}</div>
                    </div>
                </div>
            )}
            {/* {!resendLinkVisible && (
                <div className="login-form-row">
                    <div className="login-form-group">
                        <div class="alert alert-info">
                            Resend link will be available in {resendTimer} seconds.
                        </div>
                    </div>
                </div>
            )} */}
            {/* {registrationSuccess && (
                <div className="login-form-row">
                    <div className="login-form-group">
                        <div class="alert alert-success">
                            User registration successful and verified!
                            <button className="button-link ps-1" onClick={handleLogin}>
                                Login here
                            </button>
                        </div>
                    </div>
                </div>
            )} */}
            {registrationFailure && (
                <div className="login-form-row mt-3">
                    <div className="login-form-group">
                        <div class="alert alert-danger">
                            User registration failed! Try registering again.
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OTPScreen;


