import React from 'react'

function UpdateForNextVersionIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4166 4.42969L13.2994 2.54687M13.2994 2.54687L11.4166 0.664062M13.2994 2.54687L9.53377 2.54688M13.2994 6.9401V10.2036C13.2994 11.2581 13.2994 11.7854 13.0942 12.1881C12.9137 12.5424 12.6256 12.8304 12.2714 13.0109C11.8686 13.2161 11.3414 13.2161 10.2869 13.2161H3.75981C2.70534 13.2161 2.1781 13.2161 1.77535 13.0109C1.42107 12.8304 1.13304 12.5424 0.952529 12.1881C0.747314 11.7854 0.747314 11.2581 0.747314 10.2036V3.67656C0.747314 2.62209 0.747314 2.09485 0.952529 1.6921C1.13304 1.33782 1.42107 1.04979 1.77535 0.869277C2.1781 0.664062 2.70534 0.664062 3.75981 0.664062H7.02336M0.838636 11.9147C1.13307 10.8555 2.10455 10.0781 3.25759 10.0781H7.65082C8.23405 10.0781 8.52567 10.0781 8.76818 10.1264C9.76404 10.3245 10.5425 11.1029 10.7406 12.0988C10.7888 12.3413 10.7888 12.6329 10.7888 13.2161M8.27856 5.37109C8.27856 6.75756 7.15461 7.88151 5.76815 7.88151C4.38168 7.88151 3.25773 6.75756 3.25773 5.37109C3.25773 3.98463 4.38168 2.86068 5.76815 2.86068C7.15461 2.86068 8.27856 3.98463 8.27856 5.37109Z" stroke="white" stroke-width="1.25521" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default UpdateForNextVersionIcon