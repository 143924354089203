import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import HomeIcon from "../icons/home";
import AnalyticIcon from "../icons/Analytics";
import CommissionIcon from "../icons/Commissions";
import { useDispatch, useSelector } from "react-redux";
import SupportIcon from "../icons/Support";
import SettingIcon from "../icons/Settings";
import LogoutIcon from "../icons/Logout";

import { logout } from "../redux/actions";

import "@fortawesome/fontawesome-free/css/all.css";
import "./Root.css";
import MenuIcon from "../icons/MenuIcon";
import CrossIcon from "../icons/CrossIcon";
import { SidebarContext } from "../App";
import ShopIcon from "../icons/ShopIcon";
import MyContentIcon from "../icons/MyContent";
import AdminIcon from "../icons/AdminIcon";
import Footer from "../components/Footer/Footer";
import DollerIcon from "../icons/DollerIcon";
import MediaTypeIcon from "../icons/Mediatype";
import GrayCameraIcon from "../icons/GrayCameraIcon";
import useToast from "../hooks/useToast";
import InviteIcon from "../icons/InviteIcon";
import SchoolSidebarMyProjectsIcon from '../icons/SchoolSidebarMyProjectsIcon'
import { usePreviousRoute } from "../contexts/PreviousRouteContext";







const brandSideBarData = [
  { name: "Home", path: '/home', icon: <HomeIcon /> },
  { name: "My Content", path: '/my-content', icon: <MyContentIcon /> },
  { name: "Shop", path: '/shop', icon: <ShopIcon /> },
  { name: "Commissions", path: '/commissions', icon: <CommissionIcon /> },
  { name: "Admin", path: '/admin', icon: <AdminIcon /> },
  { name: "Analytics", path: '/analytics', icon: <AnalyticIcon /> },
]

const adminSideBarData = [
  { name: "Brand", path: '/admin/BrandList', icon: <HomeIcon /> },
  { name: "Creator", path: '/admin/CreatorList', icon: <MyContentIcon /> },
  { name: "School", path: '/admin/SchoolList', icon: <CommissionIcon /> },
  { name: "Assign commission", path: '/admin/school-commission', icon: <ShopIcon /> } 
]


const creatorSideBarData = [
  { name: "My Work", path: '/creator', icon: <MyContentIcon /> },
  { name: "My Projects", path: '/creator/projects', icon: <SchoolSidebarMyProjectsIcon /> },
  { name: "Sales History", path: '/sales-history', icon: <DollerIcon /> },
]

const schoolSideBarData = [
  { name: "My Projects", path: '/school', icon: <SchoolSidebarMyProjectsIcon /> },
  { name: "My Gallery", path: '/school/gallery', icon: <MyContentIcon /> },
]

const intiAdminUser = [
  "mike@swiirl.io",
  "anna456778@gmail.com",
  "cv3@yopmail.com",
  "daniel@swiirl.io",
  "satyamkushwaha.appdelite@gmail.com"
]




function RootLayout({ children }) {
  const previousRoute = usePreviousRoute();
  const showToast = useToast()
  // const [isSidebarClosed, setIsSidebarClosed] = useState(true);
  const { isSidebarClosed, toggleSidebar } = useContext(SidebarContext);
  const currentPath = useLocation().pathname
  const userType = localStorage.getItem('userRole')

  const { username, profileUrl, isOwner,fullName } = useSelector((state) => state.user);

  const userState = useSelector(state => state.user)


  // const toggleSidebar = () => {
  //   setIsSidebarClosed(!isSidebarClosed);
  // };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    showToast("Logout successful", 'success')
    dispatch(logout());
    navigate("/");
  };

  const [adminUser, setAdminUser] = useState(intiAdminUser)


  useEffect(() => {
    const verAdminUser = process.env.REACT_APP_ADMIN_USERS
    if (verAdminUser) {
      setAdminUser(verAdminUser)
    }
  }, [process.env.REACT_APP_ADMIN_USERS])

  const location = useLocation();

  useEffect(() => {
    // Scroll to the top whenever the location (route) changes
    window.scrollTo({ top: 0, behavior: "instant" });
  },[location])
  return (
    <>


      <div
        className=" position-relative ]"
        style={{ display: "flexs", flexDirection: "columns", minHeight: "100vh" }}
      >

        <header className="w-100 h-[10vh] py-10 z-[110] position-fixed drop-shadow-lg top-0 bg-white">
          <div className="flex h-full justify-content-between items-center  px-4">

            <div className="flex gap-5">

              <div
                className={`menu-icon my-auto  ms-2  ${!isSidebarClosed ? "menu-icon-closes" : ""}`}
                onClick={toggleSidebar}
              >
                <MenuIcon />
              </div>

              <div onClick={() => navigate('/')} className="cursor-pointer">
                <img
                  alt="swiirl-logo"
                  className="swiirl-logo"
                  src="/home/swiirl-logo.png"
                />
              </div>
            </div>
            <div className={`flex gap-4 items-center`}>
              <Link to={userType == "creator" ? "/creator/profile" : "/settings"} className="name-holder font-semibold text-secondary-text no-underline flex items-center gap-4">
                <span className="text nav-text"> 
                  {userType === "creator" && `${fullName || username}`}
                  {userType === "admin" && ` ${fullName || username}`}
                  {userType === "school" && ` ${fullName || username}`}
                  {userType === "brand" && ` ${fullName || username}`}</span>
                <div className="h-[60px] w-[60px] bg-gray-500s rounded-full">

                  {!profileUrl ? <GrayCameraIcon /> :
                    <img src={profileUrl} alt="asdf" className="w-full h-full object-cover rounded-full" />}
                </div>
              </Link>

              <Link onClick={handleLogout}>
                <LogoutIcon />
              </Link>
            </div>
          </div>
        </header>

        <div className="h-[10vh] min-h-[10vh]">
          {/* added this div to get the same height as the header which is postition fixed */}
        </div>

        <div className="flex flex-col h-[90vh] w-full position-relative" >

          <div className=" flex position-relative ">
            <nav
              className={`sidebar ${isSidebarClosed ? "close" : ""}`}>

              <div
                className="menu-bar"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflowX: "hidden"
                }}
              >
                <div className="menu py-4">

                  {(userType === "creator" ? creatorSideBarData :userType === "admin" ? adminSideBarData :userType === "school"?schoolSideBarData: brandSideBarData).map((item, index) =>

                    item?.path === "/admin" ?

                      adminUser && adminUser.length > 0 && adminUser.includes(username) ? (
                        <li key={item?.name} className={` ${(currentPath === item?.path) ? 'active-tab' : ''} `}>
                          <Link className="" to={item?.path}>
                            {item?.icon}
                            <span className="text nav-text ">{item?.name}</span>
                          </Link>
                        </li>
                      ) :
                        <></> :
                      <li key={item?.name} className={` ${((currentPath === '/galleries' || currentPath === '/') && item?.name === 'Home') || ((currentPath.includes('/commission')) && item?.name === 'Commissions') || (currentPath.includes('/comm-galleries') && item?.name === 'My Content') || currentPath === item?.path ? 'active-tab': ''} `}>
                        <Link className="" to={item?.path}>
                          {item?.icon}
                          <span className="text nav-text ">{item?.name}</span>
                        </Link>
                      </li>
                  )}

                </div>

                <div className="bottom-content">
                  <li className={` ${currentPath === `/${userType}/support` && 'active-tab'}`}>
                    <Link to={`/${userType}/support`}>
                      <SupportIcon />
                      <span className="text nav-text">Support</span>
                    </Link>
                  </li>
                  <li className={` ${currentPath === '/settings' ||currentPath === '/creator/profile' ? 'active-tab' : ''}`}>
                    <Link to={`${userType === 'creator' ? '/creator/profile' : '/settings'}`}>
                      <SettingIcon />
                      <span className="text nav-text">Settings</span>
                    </Link>
                  </li>
                  {
                    !!isOwner &&
                    <li className={`${currentPath === '/invite-teammates' ? 'active-tab' : ''}`}>
                      <Link to={`${userState.userType === 'brand' ? "/invite-teammates" : "/school/invite-teammates"}`}>
                        {/* <SettingIcon /> */}
                        <InviteIcon />
                        <span className="text nav-text">Invite teammates</span>
                      </Link>
                    </li>
                  }
                </div>
              </div>
            </nav>

            <div
              className={`w-full min-h-[90vh] content ${isSidebarClosed ? "overlayed" : ""}`}
              style={{
                flex: 1,
                paddingLeft: isSidebarClosed ? "92px" : "225px",
                transition: "padding-left 0.3s ease-in-out",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <main className="mb-5" style={{ flex: 1 }}>{children}</main>

            </div>
          </div>
          <div className="  z-[110]">
            <Footer />
          </div>
        </div>

      </div>
    </>
  );
}

export default RootLayout;
