import React, { useContext, useEffect, useRef, useState } from 'react'

import LeftArrowIcon from '../../../icons/LeftArrowIcon'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import InputText from '../../../components/FormComponets/InputText'
import TextArea from '../../../components/FormComponets/TextArea'
import Checkbox from '../../../components/Checkbox/Checkbox'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { extractVideoAndImgFile, getFileMimeType, getFileSize, INITIAL_FORM_DATA, validate } from './helper'
import { useDispatch, useSelector } from 'react-redux'
import { ulid } from 'ulid'
import { addProjectContent, editProjectContent, getUploadUrl, uploadFileTos3 } from '../../../services/schoolServices'
import useToast from '../../../hooks/useToast'
import { SidebarContext } from '../../../App'
import MediaUpload from '../../../components/MediaUpload'
import { getMimeTypeFromFileName } from '../../../components/cards/helper'
import { revalidate } from '../../../redux/reducerFiles/commission-content/commissionContentReducer'
import useModal from '../../../components/popups/modal/useModal'
import CustomModal from '../../../components/popups/CustomModal'
import FileUploadProgressBar from '../../../components/ProgressBarList'
import { ProgressBar } from 'react-bootstrap'
import ProgressBarList from '../../../components/ProgressBarList'


function SchoolProjectContentForm() {
    const { open, setOpen, toggle } = useModal()
    const dispatch = useDispatch()
    const [behindTheScene, setBehindTheScene] = useState([])
    const [media, setMedia] = useState([])
    const navigate = useNavigate()
    const { setGlobalLoader } = useContext(SidebarContext)
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const contentData = location?.state?.contentData || ""
    const pageProps = location.state?.pageProps || {}
    const actionType = location.state?.actionType || ''
    const commissionDetails = useSelector(state => state?.commissionContent.commissionDetails)
    const contentVersionType = location.state?.contentVersionType || ''
    const commissionXrefId = contentData?.commision_school_xref_id || commissionDetails?.cs_id
    const [disableSubmit, setDisableSubmit] = useState(true)

    const showToast = useToast()
    const formType = searchParams.get('type') || 'add';
    const [behindScenePrevData, setBehindScenePrevData] = useState(contentData?.behind_scene_media ? JSON.parse(contentData?.behind_scene_media).map(item => ({ ...item, removed: false })) : [])
    const contentSubmitted = contentData?.isSubmited

    const [loading, setLoading] = useState(false)
    const [checkboxError, setCheckboxError] = useState(false)
    const [formData, setFormData] = useState(INITIAL_FORM_DATA)
    const [formState, setFormState] = useState({
        isValid: false,
        isLoading: false
    });
    const objectUrlsForPreview = useRef({})
    const [thumbnails, setThumbnails] = useState({})
    const { schoolId, userId, userType = "school", fullName } = useSelector((state) => state.user);

    const state = useSelector((state) => state.user);


    function handleFormElementChange(e) {
        const { name, type, value, checked } = e.target;
        // Update the value based on the input change
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: {
                ...prevFormData[name], // Copy the previous object to ensure immutability
                value: name === INITIAL_FORM_DATA.isChecked.name ? checked : value, // Update the value based on input type
            },
        }));

    }

    const clearForm = () => {
        // Reset the form data to the initial state
        setFormData(INITIAL_FORM_DATA);
    };

    useEffect(() => {
        // Validate form state whenever formData changes
        let valid = validate({ formData })

        setFormState((prev) => ({ ...prev, isValid: valid })); // Update the validation state
    }, [formData]); // Run this effect whenever formData changes

    const [progressData, SetProgressData]= useState({})

    useEffect(() => {
        if (contentData && contentData?.type === 'edit' && formType === 'edit') {
            const addData = async () => {
                const {
                    content_title,
                    creator_bio,
                    creator_name,
                    inspiration,
                    name,
                    links,
                    url,
                    thumbnailUrl,
                    id,
                    behindTheSchene // [{url:"",thumnailUrl:""}]
                } = contentData;

                let fileType = await getFileMimeType(url)
                // fileType=fileType.split('/')[1]||""
                let file_name = id + "." + fileType.split('/')[1]
                setThumbnails({ [file_name]: { url: thumbnailUrl, type: 'image/jpg', name: id + "_tumbnail.jpg", isEditData: true } })

                const contentFile = [
                    {
                        file: {
                            url: url,
                            name: id + "." + fileType.split('/')[1] || "",
                            type: fileType.split('/')[0] || "image",
                            size: await getFileSize(url)
                        },
                        thumbnail: {
                            url: thumbnailUrl,
                            name: id + "_thumbnail.jpeg",
                            type: "image",
                        },
                        isNew: false
                    }
                ]

                const behindSceneFile = []
                for (let item of contentData.behindSceneMedia) {
                    behindSceneFile.push({
                        file: {
                            url: item.videoUrl,
                            name: item.videoName,
                            type: getMimeTypeFromFileName(item.videoName),
                            size: await getFileSize(url),
                            key: item.videoKey
                        },
                        thumbnail: {
                            url: item.thumbnailUrl,
                            name: item.thumbnailName,
                            type: "image",
                            key: item.thumbnailKey

                        },
                        isNew: false
                    })
                }
                setBehindTheScene(behindSceneFile)
                setMedia(contentFile)

                const files = [{
                    url,
                    thumbnailUrl,
                    name: id + "." + fileType.split('/')[1] || "",
                    type: fileType.split('/')[0] || "image",
                    size: await getFileSize(url),
                    isEditData: true
                }]
                // Create a new object based on the existing form data
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    collectionName: { ...prevFormData.collectionName, value: name },
                    creatorName: { ...prevFormData.creatorName, value: creator_name },
                    contentTitle: { ...prevFormData.contentTitle, value: content_title },
                    inspiration: { ...prevFormData.inspiration, value: inspiration },
                    bio: { ...prevFormData.bio, value: creator_bio },
                    links: { ...prevFormData.links, value: links },
                    // files: { ...prevFormData.files, value: files },

                }));


            }
            addData()
        } else {
            if (userType === 'creator') {
                setFormData({
                    ...formData,
                    creatorName: { ...formData.creatorName, value: fullName },
                });
            } else {
                clearForm();
            }
        }
    }, [formType, contentData]);
    // setGlobalLoader(false)

    const updateEditUrl = (url, editUrlIndex = 6) => {
        const urlParts = url.split('/');
        let version = urlParts[editUrlIndex] ? urlParts[editUrlIndex] : "v1";
        version = `v${parseInt(version.slice(1)) + 1}`; // Increment version number
        urlParts[editUrlIndex] = version;
        const updatedUrl = urlParts.join('/');
        return updatedUrl;
    };

    const getSignedUrlAndUploadMedia = async (mediaItems, contentId, uploadFor) => {
        // return 
        let response = [];
        if (!mediaItems.length) return response;

        let keyNames = [];
        await mediaItems.forEach(({ videoFile, thumbnailFile }) => {
            const _ulid = ulid();
            let videoKey = ''
            let thumbnailKey = ''

            if (uploadFor === 'content') {
                videoKey = `content/${userType}/${userId}/${contentId}/video/${_ulid}`;
                if (thumbnailFile) thumbnailKey = `content/${userType}/${userId}/${contentId}/video/${_ulid}_thumbnail`;
            } else {
                videoKey = `content/${userType}/${userId}/${contentId}/behind-the-scene/video/${_ulid}`;
                if (thumbnailFile) thumbnailKey = `content/${userType}/${userId}/${contentId}/behind-the-scene/video/${_ulid}_thumbnail`;
            }

            let keyPayload = {
                videoName: videoFile?.name,
                videoKey
            }

            if (thumbnailFile && thumbnailKey) {
                keyPayload = {
                    ...keyPayload,
                    thumbnailName: thumbnailFile?.name,
                    thumbnailKey
                }
            }

            response.push({
                ...keyPayload
            });

            keyNames.push(videoKey);
            if (thumbnailKey) keyNames.push(thumbnailKey);

        });

        const { urls } = await getUploadUrl(keyNames);
        if (urls.length !== keyNames.length) {
            throw new Error('The number of upload URLs does not match the number of keys.');
        }
        const uploadPromises = mediaItems.map((item, index) => {
            const videoUrl = urls[index * 2];
            const thumbnailUrl = urls[index * 2 + 1];

            const promises = [];
            if (videoUrl && item.videoFile) promises.push(uploadFileTos3(videoUrl, item.videoFile,(progressEvent)=>{
                const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded / total) * 100);
                // console.log({percentage, total, loaded})
                SetProgressData((prevData) => ({
                    ...prevData,
                    [videoUrl]: {
                        title :item.videoFile.name,
                        progress: percentage
                    }
                    
                }));
            }))
            if (thumbnailUrl && item.thumbnailFile) promises.push(uploadFileTos3(thumbnailUrl, item.thumbnailFile,(progressEvent)=>{
                const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded / total) * 100);
                // console.log({percentage, total, loaded})
                SetProgressData((prevData) => ({
                    ...prevData,
                    [thumbnailUrl]: {
                        title :item.thumbnailFile.name,
                        progress: percentage
                    }
                    
                }));
            }))
            return Promise.all(promises);
        });

        await Promise.all(uploadPromises);
        return response;
    };

    const getSignedUrlAndUploadImage = async (files, contentId) => {
        let response = [];
        if (!files.length) return response;

        let keyNames = await files.map((file) => {
            let editedContentKey = contentData?.content_key 
            editedContentKey =editedContentKey? editedContentKey?.replace('/video','/photography'):"";
            const key = actionType !== 'update_for_next_version' && formType == "edit" && contentData?.content_key ? updateEditUrl(editedContentKey) : `content/${userType}/${userId}/${contentId}/photography/${ulid()}`;
            response.push({ name: file?.name, key });
            return key;
        });

        const { urls } = await getUploadUrl(keyNames);
        const promises = [];
        const uploadPromises = urls.map((url, index) => {
            if (url) promises.push(uploadFileTos3(url, files[index],(progressEvent)=>{
                const { loaded, total } = progressEvent;
                const percentage = Math.floor((loaded / total) * 100);
                // console.log({percentage, total, loaded})
                SetProgressData((prevData) => ({
                    ...prevData,
                    [url]: {
                        title :files[index].name,
                        progress: percentage
                    }
                    
                }))
            }
            ))
        })
        await Promise.all(promises)
        return response;
    };

    const validateForUpload = () => {
        if (!formData.creatorName.value) {
            alert("Creator Name is required");
            return false;
        }
        if (!formData.inspiration.value) {
            alert("Inspiration is required");
            return false;
        }
        if (!formData.bio.value) {
            alert("Bio is required");
            return false;
        }
        if (!media.length) {
            alert("Project files is required");
            return false;
        }
        return true;
    };

    const handleContentSubmit = async (e) => {
        e.preventDefault();

        if (!formData.isChecked.value) {
            setCheckboxError(true);
            setGlobalLoader(false)
            return;
        }

        if (!validateForUpload()) {
            setGlobalLoader(false)
            return
        };

        setLoading(true);
        try {

            const [images, videos, umError] = extractVideoAndImgFile({ media, setGlobalLoader })
            const [_, behindTheSceneVideos, btsError] = extractVideoAndImgFile({ media: behindTheScene, setGlobalLoader, thumbnailRequired: false })

            if (umError || btsError) {
                setGlobalLoader(false)
                return
            }



            async function addContentFn(reqBody = {},redirectTab) {
                let contentId = ulid()
                const files = await getSignedUrlAndUploadImage(images, contentId);
                const videoFiles = await getSignedUrlAndUploadMedia(videos, contentId, 'content');
                const behindTheSceneVideoFiles = await getSignedUrlAndUploadMedia(behindTheSceneVideos, contentId);

                const requestBody = {
                    commision_school_xref_id: commissionXrefId,
                    files,
                    videoFiles,
                    behindTheScheneMedia: behindTheSceneVideoFiles,
                    creator_name: formData.creatorName.value,
                    content_title: formData.contentTitle.value,
                    inspiration: formData.inspiration.value,
                    creator_bio: formData.bio.value,
                    school_id: schoolId,
                    school_user_id: userId,
                    social_media_links: formData.links.value,
                    userType,
                    isRevision1: contentVersionType === 'revision_1' ? 1 : 0,
                    isRevision2: contentVersionType === 'revision_2' ? 1 : 0,
                    isFinalWork: contentVersionType === 'final_work' ? 1 : 0,
                    ...reqBody
                };
                // Submit form data (use your service here)
                setGlobalLoader(true)
                await addProjectContent(requestBody);
                clearForm()
                dispatch(revalidate())
            }


            // setGlobalLoader(false)
            // return
            if (contentData?.type === "edit") {

                if (contentVersionType !== 'final_work' && actionType === 'update_for_next_version') {
                    if (disableSubmit && !images?.length && !videos?.length) {
                        alert("At least one new image/video is required for next version")
                        setGlobalLoader(false)
                        return
                    }
                    const requestBody = {}
                    // submit data for next version
                    requestBody['isRevision1'] = 0
                    requestBody['isRevision2'] = contentVersionType === 'revision_1' ? 1 : 0
                    requestBody['isFinalWork'] = contentVersionType === 'revision_2' ? 1 : 0
                    requestBody['parentContentId'] = contentData?.id
                    const redirectTab=contentVersionType === 'revision_1'?0:contentVersionType === 'revision_2'?1:2

                    await addContentFn(requestBody,redirectTab)
                    showToast("Updated successfully", 'success')
                    setGlobalLoader(false)
                    navigate(-1)
                    return;
                } else {
                    let files, videoFiles;
                    let behindTheSceneVideoFiles = []
                    if (images.length) {
                        files = await getSignedUrlAndUploadImage(
                            images,
                            contentData?.id
                        );
                    }
                    if (videos.length) {
                        videoFiles = await getSignedUrlAndUploadMedia(
                            videos,
                            contentData?.id,
                            'content'
                        )
                    }

                    if (behindTheSceneVideos.length) {
                        behindTheSceneVideoFiles = await getSignedUrlAndUploadMedia(behindTheSceneVideos, contentData?.id);
                    }

                    const remainingBehindScenePrevData = []
                    behindScenePrevData.forEach(item => {
                        if (!item.removed) {
                            const newItem = { ...item }
                            delete newItem['removed']
                            remainingBehindScenePrevData.push(newItem)
                        }
                    })

                    behindTheSceneVideoFiles = [...remainingBehindScenePrevData, ...behindTheSceneVideoFiles]


                    const requestBody = {
                        id: contentData?.id,
                        commision_school_xref_id: commissionXrefId,
                        files,
                        videoFiles,
                        behindTheScheneMedia: behindTheSceneVideoFiles,
                        creator_name: formData.creatorName.value,
                        content_title: formData.contentTitle.value,
                        inspiration: formData.inspiration.value,
                        creator_bio: formData.bio.value,
                        social_media_links: formData.links.value,
                        userType,
                        shouldDeleteComment: (images.length || videos.length) ? true : false,
                    };

                    // Submit form data (use your service here)
                    requestBody['isRevision1'] = contentData?.is_revision_1 ? 1 : 0
                    requestBody['isRevision2'] = contentData?.is_revision_2 ? 1 : 0
                    requestBody['isFinalWork'] = contentData?.is_final_work ? 1 : 0
                    setGlobalLoader(true)
                    await editProjectContent({ ...requestBody });

                    clearForm()
                    setGlobalLoader(false)
                    dispatch(revalidate())
                    navigate(-1)
                    showToast(contentData?.type === "edit" ? "Content edit successfully" : "New images added successfully", 'success')
                }
            }
            else {
                await addContentFn()
                setGlobalLoader(false)
                navigate(-1);
            }

        } catch (error) {
            alert("Error creating collection");
            console.error(error);
            SetProgressData({})
            setGlobalLoader(false)

        } finally {
            setLoading(false);
            SetProgressData({})
        }
    };

    



    return (
        
        <div className='max-w-[1400px] flex flex-col gap-6 mt-12 px-12'>
            {!!Object.values(progressData)?.length && <ProgressBarList progressData={progressData}/>} 
            <div className="flex flex-col gap-4">
                <button className="flex flex-row gap-2 items-center w-fit" onClick={() => navigate(-1)}>
                    <LeftArrowIcon />
                    <span className="text-violet text-lg font-semibold">Back to Project page</span>
                </button>
                <p className="mt-4 text-4xl font-semibold">
                    {pageProps?.pageTitle || 'Add Content'}
                </p>

                <div className="flex flex-col w-full">
                    <div className="grid md:grid-cols-2 w-full gap-x-8 gap-y-6">

                        <InputText disabled={contentSubmitted} name={formData.creatorName.name} value={formData.creatorName.value} onChange={handleFormElementChange} className=" w-full" label='Creator name' placeholder='First and last name' isImportant={true} />

                        <InputText disabled={contentSubmitted} name={formData.contentTitle.name} value={formData.contentTitle.value} onChange={handleFormElementChange} className=" w-full" label='Content title' placeholder='What is your artwork name?' isImportant={true} />

                        <TextArea disabled={contentSubmitted} name={formData.inspiration.name} value={formData.inspiration.value} onChange={handleFormElementChange} className='p-3' rows="15" label='Inspiration' isImportant={true} placeholder='What has inspired you to create this piece?' />

                        <TextArea disabled={contentSubmitted} name={formData.bio.name} value={formData.bio.value} onChange={handleFormElementChange} className='p-3' rows="15" label='Tell us a bit about yourself' isImportant={true} placeholder='Creator bio written in first person' />
                        {/* wordLimit={200}
                        wordLimit={200} */}
                        <div className="col-span-2">
                            <TextArea disabled={contentSubmitted} name={formData.links.name} value={formData.links.value} onChange={handleFormElementChange} className='p-3' rows="8" placeholder='Add your social media links or names here, if desired.' />
                        </div>

                        <div className="col-span-2">
                            <MediaUpload prevEditData={behindScenePrevData} setPrevEditData={setBehindScenePrevData} disableUpload={(contentSubmitted || behindTheScene.length >= 3) ? true : false} accept="video/*" helperText="Video (MP4 only)" label="Upload behind the scenes/creation video (thoughts or process from the creator)" isImportant={false} formType={formType} id='behind-the-scene-media-upload' media={behindTheScene} setMedia={setBehindTheScene} contentSubmitted={contentSubmitted} />
                        </div>

                        <div className="col-span-2">
                            <MediaUpload setDisableSubmit={setDisableSubmit} disabledText="Content Media" disableUpload={(contentSubmitted || (formType === "edit" && media.length === 1)) ? true : false} mediaCountToggleValue={1} accept="image/*, video/*" helperText="Image or Video (MP4 only)" label="Upload your content" isImportant={true} formType={formType} id='media-upload' media={media} setMedia={setMedia} contentSubmitted={contentSubmitted} />
                        </div>

                       

                        {
                            !contentSubmitted &&
                            <div className='w-full col-span-2'>
                                <Checkbox
                                    name={formData.isChecked.name}
                                    checkboxError={checkboxError}
                                    isSubmitting={loading}
                                    checked={formData.isChecked.value}
                                    onChange={handleFormElementChange}
                                    className={`${!formData.isChecked.value ? "text-black" : "text-black"}`}
                                    label={'I certify that the submitted creative works are original, created by me, and have not been plagiarized from any other sources'}
                                />

                            </div>
                        }


                    </div>
                    {
                        !contentSubmitted &&
                        <div className="w-full flex justify-end mt-4 gap-4">
                            <button onClick={(e) => { navigate(-1) }} className="button-secondary-sw text-primary-black" style={{ width: '80px' }}>
                                Cancel
                            </button>
                            {
                                actionType === 'replace_image' ?
                                    <PrimaryButton
                                        onClick={() => setOpen(true)}
                                        disabled={!formState.isValid}
                                        text={`Replace image`}
                                    />
                                    :
                                    actionType === 'update_for_next_version' ?
                                        <PrimaryButton
                                            onClick={() => setOpen(true)}
                                            disabled={!formState.isValid}
                                            text={`Update for next version`}
                                        />
                                        :
                                        <PrimaryButton
                                            onClick={handleContentSubmit}
                                            disabled={!formState.isValid}
                                            text={`${contentData?.type === "edit" ? "Save" : "Add"} to project gallery`}
                                        />
                            }
                        </div>
                    }
                </div>
            </div>
            <CustomModal
                toggle={toggle}
                onClick={handleContentSubmit}
                open={open}
                setOpen={setOpen}
                actionButtonLebel={actionType === 'update_for_next_version' ? 'Update' : 'Replace'} 
                actionBtnStyle={actionType === 'update_for_next_version' ? {} : {backgroundColor:'#dc2626'}}
                headingText={actionType === 'update_for_next_version' ? 'Update Image?' : 'Replace image?'}
                contentText={actionType === 'update_for_next_version' ? 'Your current version image will remain intact, and the updated image will be saved in the next version gallery.' : 'Your current image will be permanently replaced in this gallery version. '}
            />
        </div>
    )
}

export default SchoolProjectContentForm

