import React, { useState } from 'react';
import Modal from './modal/Modal';
import CrossIcon from '../../icons/CrossIcon';
import SecondaryButton from '../../components/buttons/SecondaryButton'
import PrimaryButton from '../../components/buttons/PrimaryButton'



const CustomModal = ({ open, setOpen, toggle, onClick, disable, actionButtonLebel='', headingText='', contentText='', cancelClass='', actionClass='', actionBtnStyle={} }) => {

  return (
    <div className="p-4">
      <Modal containerClass={'rounded-xl'} onClose={()=>setOpen(false)} open={open}>
        <div className="flex flex-col gap-1  xl:w-[30vw] lg:w-[40vw] md:w-[60vw] sm:w-[70vw] w-[90vw] max-h-[90vh]">
            <div className="flex flex-row w-full justify-end">
                <button onClick={()=>setOpen(false)}>
                    <CrossIcon size={'40px'} />
                </button>
            </div>
            <p className="text-4xl font-bold">{headingText}</p>
            <p className="text-2xl font-base text-slate-600 mb-4">{contentText}</p>
            <div className="flex flex-row gap-4 mt-8 mb-2">
                <div className="w-64">
                    <SecondaryButton text={'Cancel'} onClick={()=>setOpen(false)} className={`text-xl w-full h-14 border-1 border-silver rounded-lg font-semibold bg-white !text-slate-900 ${cancelClass}`} />
                    </div>
                <div className="w-64"><PrimaryButton style={{...actionBtnStyle}} onClick={onClick} className={` text-xl w-full h-14 font-semibold ${actionClass}`} text={actionButtonLebel} /></div>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomModal;
