import React from 'react'

function AlertRounded({ className }) {
    return (
        <svg className={`alert-icon ${className}`} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
                <path d="M5.49999 18C5.49999 11.0964 11.0964 5.49999 18 5.49999C24.9035 5.49999 30.5 11.0964 30.5 18C30.5 24.9035 24.9035 30.5 18 30.5C11.0964 30.5 5.49999 24.9035 5.49999 18Z" stroke="#DC6803" strokeWidth="1.66667" />
            </g>
            <g opacity="0.1">
                <path d="M1.33333 18C1.33333 8.79525 8.79525 1.33333 18 1.33333C27.2047 1.33333 34.6667 8.79525 34.6667 18C34.6667 27.2047 27.2047 34.6667 18 34.6667C8.79525 34.6667 1.33333 27.2047 1.33333 18Z" stroke="#DC6803" strokeWidth="1.66667" />
            </g>
            <g clip-path="url(#clip0_109_373)">
                <path d="M18 14.6667V18M18 21.3333H18.0083M26.3333 18C26.3333 22.6024 22.6024 26.3333 18 26.3333C13.3976 26.3333 9.66666 22.6024 9.66666 18C9.66666 13.3976 13.3976 9.66666 18 9.66666C22.6024 9.66666 26.3333 13.3976 26.3333 18Z" stroke="#DC6803" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_109_373">
                    <rect width="20" height="20" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default AlertRounded