import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginSuccess, profile, profileData as ProfileDataDispatcher } from '../../redux/actions';
import GrayCameraIcon from '../../icons/GrayCameraIcon';
import { ulid } from 'ulid';
import { getBrandProfile, getUploadUrl, updateBrandProfile, uploadFileTos3 } from '../../services/brandServices';
import InputText from '../../components/FormComponets/InputText';
import CloudUploadIcon from '../../icons/CloudUpload';
import useToast from '../../hooks/useToast';
import { formatName } from '../../utils/formatName';
import { changePassword } from '../../services/aws/AwsService';
import ComingSoon from '../ComingSoon';
import EyeOff from '../../icons/EyeOff';
import EyeOn from '../../icons/EyeOn';
import QuestionMarkIcon from '../../icons/QuestionMarkIcon';

const Settings = () => {
  // const { fullName, username, schoolName, userType, userId, ...remDetails } = useSelector((state) => state.user);
  const { userType, fullName, username, userId, ...rem } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);

  const showToast = useToast()
  const navigate = useNavigate()
  const [brandName, setBrandName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [title, setTitle] = useState('')

  const [cognitoId, setCognitoId] = useState('')
  const [department, setDepartment] = useState('')
  const [postalCode, setPostalCode] = useState('')

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confNewPassword, setConfNewPasswrod] = useState('')


  const [selectedImg, setSelectedImg] = useState(null);
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [file, setFile] = useState(null)
  const [s3Key, setS3Key] = useState('')

  const dispatch = useDispatch()
  const [isImageUploading, setIsImageUploading] = useState(false)
  const [isPasswordChanging, setIsPasswordChanging] = useState(false)


  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    title: '',
    department: '',
    postalCode: '',
    profileImgUrl: '',
    cognitoId: '',
  });

  const handleCancel = (e) => {
    e.preventDefault();
    setFirstName(initialValues.firstName);
    setLastName(initialValues.lastName);
    setTitle(initialValues.title);
    setDepartment(initialValues.department);
    setPostalCode(initialValues.postalCode);
    setProfileImgUrl(initialValues.profileImgUrl);
    setSelectedImg(null);
  };

  //   const handlePasswordChange = async (e) => {
  //     e.preventDefault();
  //     setIsPasswordChanging(true)
  //     if (currentPassword === null || newPassword === null || confNewPassword === null || currentPassword === '' || newPassword === '' || confNewPassword === '') {
  //       showToast("Please fill all fields", "error")
  //       setIsPasswordChanging(false)
  //       return

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };
  const toggleConfirmNewPasswordVisibility = () => {
    setConfirmNewPasswordVisible(!confirmNewPasswordVisible);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{ }|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    let errMsg = [];

    if (password.length < minLength) {
      errMsg.push('Password must be at least 8 characters long.');
    }
    if (!hasNumber) {
      errMsg.push('Password must contain at least 1 number.');
    }
    if (!hasSpecialChar) {
      errMsg.push('Password must contain at least 1 special character.');
    }
    if (!hasUppercase) {
      errMsg.push('Password must contain at least 1 uppercase letter.');
    }
    if (!hasLowercase) {
      errMsg.push('Password must contain at least 1 lowercase letter.');
    }

    return errMsg;
  };



  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setIsPasswordChanging(true)

    const passwordErrors = validatePassword(newPassword);
    if (!currentPassword) {
      showToast("Incorrect password", "error");
      setIsPasswordChanging(false);
      return;
    }
    if (newPassword === null || confNewPassword === null || newPassword === '' || confNewPassword === '') {
      showToast("Please fill all fields", "error")
      setIsPasswordChanging(false)
      return

      //   const handlePasswordChange = async () => {
      //     setIsPasswordChanging(true);

      //     const passwordErrors = validatePassword(newPassword);

      //     if (!currentPassword || !newPassword || !confNewPassword) {
      //       showToast("Please fill all fields", "error");
      //       setIsPasswordChanging(false);
      //       return;
    }
    if (newPassword === currentPassword) {
      showToast("New password should not be the same as the current password", "error");
      setIsPasswordChanging(false);
      return;
    }
    if (newPassword !== confNewPassword) {
      showToast("New passwords do not match", "error")
      setIsPasswordChanging(false)
      return
    }

    if (passwordErrors.length > 0) {
      passwordErrors.forEach((error) => showToast(error, "error"));
      setIsPasswordChanging(false);
      return;
    }
    try {
      await changePassword(cognitoId, currentPassword, newPassword);
      setCurrentPassword('')
      setNewPassword("")
      setConfNewPasswrod('')
      showToast("Password updated successfully!", "success");
    } catch (error) {
      showToast("Incorrect password", "error");
    } finally {
      setIsPasswordChanging(false);
    }
  }

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setIsImageUploading(true);
    try {
      const payload = {
        full_name: formatName(firstName, lastName),
        role: title,
        department: department,
        postal_code: postalCode,
        // profile_img: profileImgUrl,
        user_id: userId,
      };
      if (file) {
        await getSignedUrlAndUploadImage(file);
      }
      const response = await updateBrandProfile(payload);

      // If the response is successful, update the state and show a success toast
      if (!response.errorMessage) {
        showToast('Profile updated successfully.', 'success');
        await fetchBrandProfile();
      } else {
        showToast('Error updating profile.', 'error');
      }
      setIsImageUploading(false);
    } catch (error) {
      setIsImageUploading(false);
      showToast('Error updating profile.', 'error');
    }
  }


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file?.type?.includes('image')) {
      showToast('Only jpg, jpeg, svg, png or gif are accepted', "error")
      return
    }
    if (file) {
      setFile(file)
      setSelectedImg(URL.createObjectURL(file));
    }
  };

  const getSignedUrlAndUploadImage = async (file) => {
    let response = [];

    // Check if the file is provided
    if (file) {
      const key = `profile/brand/${userId}/${ulid()}`;

      // Get the signed URL to upload the file
      const { urls } = await getUploadUrl([key]);
      response = { name: file.name, key };

      // Upload the file to S3
      await uploadFileTos3(urls[0], file);

      setS3Key(key);

      const payload = { user_id: userId || "", profile_img: key };

      // Update the profile with the new image key
      await updateBrandProfile(payload);
    } else {
      // If no file is selected, we proceed with just updating other profile details
      response = { name: '', key: '' };
    }

    const userData = await fetchBrandProfile();

    // Dispatch the profile data and update the Redux store
    dispatch(profile(userData?.profile_img || ""));
    dispatch(ProfileDataDispatcher(userData));
  };



  async function fetchBrandProfile() {
    try {

      let response = await getBrandProfile({ user_id: userId });
      let brandData = response.data;
      const nameParts = brandData?.full_name.split(' ');
      // setFullName(formatName(brandData?.first_name, brandData?.last_name))
      if (brandData?.full_name) {
        setFirstName(nameParts[0] || '');
        setLastName(nameParts.slice(1).join(' ') || '');
      }
      setBrandName(brandData?.brand_name)
      setTitle(brandData?.role)
      setProfileImgUrl(brandData?.profile_img)
      setDepartment(brandData?.department)
      setPostalCode(brandData?.postal_code)
      setCognitoId(brandData?.cognito_id || "")
      dispatch(profile(brandData.profile_img || ""));
      dispatch(loginSuccess({ fullName: brandData?.full_name }))

      setInitialValues({
        firstName: nameParts[0] || '',
        lastName: nameParts.slice(1).join(' ') || '',
        title: brandData?.role || '',
        department: brandData?.department || '',
        postalCode: brandData?.postal_code || '',
        profileImgUrl: brandData?.profile_img || '',
        cognitoId: brandData?.cognito_id || '',
      });

      return brandData
    } catch (error) {
      console.log(error)
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    fetchBrandProfile();
  }, [userId])


  if (userType === 'brand') return (

    <div className="content-area">
      <div className="row m-0 ">
        <div className="col-lg-12 px-0 ">
          <div className="h-[185px] relative mb-2">
            <img
              src="/home/header.png"
              alt="Home Page Banner"
              className="w-full max-h-full min-h-full object-cover"
            />
            {/* <img
              src="/Settings/Settings_banner.webp"
              alt="Home Page Banner"
              className="w-full max-h-full min-h-full object-cover"
            /> */}
            {/* <div className="rounded-full absolute z-10 overflow-hidden top-[128px] w-[174px] h-[174px]  left-[50px] border-1">
              <img src={profileImgUrl || "/home/circle2.png"} className="max-w-[100%] scale-[103%] object-cover" alt="Circle Page Banner" />
            </div> */}
            <div className="rounded-full absolute z-10 overflow-hidden top-[108px] w-[174px] h-[174px] left-[50px] border-1s bg-gray-400s">

              {profileImgUrl ? <img src={profileImgUrl || "/home/circle2.png"} className="w-full h-full object-cover" alt="__" /> : <GrayCameraIcon size={154} />}
            </div>
          </div>
        </div>

        <div className="col-lg-11 mt-3 mb-5 custom-max-width">
          <div className="row align-items-center">
            <div className="col-lg-12 ">
              <div className="flex justify-between gap-4">

                <div className='pl-[247px]'>
                  {/* <div className='text-[30px] font-[600] text-primary-black'>{fullName || "-"}  </div> */}
                  <div className='text-[30px] font-[600] text-primary-black'>{brandName}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-lg-10 custom-max-width  mt-5">
          <form autoComplete='off' className=' px-[55px]'>
            <div className=' border-b-[1px] py-2  border-text-seconday-gray '>
              <h4 className='font-[600] text-primary-black'>Personal Info</h4>
              <p>Update your photo and personal details here.</p>
            </div>
            <div className=' border-b-[1px]  py-4 flex  border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Name</h6>
              <div className=' w-full'>
                <div className='w-[71%] flex gap-5 justify-between'>

                  <div className='w-6/12'>
                    <InputText className=' w-full p-2' value={(firstName)} placeholder='Enter first name' onChange={(e) => setFirstName(e.target.value.replace(/\s/g, ''))} />
                  </div>
                  <div className='w-6/12'>
                    <InputText className=' w-full p-2' value={lastName} placeholder='Enter last name' onChange={(e) => setLastName(e.target.value.replace(/\s/g, ''))} />
                  </div>
                </div>
              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Title/Role</h6>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%]'>
                  <InputText className='  w-full p-2' value={title} placeholder='Enter title' onChange={(e) => setTitle(e.target.value.replace(/^\s+/, ''))} />
                </div>

              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Department</h6>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%]'>
                  <InputText className='  w-full p-2' value={department} placeholder='Enter department' onChange={(e) => setDepartment(e.target.value.replace(/^\s+/, ''))} />
                </div>

              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Postal Code</h6>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%]'>
                  <InputText type='number' className='w-full p-2' value={postalCode} min="0" placeholder='Enter postal code' onChange={(e) => setPostalCode(e.target.value)} autoComplete='off' name="postal_code" />
                </div>

              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <div className='col-3'>
                <h6 className='font-[600] col-3 text-primary-black !w-full'>Your Photo</h6>
                <div>This will be displayed on your profile.</div>
              </div>
              <div className=' gap-5 w-full '>
                <div className='w-[71%]'>
                  <div className='flex gap-5'>

                    <div>
                      <div className="rounded-full overflow-hidden top-[128px] w-[70px] h-[70px] border-1">
                        {(selectedImg || profileImgUrl) ? <img src={selectedImg || profileImgUrl || "/home/circle2.png"} className="w-full h-full object-cover" alt="Circle Page Banner" />
                          : <GrayCameraIcon size={70} />
                        }                      </div>
                    </div>

                    <label className="upload-control" htmlFor="upload-input">
                      <input
                        type="file"
                        id="upload-input"
                        accept="image/jpeg, image/png, image/gif, image/jpg,image/svg"

                        onChange={handleImageChange}

                      />
                      <div className="uploadicon p-2 mb-3 rounded-md">
                        {/* <UploadIcon /> */}
                        <CloudUploadIcon />
                      </div>
                      <div className="upload-options">
                        <span className="click">Click to upload
                          {/* <span className='text-gray-500'>or drag and drop</span> */}
                        </span>
                      </div>
                      <div className=" mt-0 p-0 text-gray-500">
                        jpg, jpeg, svg, png or gif (max. 800×800px)
                      </div>
                    </label>
                  </div>

                </div>
                <div className='flex justify-end'>
                  <div className="flex gap-3 pt-2">
                    <button onClick={handleCancel} className="button-secondary-sw text-primary-black" style={{ width: '80px' }}> Cancel </button>
                    <button className="button-primary-sw  " style={{ width: '120px' }} disabled={isImageUploading} onClick={handleSaveChanges}>{isImageUploading ? "Updating..." : "Save Changes"}</button>
                  </div>
                </div>
              </div>
            </div>

            {/* Reset password */}
            <div>
              <div className=' py-2'>
                <h4 className='font-[600] text-primary-black mt-3'>Security</h4>
                <p>Please enter your current password to change your password</p>
              </div>

              <div className='border-b-[1px] py-4 flex border-text-seconday-gray'>
                <h6 className='font-[600] col-3 text-primary-black'>Current password</h6>
                <div className='flex gap-5 w-full'>
                  <div className='w-[71%] relative'>
                    <InputText
                      style={{ webkitTextSecurity: passwordVisible ? 'none' : 'disc', textSecurity: passwordVisible ? 'none' : 'disc' }}
                      isImportant={true}
                      className='w-full p-2'
                      value={currentPassword}
                      placeholder='Enter current password'
                      onChange={(e) => setCurrentPassword(e.target.value.replace(/^\s+/, ''))}
                    />
                    <div
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <EyeOff /> : <EyeOn />}
                    </div>
                  </div>
                </div>
              </div>

              <div className=' border-b-[1px] border-text-seconday-gray py-4'>
               <div className='flex'>
                <h6 className='font-[600] col-3 text-primary-black'>New Password</h6>
                <div className='gap-1 w-[60%]'>
                  <div className='relative w-[89%]'>
                    <InputText style={{borderColor:'#dod5dd', webkitTextSecurity: newPasswordVisible ? 'none' : 'disc', textSecurity: newPasswordVisible ? 'none' : 'disc' }} isImportant={true} className='w-auto p-2' value={newPassword} placeholder='Enter new password' onChange={(e) => setNewPassword(e.target.value.replace(/^\s+/, ''))} />
                    <button
                      type="button"
                      onClick={toggleNewPasswordVisibility}
                      className="absolute right-3 top-5 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                    >
                    {newPasswordVisible ? (
                      <EyeOff/>
                      ) : (
                      <EyeOn/>
                    )}
              
                   </button>
                    <span className='text-[#475467]' >Your new password must be more than 8 characters.</span>
                  </div>
                </div>
               </div>
              </div>
              <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
                <h6 className='font-[600] col-3 text-primary-black'>Confirm new password</h6>
                <div className='flex gap-5 w-full'>
                  <div className='w-[71%] relative'>
                    <InputText style={{ webkitTextSecurity: confirmNewPasswordVisible ? 'none' : 'disc', textSecurity: confirmNewPasswordVisible ? 'none' : 'disc' }} isImportant={true} className='  w-full p-2' value={confNewPassword} placeholder='Enter new password again' onChange={(e) => setConfNewPasswrod(e.target.value.replace(/^\s+/, ''))} />
                    <div
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={toggleConfirmNewPasswordVisibility}
                    >
                      {confirmNewPasswordVisible ? <EyeOff /> : <EyeOn />}
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-end'>
                <div className="flex gap-3 pt-2">
                  <button onClick={(e) => {
                    e.preventDefault();
                    setCurrentPassword('')
                    setNewPassword('')
                    setConfNewPasswrod('')
                  }} className="button-secondary-sw text-primary-black" style={{ width: '80px' }}> Cancel </button>
                  <button className="button-primary-sw  " style={{ width: '150px' }} disabled={isPasswordChanging} onClick={handlePasswordChange}>{isPasswordChanging ? "Updating..." : "Update Password"}</button>
                </div>
              </div>
            </div>

          </form>
        </div>



      </div>
    </div>


  );
  else return (
    <ComingSoon />
  );
};

export default Settings;
