// src/api/openai.js
import OpenAI from "openai";

const openaiApiKey = process.env.REACT_APP_OPEN_AI_API_KEY || '';


const openai = new OpenAI({ apiKey: openaiApiKey, dangerouslyAllowBrowser: true });



export const chatAboutImage = async (image, message) => {
    if (!image) return
    let imageInput;
    if (typeof image === 'string' && image?.startsWith('https://')) {
        imageInput = image;
    }
    else {
        imageInput = `data:image/jpeg;base64,${image}`;
    }
    const response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
            {
                role: "user",
                content: [
                    { type: "text", text: message || "What’s in this image?" },
                    {
                        type: "image_url",
                        image_url: {
                            "url": imageInput || "",
                        },
                    },
                ],
            },
        ],
    });
    return response;
};


