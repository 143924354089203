import React, { useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import CommissionContent from '../../../components/commission-content/CommissionContent';
import CustomTabs from '../../../components/custom-tabs/CustomTabs';
import TimelineTracker from '../../../components/progress-status/TimelineTracker';
import { useSelector } from 'react-redux';

function formatDate(isoDateString) {
  // Parse the ISO 8601 string into a Date object
  const date = new Date(isoDateString);

  // Check if the date is valid
  if (isNaN(date)) {
    return '-'
  }

  

  // Format the date into "Dec 17, 2024"
  const result = date.toLocaleDateString('en-US', {
    month: 'short',  // Abbreviated month (e.g., Dec)
    day: '2-digit',  // Day as 2 digits (e.g., 17)
    year: 'numeric'  // Full year (e.g., 2024)
  });

  return result 
}


function getStatusForDate(dateString, commission) {

  if(commission.isAccepted===0) {
    return 'incomplete'
  }

  if(commission.status === 'submitted') {
    return 'completed'
  }

  if(!dateString) {
    return 'incomplete'
  }

  // Convert the provided date string into a Date object
  const providedDate = new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  // Reset the time to 00:00:00 for both dates to only compare the date (year, month, day)
  providedDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // Compare the dates
  if (providedDate < currentDate) {
    return "completed"; // If the provided date is in the past
  } else if (providedDate.getTime() === currentDate.getTime()) {
    return "current"; // If the provided date is exactly the same as the current date
  } else {
    return "incomplete"; // If the provided date is in the future
  }
}


function CommissionReviewAndContentForSchool({isAccepted}) {
  const commissionDetails = useSelector(state=>state.commissionContent?.commissionDetails)
  const location = useLocation()
  // const commissionToDisplay = location?.state?.commissionToDisplay
  const commissionToDisplay = commissionDetails
  // const commissionToDisplay = {
  //   isAccepted: 1, // Project accepted (1 means accepted, 0 means not accepted)
  //   accepted_date: '2024-12-01', // Date the project was accepted
  //   first_review_date: '2024-12-10', // First review date
  //   second_review_date: '2025-12-15', // Second review date
  //   endDate: '2024-12-20', // Final submission date
  //   created_at: '2024-11-01', // Date the commission was created
  //   status:'submitted'

  // };

  const tabs = [
    {
      title: 'Revision 1',
      content: <CommissionContent isAccepted={isAccepted} pageType="revision_1" />
    },
    {
      title: 'Revision 2',
      content: <CommissionContent isAccepted={isAccepted} pageType="revision_2" />
    },
    {
      title: 'Final work',
      content: <CommissionContent isAccepted={isAccepted} pageType="final_work" />
    }
  ]


  let steps = [
    {
        title: "Project accepted",
        description: "You have accepted the project, and work has started.",
        // date: commissionToDisplay.isAccepted === 1 ?  formatDate(commissionToDisplay?.accepted_date || commissionToDisplay?.created_at ) : '-',
        status: commissionToDisplay.isAccepted === 1 ? 'completed' : 'incomplete'
    },
    {
        title: "Revision 1",
        description: "First version review",
        date: commissionToDisplay?.first_review_date ? formatDate(commissionToDisplay?.first_review_date) : '-',
        status: commissionToDisplay?.first_review_date === commissionToDisplay?.second_review_date?"completed":getStatusForDate(commissionToDisplay.first_review_date, commissionToDisplay)
    },
    {
        title: "Revision 2",
        description: "Second version review",
        date: commissionToDisplay?.second_review_date ? formatDate(commissionToDisplay?.second_review_date) : '-',
        status:(commissionToDisplay?.submitted_at || commissionToDisplay?.updated_at) === commissionToDisplay?.second_review_date?"completed":getStatusForDate(commissionToDisplay.second_review_date, commissionToDisplay)
    },
    {
        title: "Final work ready!",
        description: "Submit final work",
        date: commissionToDisplay?.isSubmitted ? formatDate(commissionToDisplay?.submitted_at || commissionToDisplay?.updated_at) : '-',
        status: commissionToDisplay?.isSubmitted ? getStatusForDate(commissionToDisplay?.submitted_at || commissionToDisplay?.updated_at, commissionToDisplay) : 'incomplete'
      }
]


if(!commissionToDisplay?.first_review_date || !commissionToDisplay?.second_review_date ){
  steps = [
    steps[0],steps[3],
  ]
}

const tabReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TAB':
      return action.payload; // Update the current tab based on the action's payload
    default:
      return state;
  }
};

  const initialTab = 0
  const [currentTab, dispatch] = useReducer(tabReducer, initialTab); 
  // Function to change the current tab
  const changeCurrentTabFn = () => {
    const isAccepted = commissionDetails?.isAccepted || 0;
    const currDt = new Date(); // Current date/time
    const r1Dt = commissionDetails?.first_review_date ? new Date(commissionDetails.first_review_date) : null;
    const r2Dt = commissionDetails?.second_review_date ? new Date(commissionDetails.second_review_date) : null;
    const finalDt = commissionDetails?.submitted_at ? new Date(commissionDetails.submitted_at) : null;
    const isSubmitted = commissionDetails?.isSubmitted;
    let newTab = 0;
  
    if (!isAccepted) {
      newTab = 0;
    } else if (isSubmitted) {
      newTab = 2;
    } else if (finalDt && finalDt <= currDt) { // Check if finalDt is valid and compare
      newTab = 2;
    } else if (r2Dt && r2Dt <= currDt) { // Check if r2Dt is valid and compare
      newTab = 1;
    } else if (r1Dt && r1Dt <= currDt) { // Check if r1Dt is valid and compare
      newTab = 0;
    } 
  
    // Dispatch the new tab state
    dispatch({ type: 'SET_TAB', payload: newTab });
  };

  useEffect(()=>{
    changeCurrentTabFn()
  },[commissionDetails])
  
  return (
    <div className="flex flex-col gap-4 w-full my-8">
      <p className="font-semibold text-2xl">Commission progress and content</p>
      <div className="w-[60%] mx-auto">
        <TimelineTracker steps={steps} />
      </div>
      <CustomTabs currentTab={currentTab} tabs={tabs} />
    </div>
  )
}

export default CommissionReviewAndContentForSchool