// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";

// import "./CardContainer.css";
// import CustomImage from "./CustomImage/CustomImage";
// import VideoPlayer from "./video/player/VideoPlayer";

// export function Card({ images, title, description, commissionid, islongCards, isIcon = true }) {
//   const [imageUrls, setImageUrls] = useState([]);
//   // const fetchedImagesRef = useRef(false); // Ref to ensure API call happens only once
//   useEffect(() => {
//     const cachedImages = images?.slice(0, 6)
//     setImageUrls(cachedImages);
//   }, [images])

//   const cardClassName = islongCards ? "card first-card" : "card";

//   return (
//     <div className={islongCards ? 'col-span-2' : 'col-span-1'}>
//       <Link
//         to={`/comm-galleries?commissionid=${commissionid}&commissionname=${title}`}
//         className="card-link"
//       >
//         <div className={cardClassName}>
//           <div className="card-images">
//             <div className="column-full">

//               {imageUrls[0] && (
//                 !imageUrls[0].thumbnail_url ?
//                   <CustomImage
//                     src={imageUrls[0].thumbnail_url || imageUrls[0].url}
//                     alt={imageUrls[0].alt || "Image"}
//                     imageClass="card-image"
//                   // cacheKey={imageUrls[0].key}
//                   />
//                   :
//                   <VideoPlayer
//                     key={imageUrls[0].url|| ''}
//                     src={imageUrls[0].thumbnail_url || imageUrls[0].url}
//                     alt={imageUrls[0].alt || "Image"}
//                     imageClass="card-image"
//                     // onImageClick={() => handleImageClick(media)}
//                     shouldPlay={false}
//                     videoUrl={imageUrls[0].url}
//                   ></VideoPlayer>
//               )}
//             </div>
//             <div className="column-half">
//               {imageUrls[1] && (
//                 <CustomImage
//                   src={imageUrls[1].thumbnail_url || imageUrls[1].url}
//                   alt={imageUrls[1].alt || "Image"}
//                   imageClass="card-image-half"
//                   // cacheKey={imageUrls[1].key}
//                   imageContainerClass="height-50"
//                 />
//               )}
//               {imageUrls[2] && (
//                 <CustomImage
//                   src={imageUrls[2].thumbnail_url || imageUrls[2].url}
//                   alt={imageUrls[2].alt || "Image"}
//                   imageClass="card-image-half"
//                   // cacheKey={imageUrls[2].key}
//                   imageContainerClass="height-50"
//                 />
//               )}
//             </div>
//             {islongCards && (
//               <>
//                 <div className="column-full">
//                   {imageUrls[3] && (
//                     <CustomImage
//                       src={imageUrls[3].thumbnail_url || imageUrls[3].url}
//                       alt={imageUrls[3].alt || "Image"}
//                       imageClass="card-image"
//                     // cacheKey={imageUrls[3].key}
//                     />
//                   )}
//                 </div>
//                 <div className="column-half">
//                   {imageUrls[4] && (
//                     <CustomImage
//                       src={imageUrls[4].thumbnail_url || imageUrls[4].url}
//                       alt={imageUrls[4].alt || "Image"}
//                       imageClass="card-image-half"
//                       // cacheKey={imageUrls[4].key}
//                       imageContainerClass="height-50"
//                     />
//                   )}
//                   {imageUrls[5] && (
//                     <CustomImage
//                       src={imageUrls[5].thumbnail_url || imageUrls[5].url}
//                       alt={imageUrls[5].alt || "Image"}
//                       imageClass="card-image-half"
//                       // cacheKey={imageUrls[5].key}
//                       imageContainerClass="height-50"
//                     />
//                   )}
//                 </div>
//               </>
//             )}
//           </div>

//           {isIcon ? <div className="card-content">
//             <img
//               alt="avatar"
//               className="avatar-image"
//               src="/Commission/Avatar.png"
//             />
//             <div className="title-description">
//               <h3 className="card-title">{title}</h3>
//               <p className="card-description">{description}</p>
//             </div>
//           </div> :
//             <div className="p-2 m-0">
//               <h3 className="card-title mt-3">{title}</h3>
//               <p className="card-description mb-0">{description}</p>
//             </div>
//           }
//         </div>
//       </Link>
//     </div>
//   );
// }

// function CardContainer({ cards }) {
//   const isFirstLong = cards.length % 2 === 1
//   return (
//     <div className="card-container grid grid-cols-2">
//       {cards.map((card, index) => {
//         let isLong = isFirstLong && (index === 0)
//         return (
//           <React.Fragment key={index}>
//             <Card
//               images={card.images}
//               title={card.name}
//               description={`${card.campaign_goal}`}
//               commissionid={card.id}
//               islongCards={isLong}
//             />
//             {/* {index === 0 && cards.length > 1 && (
//             <div className="gallery-label">Your content</div>
//           )} */}
//           </React.Fragment>
//         )
//       })}
//     </div>
//   );
// }

// export default CardContainer;

























import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./CardContainer.css";
import CustomImage from "./CustomImage/CustomImage";
import VideoPlayer from "./video/player/VideoPlayer";
import placeholder1 from '../components/cards/placeholder-images/CardPlaceholder1.svg'
import placeholder2 from '../components/cards/placeholder-images/CardPlaceholder2.svg'
import placeholder3 from '../components/cards/placeholder-images/CardPlaceholder3.svg'
import MediaGridCard from "./cards/MediaGridCard";

const placeholder = [placeholder1, placeholder2, placeholder3]


export function Card({ images, title, description, commissionid, islongCards, isIcon = true }) {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    if (images && images.length) {
      // Limit to 6 images and update state
      setImageUrls(images.slice(0, 6));
    }
  }, [images]);

  const cardClassName = islongCards ? "card first-card" : "card";

  const renderMedia = ({ media = {}, imageClass = "card-image", imageContainerClass = "", index }) => {
    const { url, thumbnail_url, alt } = media;
    const mediaSrc = thumbnail_url || url;

    return thumbnail_url ? (
      <VideoPlayer
        key={url}
        src={mediaSrc}
        alt={alt || "Media"}
        imageClass={imageClass}
        imageContainerClass={imageContainerClass}
        shouldPlay={false}
        videoUrl={url}
      />
    ) : (
      <CustomImage
        src={mediaSrc || placeholder[index % 3]}
        alt={alt || "Image"}
        imageClass={mediaSrc ? imageClass : 'w-full h-full object-fill'}
        imageContainerClass={imageContainerClass}
      />
    );
  };

  return (
    <div className={islongCards ? "col-span-2" : "col-span-1"}>
      <Link
        to={`/comm-galleries?commissionid=${commissionid}&commissionname=${title}`}
        className="card-link"
      >
        <div className={cardClassName}>
          <div className="card-images">
            <div className="column-full">
              {renderMedia({ media: imageUrls[0], index: 0 })}
            </div>
            <div className="column-half ">
              {renderMedia({ media: imageUrls[1], imageClass: "card-image-half", imageContainerClass: "height-50", index: 1 })}
              {renderMedia({ media: imageUrls[2], imageClass: "card-image-half", imageContainerClass: "height-50", index: 2 })}
            </div>
            {islongCards && (
              <>
                <div className="column-full">
                  {renderMedia({ media: imageUrls[3], index: 0 })}
                </div>
                <div className="column-half">
                  {renderMedia({ media: imageUrls[4], imageClass: "card-image-half", imageContainerClass: "height-50", index: 1 })}
                  {renderMedia({ media: imageUrls[5], imageClass: "card-image-half", imageContainerClass: "height-50", index: 2 })}
                </div>
              </>
            )}
          </div>

          {isIcon ? (
            <div className="card-content">
              <img alt="avatar" className="avatar-image" src="/Commission/Avatar.png" />
              <div className="title-description">
                <h3 className="card-title">{title}</h3>
                <p className="card-description">{description}</p>
              </div>
            </div>
          ) : (
            <div className="p-2 m-0">
              <h3 className="card-title mt-3">{title}</h3>
              <p className="card-description mb-0">{description}</p>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

const truncateText = (text, maxLength) =>{
  if(!text) return "";
  return text.length > maxLength? `${text.slice(0, maxLength)}...` : text;
}

function CardContainer({ cards, displayMore=false }) {
  const isFirstLong = cards.length % 2 === 1;
  const navigate = useNavigate()
  const textLength = 65;
  return (
    <div className={`card-container grid ${displayMore ? 'grid-cols-1' : 'grid-cols-2'}`}>
      {cards.map((card, index) => {
        const isLong = isFirstLong && index === 0;
        return (
          <React.Fragment key={index}>
            {/* <Card
              images={card.images}
              title={card.name}
              description={card.campaign_goal}
              commissionid={card.id}
              islongCards={isLong}
            /> */}
            <MediaGridCard
              containerClass="h-[300px] w-full"
              mediaCount={card?.images?.length||0}
              mediaItems={card?.images||[]}
              displayMore={displayMore}
              onCardClick={() => {
                navigate(card?.status === 'in_progress'
                  ? `/commissions`
                  : `/comm-galleries?commissionid=${card?.id}&commissionname=${card?.name}`)
              }}
            >
              <div className="card-content">
                <img alt="avatar" className="avatar-image" src="/Commission/Avatar.png" />
                <div className="title-description">
                  <h3 className="card-title">{card?.name||''}</h3>
                  <p className="card-description">{truncateText(card?.campaign_goal || "", textLength)}</p>
                </div>
              </div>
            </MediaGridCard>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default CardContainer;
