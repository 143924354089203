import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { PreviousRouteProvider } from "../contexts/PreviousRouteContext";

function PrivateRoute({ children }) {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return <PreviousRouteProvider>{children}</PreviousRouteProvider>;
}

export default PrivateRoute;
