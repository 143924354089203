// import React, { useMemo } from 'react'
// import PrimaryFilledBtn from '../form-elements/PrimaryFilledBtn'
// import CustomTabs from '../custom-tabs/CustomTabs'
// import CommissionListingTable from './CommissionListingTable'
// import { useNavigate } from 'react-router-dom';
// import SingleActiveCommission from './commission-details/SingleActiveCommission';

// const MemoizedCommissionListingTable = React.memo(CommissionListingTable);


// function CommissionTabForTable() {

//   const navigate = useNavigate()

//     const tabs = useMemo(() => [
//       {
//         title: 'Active Commissions',
//         content: <CommissionListingTable status="in_progress" key="in_progress" />
//       },
//         {
//           title: 'Single Active commissions',
//           content: <SingleActiveCommission />
//         },
//         {
//           title: 'Commission History',
//           content: <MemoizedCommissionListingTable status="submitted" key="submitted" />  //status="history" key="history" 
//         }
//       ], []);
//   return (
//     <div className="w-full flex flex-col gap-4 mt-12 px-11">
//         <div className="w-full flex flex-row justify-between">
//             <span className="text-3xl font-semibold">
//                 Commissions
//             </span>

//             <span className='w-72'>

//             <PrimaryFilledBtn onClick={()=>navigate('/commissions/create')} label='New Commission' />
//             </span>
//         </div>

//         <CustomTabs tabs={tabs} />
//     </div>
//   )
// }

// export default CommissionTabForTable

import React from 'react';
import PrimaryFilledBtn from '../form-elements/PrimaryFilledBtn';
import CommissionListingTable from './CommissionListingTable';
import { useNavigate } from 'react-router-dom';

function CommissionTabForTable() {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col gap-4 mt-12 px-11">
      <div className="w-full flex flex-row justify-between">
        <span className="text-3xl font-semibold">Commissions</span>

        <span className='w-72'>
          <PrimaryFilledBtn onClick={() => navigate('/commissions/create')} label='New Commission' />
        </span>
      </div>

      <CommissionListingTable />
    </div>
  );
}

export default CommissionTabForTable;