const initialState = {
    imageData: [],
    scrollPosition: 0,
    page:1,
    preventFetchingData: false//While comming back from some pages like imageDetails we don't need to fetch data again. So we stop fetching using this state.
}

const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GALLERY_DATA_SUCCESS": {

           return {
            ...state,
            imageData:[...state.imageData, ...action.payload.imageData]
           }
            // if(state.imageData?.[0]?.id !== action.payload.imageData?.[0]?.id) {

            //     return {
            //         ...state,
            //         imageData: state.page === 1 ? action.payload.imageData : [...state.imageData, ...action.payload.imageData]
            //     }
            // } else {
            //     return {
            //         ...state,
            //         imageData: state.page === 1 ? action.payload.imageData : [...state.imageData,...action.payload.imageData]
            //     }
            // }
        }
        case "GALLERY_DATA_PAGE":
            return {
                ...state,
                page: action.payload.page
            }
        case "GALLERY_DATA_FILTER_PURCHASED":
            const newData = state.imageData.filter((item) => item.id !== action.payload.purchased.id);
            return {
                ...state,
                imageData: newData
            }
        case "GALLERY_DATA_PREVENT_FETCHING_DATA":
            return {
                ...state,
                preventFetchingData: action.payload.value
            }
        case "GALLERY_DATA_IMAGE_SCROLL_POSITION":
            return {
                ...state,
                scrollPosition: action.payload.scrollPosition
            }
        default:
            return state
    }
}

export default galleryReducer;