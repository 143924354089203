export const downloadImageUtil = async({setLoading=()=>null, imageUrl, imageName="image.jpg"}) => {

    if(!imageUrl) return;
    try {
        setLoading(true)
        const response = await fetch(imageUrl);

        const contentType = response.headers.get("Content-Type");
        const fileExtension = contentType ? contentType.split("/")[1] : "jpg";
        const blob = await response.blob();

        const finalFileName = `${imageName}.${fileExtension}`;
  
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', finalFileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setLoading(false)
      } catch (error) {
        console.log('Error downloading image:', error);
        setLoading(false)
      }
}