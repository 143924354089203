



import React from 'react'

const DottedCircle = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_408_8997)">
                <path d="M18.4329 10.8107C18.4329 15.42 14.6963 19.1565 10.087 19.1565M18.4329 10.8107C18.4329 6.2014 14.6963 2.46484 10.087 2.46484M18.4329 10.8107H15.0945M10.087 19.1565C5.47777 19.1565 1.74121 15.42 1.74121 10.8107M10.087 19.1565V15.8182M1.74121 10.8107C1.74121 6.2014 5.47777 2.46484 10.087 2.46484M1.74121 10.8107H5.07954M10.087 2.46484V5.80318" stroke="white" stroke-width="1.67432" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_408_8997">
                    <rect width="20.03" height="20.03" fill="white" transform="translate(0.0722656 0.794922)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DottedCircle