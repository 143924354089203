
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CommentSection from './CommentSection'
import { BackIcon, CommentIcon,InfoIcon, DownloadIcon, LeftSwipe, RightSwipe } from './SendIcon'
import { Stage, Layer, Rect, } from "react-konva";
import DottedCircle from '../../icons/DottedCircle'
import VideoPlayer from '../video/player/VideoPlayer'
import BrandOptions from './BrandOptions'
import CreatorOptions from './CreatorOptions'
import { getRevisionData } from '../../redux/reducerFiles/commission-content/commissionContentReducer'
import LoadingWrapper from '../loadingWrapper/LoadingWrapper'



export default function ImageReviewAndComment() {
  const navigate = useNavigate();
  const location = useLocation()
  const pageType = location?.state?.pageType || ''
  const { commissionDetails } = useSelector((state) => state.commissionContent);
  const revisionData = useSelector((state) => getRevisionData(state, pageType));

  const { userId, userType } = useSelector((state) => state.user);
  const [showComments, setShowComments] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Comments'); 
  const mediaForReview = revisionData?.data
  const mediaForReviewCurrentIndex = location.state?.mediaForReviewCurrentIndex
  const [currentImageIndex, setCurrentImageIndex] = useState(mediaForReviewCurrentIndex || 0)
  const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);
  // const [linesHistory, setLinesHistory] = useState([]);
  const [enableSquare, setEnableSquare] = useState(false); // for square tool
  const [startPos, setStartPos] = useState(null); // Store starting point for square drawing
  const [currentSquare, setCurrentSquare] = useState([]); // Current square being drawn
  const [squaresHistory, setSquaresHistory] = useState([]); // Store the squaresHistory
  const [currentSquareState, setCurrentSquareState] = useState([]);
  const [newTabValue, setNewTabValue] = useState(0)
  const [currActionBtn, setCurrActionBtn] = useState('')

  const fetchComment = (taskId, version) => {
    if (!taskId) return;
  };
  const addComments = (taskId, commentsData) => {
    if (!taskId || !commentsData) return;
  };


  const reset = () => {
    setEnableSquare(false)
    setStartPos(false)
    setCurrentSquare([])
    setSquaresHistory([])
    setCurrentSquareState([])
  }


  const navigateImage = (direction) => {
    let idx;
    if (direction === 'prev') {
      idx = (currentImageIndex > 0 ? currentImageIndex - 1 : mediaForReview.length - 1)
    } else {
      idx = (currentImageIndex < mediaForReview.length - 1 ? currentImageIndex + 1 : 0)
    }
    reset()
    setCurrentImageIndex(idx)
  }



  const undoLine = () => {

    if (currentSquareState && !currentSquareState?.length) return;
    setCurrentSquareState(currentSquareState.slice(0, -1));
  };

  // ------------handle comments part-------------

  const handleCommentClick = (i) => {
    setSelectedCommentIndex(i === selectedCommentIndex ? null : i);
  };


  // ------------------------------





  function getImageDimensions(url, callback) {
    const img = new window.Image();

    // Set up the onload event to retrieve the image dimensions once it is fully loaded
    img.onload = function () {
      const width = img.width;
      const height = img.height;
      callback(width, height); // Call the callback with width and height
    };

    // Handle error case if the image fails to load
    img.onerror = function () {
      callback(null, null); // Return null if there's an error loading the image
      console.error('Error loading image from URL');
    };

    // Set the image source (this triggers the loading)
    img.src = url;
  }

  const [dimensions, setDimentions] = useState({
    width: 0,
    height: 0
  })
  // Example usage

  const contentDetail = mediaForReview[currentImageIndex]
  const imageUrl = mediaForReview[currentImageIndex]?.thumbnailUrl || mediaForReview[currentImageIndex]?.url;

  const originalImageUrl = mediaForReview[currentImageIndex]?.url
  const imageName = mediaForReview[currentImageIndex]?.id + ".jpeg"

  const changeTab = () => {
  
  }

  useEffect(() => {
    getImageDimensions(imageUrl, (width, height) => {
      if (width && height) {
        setDimentions({
          width,
          height
        })
      } else {
        console.log('Failed to load image dimensions');
      }
    });
  }, [])


  // Get the final width of the image after maintaining the aspect ratio
  const getFinalImageWidth = (imageWidth, imageHeight, maxWidth, maxHeight) => {
    const imageAspectRatio = imageWidth / imageHeight;


    // If the image is landscape (width > height), adjust based on the maxWidth
    if (imageAspectRatio > 1) {
      return Math.min(imageWidth, maxWidth);
    }

    // If the image is portrait (height > width), adjust based on the maxHeight
    const finalWidth = Math.min(maxHeight * imageAspectRatio, maxWidth);
    return finalWidth;
  };

  // Get the final height of the image after maintaining the aspect ratio
  const getFinalImageHeight = (imageWidth, imageHeight, maxWidth, maxHeight) => {
    const imageAspectRatio = imageWidth / imageHeight;

    // If the image is landscape (width > height), adjust based on the maxWidth
    if (imageAspectRatio > 1) {
      return Math.min(imageHeight, maxHeight);
    }

    // If the image is portrait (height > width), adjust based on the maxHeight
    const finalHeight = Math.min(maxWidth / imageAspectRatio, maxHeight);
    return finalHeight;
  };

  // const handleSquareToggler = () => setEnableSquare((prev) => !prev);

  const handleMouseDown = (e) => {

    if (enableSquare) {
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();
      setStartPos(pos);
      setCurrentSquare({
        x: pos.x,
        y: pos.y,
        width: 0,
        height: 0
      });
    }
  };


  const handleMouseMove = (e) => {

    if (enableSquare && startPos) {
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();
      setCurrentSquare({
        x: startPos.x,
        y: startPos.y,
        width: pos.x - startPos.x,
        height: pos.y - startPos.y
      });
    }
  };

  const handleMouseUp = () => {
    if (enableSquare && startPos && currentSquare) {
      // setSquaresHistory([...squaresHistory, currentSquare]);
      setCurrentSquareState(prev => [...prev, currentSquare])
      setStartPos(null);
      setCurrentSquare(null);
    }
  };

  const handleSquareToggler = () => {
    setEnableSquare(prev => !prev);
    if(enableSquare){
      setShowComments(true)
    }
  };



  const handleClickAction = (action) => {
    if(currActionBtn===action) {
      setShowComments(b=>!b)
      return
    }

    if(action==='comment') {
      setShowComments(true)
      setNewTabValue(0)
    }
    if(action==='info') {
      setShowComments(true)
      setNewTabValue(1)
    }


    setCurrActionBtn(action)


    // if(showComments) {
    //   setShowComments(prev=>!prev)
    // }
    
    // if(action==='comment' && newTabValue===0){
    //   setShowComments(false)
    //   return
    // }
    // if(action==='info' && newTabValue===1){
    //   setShowComments(false)
    //   return
    // }
  }

  
  return (
    <div className="fixed inset-0 z-[99999] h-screen bg-black">
      {/* Main Content Container */}
      <div className={`h-full transition-all flex justify-between duration-300 ease-in-out ${"showComments" ? 'mr-96s' : ''}`}>
        <div className={`${showComments ? "col-10" : "col-12"} relative `}>
          {/* Header */}
          <header className={`absolute top-0 left-0  flex items-center justify-between p-4 text-white ${showComments ? 'right-12' : 'right-0'}`}>
            <button onClick={() => navigate(-1)} className="p-2 hover:bg-slate-500/75 rounded-full z-[999]">
              <BackIcon />
            </button>
            <div className="z-[999] transition-all duration-300 ease-in-out absolute right-12 mr-3 flex items-center gap-2 bg-black/50 px-2 py-1 rounded-lg">
              {
                userType === 'brand' &&
                <BrandOptions 
                contentDetail={contentDetail}
                contentId={mediaForReview[currentImageIndex]?.id}
                isRejected={mediaForReview[currentImageIndex]?.is_rejected}
                 />
              }
              {
                (userType === 'school' || userType === 'creator') &&
                <CreatorOptions contentDetail={contentDetail} />
              }

             {/*
              <ul className='mb-0 flex gap-3 items-center'>
                <li className='flex items-center gap-3 hover:bg-slate-500/75 p-2 rounded-full cursor-pointer'>
                  <button className=" rounded-full text-white hover:bg-black/75">
                    <svg className="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                  </button>
                  <span>Favourite</span>
                </li>
                <li className={`flex items-center gap-3 hover:bg-slate-500/75 p-2 rounded-full cursor-pointer ${ isDownload ? "cursor-not-allowed opacity-50" : ""}`} onClick={() => !isDownload && downloadImageUtil({ setLoading: setIsDownload,imageUrl: originalImageUrl || imageUrl, imageName })}>
                  <DownloadIcon /> <span>{isDownload ? "Downloading..." : "Download"}</span>
                </li>
                <li className={`flex items-center gap-3 hover:bg-slate-500/75 p-2 rounded-full cursor-pointer ${mediaForReview[currentImageIndex]?.is_rejected ? 'cursor-not-allowed opacity-50' : ''}`} onClick={
                  () => !mediaForReview[currentImageIndex]?.is_rejected && rejectContent(mediaForReview[currentImageIndex]?.id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" fill="none" stroke="white" stroke-width="2" />
                    <path d="M15.535 8.465a1 1 0 0 0-1.414 0L12 10.586 9.879 8.465a1 1 0 0 0-1.414 1.414L10.586 12l-2.121 2.121a1 1 0 0 0 1.414 1.414L12 13.414l2.121 2.121a1 1 0 0 0 1.414-1.414L13.414 12l2.121-2.121a1 1 0 0 0 0-1.414z" fill="white" />
                  </svg>
                  <span>
                  {mediaForReview[currentImageIndex]?.is_rejected ? "Rejected": isLoading ? "Rejecting..." : "Reject"}
                  </span>
                </li>
                <span className="font-medium">{contentDetail?.creator_name || ""}</span>
              </ul> */}
              {/* <DownloadIcon /> */}

            </div>
          </header>


          {/* Navigation Arrows */}
          <button
            className="absolute z-[999] left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-slate-500/75"
            onClick={() => navigateImage('prev')}
            aria-label="Previous image"
          >
            <LeftSwipe />
          </button>
          <button
            className={`absolute  z-[999]  top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-slate-500/75 ${showComments ? 'right-36' : 'right-0'}`}
            onClick={() => navigateImage('next')}
            aria-label="Next image"
          >
            <RightSwipe />
          </button>

          <LoadingWrapper loading={revisionData?.loading} >
            <div className={`relative w-full flex justify-center items-center align-middle  h-full`}>
              <div
                className=" inset-0  m-auto  h-[100vh]"
                style={{
                  width: ` ${getFinalImageWidth(dimensions.width, dimensions.height, window.innerWidth, window.innerHeight)}px`,
                  height: `${getFinalImageHeight(dimensions.width, dimensions.height, window.innerWidth, window.innerHeight)}px`,

                }}
              >
                {contentDetail?.media_type === "video" ?
                  <div className='w-full h-full flex justify-center items-center'>

                    <VideoPlayer
                      src={imageUrl || contentDetail?.thumbnailUrl}
                      videoUrl={contentDetail?.url} // Add videoUrl prop
                      showThumbnail={true}
                      alt={'alt-vid'}
                      imageContainerClass={'flex items-center'}

                    />
                  </div>
                  :
                  <div
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                      backgroundSize: "contain", // Keep the image aspect ratio intact
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: '100%', // Fill the container width
                      height: "100%", // Fill the container height
                      // backgroundColor: "gray"
                    }}
                  >
                    <Stage
                      width={getFinalImageWidth(dimensions.width, dimensions.height, window.innerWidth, window.innerHeight)}
                      height={getFinalImageHeight(dimensions.width, dimensions.height, window.innerWidth, window.innerHeight)}
                      onMouseDown={(enableSquare) ? handleMouseDown : undefined}
                      onMouseMove={(enableSquare) ? handleMouseMove : undefined}
                      onMouseUp={(enableSquare) ? handleMouseUp : undefined}
                      batchDraw
                    >
                      <Layer>
                        {/* Draw squaresHistory */}
                        {squaresHistory && squaresHistory.length > 0 && squaresHistory.map((square, index) => {
                          return (
                            <Rect
                              key={index}
                              x={square.x}
                              y={square.y}
                              width={square.width}
                              height={square.height}
                              fill="transparent"
                              stroke={selectedCommentIndex === square?.commentId ? "red" : "blue"}
                              strokeWidth={3}
                            />
                          )
                        })}

                        {currentSquareState && currentSquareState.length > 0 && currentSquareState.map((square, index) => {
                          return (
                            <Rect
                              key={index}
                              x={square.x}
                              y={square.y}
                              width={square.width}
                              height={square.height}
                              fill="transparent"
                              stroke={selectedCommentIndex === square?.commentId ? "red" : "green"}
                              strokeWidth={3}
                            />
                          )
                        })}


                        {/* Draw the square being drawn */}
                        {currentSquare && (
                          <Rect
                            x={currentSquare.x}
                            y={currentSquare.y}
                            width={currentSquare.width}
                            height={currentSquare.height}
                            fill="transparent"
                            stroke="yellow"
                            strokeWidth={3}
                          />
                        )}
                      </Layer>
                    </Stage>

                  </div>
                }
              </div>

            </div>
          </LoadingWrapper>



        </div>

      </div>

      {/* Bottom Actions */}
      <div className={`absolute bg-black/50 bottom-0 right-0 z-20 px-2 py-1 rounded-lg flex justify-end gap-2 transition-all duration-300 ease-in-out ${showComments ? 'right-96 mr-3' : 'right-0'}`}>
        {
          (contentDetail?.media_type !== "video" && userType==='brand' ) &&
          <>
            <button className={`p-3 rounded-full text-white hover:bg-slate-500/75 ${enableSquare ? "bg-slate-500/75" : ""}`} onClick={handleSquareToggler}>
              <DottedCircle />
            </button>
            <button className="p-3 rounded-full text-white hover:bg-slate-500/75" onClick={undoLine}>
              <svg xmlns="http://www.w3.org/2000/svg" fill='white' width="20" height="20" viewBox="0 0 24 24"><path d="M17.026 22.957c10.957-11.421-2.326-20.865-10.384-13.309l2.464 2.352h-9.106v-8.947l2.232 2.229c14.794-13.203 31.51 7.051 14.794 17.675z" /></svg>
            </button>
          </>
        }
         {/* Info Button (with margin to space it out) */}
         <button
            onClick={() => handleClickAction('info')}
            className="p-3 rounded-full text-white hover:bg-slate-500/75"
            aria-label="Open Info Tab"
          >
            <InfoIcon />
          </button>

        <button
          className="p-3 rounded-full text-white hover:bg-slate-500/75" onClick={()=>handleClickAction('comment')}
          aria-label={showComments ? "Hide comments" : "Show comments"}
        >
          <CommentIcon />
        </button>
      </div>




      {/*------- Comments Sidebar------ */}
       {showComments && (
        <div className="comment-section w-96 bg-white p-4 shadow-lg">
          <CommentSection
            newTabValue={newTabValue}
            changeTab={changeTab}
            handleCommentClick={handleCommentClick}
            selectedTab={selectedTab}
            showComments={showComments}
            setShowComments={setShowComments}
            selectedCommentIndex={selectedCommentIndex}
            content_id={mediaForReview[currentImageIndex]?.id}
            commission_school_xref_id={mediaForReview[currentImageIndex]?.commision_school_xref_id}
            sender_id={userId}
            creatorData={contentDetail}
            currentSquare={currentSquareState}
            setCurrentSquare={setCurrentSquareState}
            squaresHistory={squaresHistory}
            setSquaresHistory={setSquaresHistory} />
        </div>
      )}
    </div>
  )
}

