import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectContent } from '../../../services/schoolServices';
// Thunk for Revision 1
export const fetchRevision1 = createAsyncThunk(
  'commissionContent/fetchRevision1',
  async ({ commissionXrefId, userType }) => {
    return await getProjectContent(commissionXrefId, userType, 'revision_1');
  }
);

// Thunk for Revision 2
export const fetchRevision2 = createAsyncThunk(
  'commissionContent/fetchRevision2',
  async ({ commissionXrefId, userType }) => {
    return await getProjectContent(commissionXrefId, userType, 'revision_2');
  }
);

// Thunk for Final Work
export const fetchFinalWork = createAsyncThunk(
  'commissionContent/fetchFinalWork',
  async ({ commissionXrefId, userType }) => {
    return await getProjectContent(commissionXrefId, userType, 'final_work');
  }
);
