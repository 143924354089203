import React from 'react';

function CommentIcon({commentsCount='',fill="white",stroke="white"}) {
    return (
        <div className="relative">
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.8042 6.61764C19.8042 4.92885 19.8042 4.08445 19.4756 3.43942C19.1865 2.87203 18.7252 2.41073 18.1578 2.12163C17.5128 1.79297 16.6684 1.79297 14.9796 1.79297H6.53639C4.8476 1.79297 4.00321 1.79297 3.35817 2.12163C2.79079 2.41073 2.32948 2.87203 2.04039 3.43942C1.71173 4.08445 1.71173 4.92884 1.71173 6.61764V12.0454C1.71173 13.7342 1.71173 14.5786 2.04039 15.2236C2.32948 15.791 2.79079 16.2523 3.35817 16.5414C4.00321 16.8701 4.8476 16.8701 6.53639 16.8701H13.0861C13.7134 16.8701 14.027 16.8701 14.327 16.9316C14.5932 16.9862 14.8507 17.0766 15.0927 17.2002C15.3654 17.3395 15.6103 17.5354 16.1001 17.9273L18.4978 19.8455C18.916 20.18 19.1251 20.3473 19.3011 20.3475C19.4542 20.3477 19.599 20.2781 19.6944 20.1585C19.8042 20.0209 19.8042 19.7531 19.8042 19.2176V6.61764Z" fill={fill} stroke={stroke} stroke-width="2.19303" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[rgba(52, 64, 84, 1)] text-[12px] pb-1 font-semibold">{commentsCount}</p>
        </div>

    );
}

export default CommentIcon;
