import React from 'react'

export const BackIcon = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5859 12.0195H4.58594M4.58594 12.0195L10.5859 18.0195M4.58594 12.0195L10.5859 6.01953" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const DownloadIcon = () => (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.22266 10.5202L10.8893 14.1868M10.8893 14.1868L14.556 10.5202M10.8893 14.1868V6.85352M20.056 10.5202C20.056 15.5828 15.9519 19.6868 10.8893 19.6868C5.82671 19.6868 1.72266 15.5828 1.72266 10.5202C1.72266 5.45757 5.82671 1.35352 10.8893 1.35352C15.9519 1.35352 20.056 5.45757 20.056 10.5202Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const CommentIcon = () => (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.7969 5.56797C17.7969 4.02782 17.7969 3.25775 17.4971 2.66949C17.2335 2.15205 16.8128 1.73135 16.2953 1.4677C15.7071 1.16797 14.937 1.16797 13.3969 1.16797H5.69687C4.15673 1.16797 3.38666 1.16797 2.7984 1.4677C2.28096 1.73135 1.86026 2.15205 1.59661 2.66949C1.29687 3.25775 1.29687 4.02782 1.29687 5.56797V10.518C1.29687 12.0581 1.29687 12.8282 1.59661 13.4164C1.86026 13.9339 2.28096 14.3546 2.7984 14.6182C3.38666 14.918 4.15673 14.918 5.69687 14.918H11.6701C12.2422 14.918 12.5282 14.918 12.8018 14.9741C13.0445 15.0239 13.2794 15.1063 13.5 15.2191C13.7487 15.3461 13.9721 15.5248 14.4188 15.8821L16.6054 17.6315C16.9868 17.9366 17.1776 18.0892 17.338 18.0893C17.4776 18.0895 17.6097 18.026 17.6967 17.9169C17.7969 17.7915 17.7969 17.5473 17.7969 17.0588V5.56797Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const LeftSwipe = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
  </svg>
)

export const RightSwipe = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
    </svg>
)
export const SendIcon = () => (
    <svg width="22" height="21" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.2106 9.01909H3.16893M3.09133 9.28631L0.950985 15.6798C0.782837 16.1821 0.698763 16.4332 0.759099 16.5878C0.811495 16.7222 0.924028 16.824 1.06289 16.8627C1.22279 16.9074 1.4643 16.7987 1.94732 16.5813L17.2662 9.68784C17.7376 9.47568 17.9734 9.3696 18.0462 9.22224C18.1095 9.09421 18.1095 8.94399 18.0462 8.81596C17.9734 8.66859 17.7376 8.56251 17.2662 8.35036L1.94198 1.45451C1.46041 1.2378 1.21963 1.12945 1.05989 1.1739C0.921161 1.2125 0.80864 1.31405 0.756062 1.44811C0.695522 1.60247 0.778697 1.85307 0.945048 2.35426L3.09192 8.8225C3.1205 8.90858 3.13478 8.95162 3.14042 8.99564C3.14542 9.0347 3.14537 9.07424 3.14027 9.11329C3.13452 9.15729 3.12012 9.2003 3.09133 9.28631Z" stroke="black" stroke-width="0.931253" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const InfoIcon = () => (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="2" fill="none" />
      <path
        d="M12 8C12.5523 8 13 8.44772 13 9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9C11 8.44772 11.4477 8 12 8Z"
        fill="white"
      />
      <path
        d="M12 12C12.5523 12 13 12.4477 13 13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13C11 12.4477 11.4477 12 12 12Z"
        fill="white"
      />
    </svg>
  );
  
