import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCommissionImages } from "../../services/brandServices";
import useDebounce from "../../utils/debounce";
import VideoPlayer from "../video/player/VideoPlayer";
import CustomImage from "../CustomImage/CustomImage";
import LoadingWrapper from "../loadingWrapper/LoadingWrapper";
import MediaActions from "./media-actions/MediaActions";
import { useDispatch, useSelector } from "react-redux";
import BorderLessButton from "../buttons/BorderLessButton";
import PrimaryButton from "../buttons/PrimaryButton";
import useModal from "../popups/modal/useModal";
import MessageInfoCard from "../MessageInfoCard";
import OutllinedPrimaryBtn from "../buttons/OutllinedPrimaryBtn";
import PrimaryFilledBtn from "../form-elements/PrimaryFilledBtn";
import DeleteIcon from "../../icons/Delete";
import NextRevisionIcon from "../../icons/NextRevisionIcon";
import { getProjectContent, moveToNextVersion } from "../../services/schoolServices";
import DeletePopup from "../popups/DeletePopup";
import { deleteProjectContentImage } from "../../services/schoolServices";
import useToast from "../../hooks/useToast";

import { fetchFinalWork, fetchRevision1, fetchRevision2 } from "../../redux/reducerFiles/commission-content/thunk.actions";
import { revalidate, setCommissionDetails } from "../../redux/reducerFiles/commission-content/commissionContentReducer";
import {  getCommissionDetails, submitProject } from '../../services/schoolServices';
import { SidebarContext } from "../../App";
import Disclaimer from "../popups/Disclaimer";



import CrossInCircleIcon from "./icons/CrossInCircleIcon";

function CommissionContent({ pageType, isAccepted, activeTab, setActiveTab }) {
  const { revision1, revision2, finalWork, commissionDetails } = useSelector((state) => state.commissionContent);
  const getRevisionData = () => {
    switch (pageType) {
      case 'revision_1':
        return revision1;
      case 'revision_2':
        return revision2;
      case 'final_work':
        return finalWork;
      default:
        return {
          data: [],
          loading: false,
          error: null,
          dataByCreatorName: {}
        };
    }
  };
  const [disable, setDisable] = useState(false);
  const { open, setOpen, toggle } = useModal();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const location = useLocation();
  const shouldUpdateContent = location.state?.shouldUpdateContent
  const commissionToDisplay = commissionDetails;
  const commissionId =
  commissionToDisplay?.id || commissionToDisplay?.commissionId;
  const mediaForReview = getRevisionData()?.data
  const { userType } = useSelector((state) => state.user);
  const [openDeleteModel, setOpenDeleteModel] = useState(false)
  const imageData = getRevisionData()?.dataByCreatorName
  const isLoading = getRevisionData()?.loading
  const [moveToNextVersionLoader,setMoveToNextVersionLoader ] = useState(false)
  const [isNew, setIsNew] = useState(false);
  const { globalLoader, setGlobalLoader } = useContext(SidebarContext)
  const [isCommissionLoading, setIsCommissionLoading] = useState(false)
  const [isAccept, setIsAccept] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [commissionData, setCommissionData] = useState({})
  const commissionXrefId = commissionToDisplay?.cs_id || commissionToDisplay?.commissionSchoolXrefId 
  
  const dispatch = useDispatch()
  const showToast = useToast()
  const handleDeleteModel = (state = false, type = 'collection') => {
    setOpenDeleteModel(state)
  }
  const navigate = useNavigate();
  const isSubmited =
    commissionToDisplay?.status === "in_progress" ? false : true;


  useEffect(() => {
    setDisable(isNew || !isAccepted || isSubmited);
  }, [isNew, isAccepted, isSubmited]);


  useEffect(() => {
    const fetchCommissionContents = async () => {
      try {
          if (pageType === 'revision_1') {
            dispatch(fetchRevision1({ commissionXrefId, userType:userType !== 'creator'?'school':'creator' }));
          }
          if (pageType === 'revision_2' ) {
            dispatch(fetchRevision2({ commissionXrefId, userType:userType !== 'creator'?'school':'creator' }));
          }
          if (pageType === 'final_work' ) {
            dispatch(fetchFinalWork({ commissionXrefId, userType:userType !== 'creator'?'school':'creator' }));
          }
      } catch (error) {
        console.log('Error fetching contents:', error);
      }
    };
  
    fetchCommissionContents();
  }, [pageType]);
  

  const handleClickToEdit = (e, contentData) => {
    if (userType == "brand") {
      if(pageType==='final_work') {
        let currentMediaIndex = -1;
        for(const key in mediaForReview) {
          if(mediaForReview[key]?.id === contentData.id) {
            currentMediaIndex = key
            break;
          }
        }
        
        // return
        navigate("/image-details", { state: { image:contentData, commissionname:commissionDetails?.name,commissionid:commissionDetails.id, dataType:pageType, mediaList:mediaForReview, currentMediaIndex } });
      } else {
        handleCommentClick(e, contentData)
      }
    } else {
      navigate(`/${userType}/project/upload?type=edit`, {
        state: { contentData: { ...contentData, type: "edit", isSubmited } },
      });
    }
  };

  function handleCommentClick(e, data) {
    e.stopPropagation();
    const mediaForReviewCurrentIndex = mediaForReview.indexOf(data);
    if (userType == "brand") {
      navigate(`/commissions/details/image-review`, {
        state: { mediaForReview, mediaForReviewCurrentIndex, commissionToDisplay, pageType },
      });
      return;
    }

    navigate(`/${userType}/commissions/details/image-review`, {
      state: { mediaForReview, mediaForReviewCurrentIndex, commissionToDisplay, contentVersionType:pageType, pageType },
    });
  }

  function handleCheckboxChange(e) {
    if (e.target.checked) {
      const selectedIds = mediaForReview.map(media => media.id)
      setSelectedImages(selectedIds)
    } else {
      setSelectedImages([])
    }
  }
  const allSelected = mediaForReview.every(media => selectedImages.includes(media.id));

  const handleDeleteProjectContentImage = async () => {
    try {
      setDeleteLoading(true);
      const resp = await deleteProjectContentImage(selectedImages);
      setRefresh(!refresh);
      if (resp?.status === 200) {
        setSelectedImages([]);
      }
      showToast("Images Deleted successfully", 'success');
      setOpenDeleteModel(false);
      dispatch(revalidate())
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleMoveToNextVersion = async () => {
    try {
      setMoveToNextVersionLoader(true)
      const selectedIdsSet = new Set(selectedImages);
      let payload = { contentIds: selectedImages }

      let tabToSwitch = 0

      if (pageType === 'revision_1') {
        payload = {
          ...payload, isRevision2: 1,
        }
        tabToSwitch = 1
      }

      if (pageType === 'revision_2') {
        payload = {
          ...payload, isFinalWork: 1,
        }
        tabToSwitch = 2
      }
      const res = await moveToNextVersion(payload)
      setMoveToNextVersionLoader(false)
      showToast('Contents moved to next revision successfully.','success')
      setActiveTab && setActiveTab(tabToSwitch)
      setSelectedImages([])
      dispatch(revalidate())
    } catch (error) {
      setMoveToNextVersionLoader(false)
      console.error(error);
    } finally {
      setMoveToNextVersionLoader(false)
    }
  }

  const handleSubmitWrapper = async () => {
    if(finalWork?.data?.length){
      setOpen(true)
    } else {
      alert("Final work is empty. Please add or move content before submitting.")
    }
  }

   const handleSubmit = async () => {
      try {
        if (isSubmited && !isAccept) {
          showToast("Already submitted or rejected ", "error")
          setOpen(false)
  
        } else {
          setGlobalLoader(true)
          const payload = {
            commission_xref_id: commissionXrefId,
            status: "submitted"
          }
          const resp = await submitProject(payload)
          setOpen(false)
          showToast("Submitted successfully", "success")
          await fetchCommissionDetails(commissionXrefId)
          setGlobalLoader(false)
  
        }
      } catch (error) {
        setGlobalLoader(false)
      }
    }
     const fetchCommissionDetails = async commissionXrefId => {
        try {
          setIsCommissionLoading(true)
          const res = await getCommissionDetails({ commissionXrefId })
          dispatch(setCommissionDetails(res.data))
          setIsAccept(res?.data?.is_accepted || false)
          setIsSubmitted(res?.data?.status == "in_progress" ? false : true)
          setIsNew(res?.data?.isNew || false)
          setCommissionData(res.data)
        } catch (err) {
          console.log(err)
        } finally {
          setIsCommissionLoading(false)
        }
      }

  return (
    <div className="w-full flex flex-col gap-4">
      <DeletePopup
        isOpen={openDeleteModel}
        title='Delete image?'
        subText='Your images will be permanently removed from our database and/or store.'
        onClose={() => handleDeleteModel(false)}
        onDelete={handleDeleteProjectContentImage}
        loading={deleteLoading}
      />
      <div className="flex flex-row justify-between items-center">
        <p className="text-[16px] font-normal text-[#667085]">
          {pageType === "revision_2"
            ? "Second revision submitted by creators"
            : pageType === "final_work"
              ? "Final work submitted by creators"
              : "First revision submitted by creators"}
        </p>
        {
          userType !== 'brand' && (
            <div className="flex flex-row gap-4">
              <div className="w-60">
                <OutllinedPrimaryBtn
                  label="Add Content"
                  className="bg-[rgba(152,113,244,0.07)]"
                  onClick={() =>
                    navigate(`/${userType}/project/upload?type=add`, {
                      state: { commissionXrefId: commissionXrefId, type: "add",contentVersionType:pageType },
                    })
                  }
                  disabled={disable ? true : false}
                />
              </div>

              {pageType === "final_work" && userType === 'school' && (
                <div className="w-56">
                  <PrimaryFilledBtn
                    onClick={handleSubmitWrapper}
                    label="Submit to swiirl"
                    disabled={disable ? true : false}
                  />

                </div>
              )}
            </div>
          )
        }
      </div>
      {userType === "school" && pageType === "final_work" && (
      <MessageInfoCard
        heading={"Submitting your final version of project to Swiirl"}
         subHeading={
           "Once all contributors have added their final art, please submit it to Swiirl. You will no longer be able to add art to a submitted project, and will be considered fulfilled."
         }
         className={"mt-12"}
        />
       )}

      <LoadingWrapper loading={isLoading}>
        <div className="flex flex-col">
          {
            userType !== 'brand' && !!Object?.keys(imageData)?.length &&
            (
              <div className="flex w-full flex-row justify-between">

                <div className="flex items-center gap-2">
                  <input disabled={commissionDetails.isSubmitted} onChange={handleCheckboxChange} id={`media-select-${pageType}`} name={`media-select-${pageType}`} type="checkbox" checked={allSelected} className={`accent-violet size-5  ${commissionDetails.isSubmitted && 'cursor-not-allowed'} `} />
                  <label htmlFor={`media-select-${pageType}`} className={`text-base font-semibold text-violet my-auto accent-violet size-5  ${commissionDetails.isSubmitted ? 'cursor-not-allowed' : 'cursor-pointer'} `}>
                    Select
                  </label>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-row items-center">
                    <DeleteIcon className={'size-5'} />
                    <BorderLessButton
                      onClick={() => setOpenDeleteModel(true)}
                      disabled={commissionDetails.isSubmitted || deleteLoading || selectedImages.length === 0}
                      text="Delete"
                      className="font-semibold text-violet px-1  "
                    />
                  </div>

                  {
                    pageType !== 'final_work' &&
                    <div className="flex flex-row items-center">
                      <NextRevisionIcon />
                      <BorderLessButton
                        disabled={(commissionDetails.isSubmitted) || ((selectedImages?.length) ? !moveToNextVersionLoader ? false : true  : true)}
                        text={`${moveToNextVersionLoader ? "Moving to next version ...":"Move to next revision"}`}
                        className="font-semibold text-violet px-1.5"
                        onClick={handleMoveToNextVersion}
                      />
                    </div>
                  }

                </div>
              </div>
            )
          }
          {Object?.keys(imageData)?.map((creator_name) => (
            <div key={creator_name} className="mt-8">
              <div className="flex flex-col">
                <p className="text-xl font-semibold">{creator_name}</p>
                <div className="flex flex-wrap gap-2">
                  {imageData?.[creator_name]?.map((data) => (
                    <div
                      key={data.id}
                      className={`relative w-[300px] aspect-square ${
                        selectedImages.includes(data.id) ? "imageSelected" : ""
                      }`}
                    >
                      {data.media_type === "video" ? (
                        <div
                          onClick={(e) => handleCommentClick(e, data)}
                          className="h-full w-full"
                        >
                          <VideoPlayer
                            key={data?.alt || data.id}
                            src={data.thumbnailUrl || ""}
                            alt={data?.alt || "Image"}
                            imageClass="card-image"
                            shouldPlay={false}
                            videoUrl={data?.url}
                          >
                            <MediaActions
                              showSelect={userType !== "brand"}
                              data={data}
                              setSelectedImages={setSelectedImages}
                              selectedImages={selectedImages}
                              handleClick={(e) => handleClickToEdit(e, data)}
                            />
                          </VideoPlayer>
                        </div>
                      ) : (
                        <div className="relative">
                          <CustomImage
                            key={data.s3_key}
                            src={data.thumbnailUrl || data.url}
                            alt={data.alt || data.id}
                            imageClass="card-image"
                          >
                            <MediaActions
                              showSelect={userType !== "brand"}
                              data={data}
                              setSelectedImages={setSelectedImages}
                              selectedImages={selectedImages}
                              handleClick={(e) => handleClickToEdit(e, data)}
                              handleCommentClick={(e) =>
                                handleCommentClick(e, data)
                              }
                            />
                          </CustomImage>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </LoadingWrapper>
      {
        open &&
        <Disclaimer
          open={open}
          setOpen={setOpen}
          toggle={toggle}
          onClick={handleSubmit}
          disable={globalLoader}
          primaryButtonLabel="Submit"
        />
      }
    </div>
  );
}

export default CommissionContent;
