import Axios from "axios";
import { clearLocalStorage, getLocalStorage } from "./session";

// Function to get the full API URL
const getUrl = ({endpoint,baseUrl=null}) => {
  if(baseUrl && process.env.REACT_APP_LOCAL_API==='true' ){ //&& process.env.REACT_APP_LOCAL_API==='true'
    return baseUrl + endpoint
  }
  return process.env.REACT_APP_API_URL + endpoint; 
  // return 'http://localhost:5000' + endpoint; 

};

/**
 * @description Helper function to retrieve the token from local storage
 */
const getToken = async () => {
  let token = await getLocalStorage("token");
  return token ? token.replace(/^"|"$/g, "") : null;
};

// HTTP methods without token
export const Post = async ({endpoint,baseUrl=null, data}) => {
  return Axios.post(getUrl({endpoint,baseUrl}), data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const Get = async ({endpoint,baseUrl}) => {
  return Axios.get(getUrl({endpoint,baseUrl}));
};

export const Put = async ({endpoint, data}) => {
  return Axios.put(getUrl({endpoint}), data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const Patch = async ({endpoint, data}) => {
  return Axios.patch(getUrl({endpoint}), data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const Delete = async ({endpoint, data}) => {
  return Axios.delete(getUrl({endpoint}), {
    data,
    headers: { "Content-Type": "application/json" },
  });
};

// HTTP methods with token
export const PostWithToken = async ({baseUrl=null, endpoint, data}) => {
  const token = await getToken();
  return Axios.post(getUrl({baseUrl,endpoint}), data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GetWithToken = async ({endpoint,baseUrl}) => {
  const token = await getToken();
  return Axios.get(getUrl({baseUrl,endpoint}), {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const PutWithToken = async ({endpoint, data,baseUrl=null}) => {
  const token = await getToken();
  return Axios.put(getUrl({endpoint,baseUrl}), data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const PatchWithToken = async ({baseUrl=null,endpoint, data}) => {
  const token = await getToken();
  return Axios.patch(getUrl({baseUrl,endpoint}), data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const DeleteWithToken = async ({baseUrl=null,endpoint, data}) => {
  const token = await getToken();
  return Axios.delete(getUrl({baseUrl,endpoint}), {
    data,
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Function for uploading images without auth token
export const UploadImage = async ({endpoint, formData}) => {
  return Axios.post(getUrl(endpoint), formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// Axios interceptors for request and response
Axios.interceptors.request.use(
  (request) => {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  },
  { synchronous: true }
);

Axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  function (error) {
    if (!error || !error.response) {
      return Promise.reject(error);
    }

    // -------------------temproray comment for auth----------------------
    if (error?.response?.status === 401) {
      // Handle 401 Unauthorized response
      alert("Unauthorized User ,login again.");
      clearLocalStorage()
      // return (window.location.href = "/");
    }
    else if (error?.response?.status === 403) {
      // Handle 403 Forbidden response
      alert("Session Expired ,login again.");
      clearLocalStorage()
      return (window.location.href = "/");
    }
    return Promise.reject(error);
  }
);

