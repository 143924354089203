import PrivateRoute from "../../HOC/PrivateRouteLayout";
import CommGalleries from "../../pages/brand/commission/CommGalleries";
import CommissionGetPage from "../../pages/brand/commission/GetCommission";
import Home from "../../pages/brand/homePage/Home";
import ImageDetail from "../../pages/brand/imageDetails/ImageDetails";
import MyContent from "../../pages/brand/MyContent";
import RootLayout from "../../pages/Root";
import Shop from "../../pages/brand/shop/Shop";
import CreateCommision from "../../pages/brand/commission/CreateCommission";
import InviteTeammates from "../../pages/invite-teammates/InviteTeammates";
import SchoolSupport from "../../pages/support/SchoolSupport";
import CommissionDetails from "../../components/commissions/commission-details/CommissionDetails";
import ImageReviewAndComment from '../../components/commission-content/ImageReviewAndComment'

const brandRoutes = [

    {
        path: "/home",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <Home />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/galleries",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <MyContent />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/commissions",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <CommissionGetPage />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/commissions/details",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <CommissionDetails />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/commissions/details/image-review",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <ImageReviewAndComment />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/image-details",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <ImageDetail />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/comm-galleries",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <CommGalleries />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/commissions/create",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <CreateCommision />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/shop",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <Shop />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/my-content",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <MyContent />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/invite-teammates",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <InviteTeammates />
                </RootLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/brand/support",
        element: (
            <PrivateRoute>
                <RootLayout>
                    <SchoolSupport />
                </RootLayout>
            </PrivateRoute>
        ),
    },
];



export default brandRoutes;