import React, { useState } from "react";
import "./CreateCommissionForm.css"; // Import CSS file
import UploadIcon from "../icons/upload";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getUploadUrl,
  createCommission,
  uploadFileTos3,
} from "../services/brandServices";
import { ulid } from "ulid";
import DatePicker from "./dateRangePicker.js/dateRangePicker";
import DropDown from "./dropDown/DropDown";
import CommonFile from "../icons/CommonFile";
import DeleteIcon from "../icons/Delete";
import { constants } from "../constants/constants";

import { useNavigate } from "react-router-dom";
import { CustomSelect } from "./FormComponets/select/CustomSelect";
import CustomDateRangePicker from "./dateRangePicker.js/dateRangePicker/CustomDateRangePicker";
import useToast from "../hooks/useToast";
import ManageThumnail from '../pages/Creator/my-work/upload-creator-content/ManageThumnail'
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import GlobalLoader from "./loaders/GlobalLoader";

const { commissionStatus } = constants



const CreateCommissionForm = () => {
  const navigate = useNavigate();
  const showToast = useToast()
  const [thumbnails, setThumbnails] = useState({})
  const [formCommData, setFormData] = useState({
    name: "",
    target_location: "",
    timeline: "",
    media_type: "photography", //default media type
    content_usage: "",
    campaign_goal: "",
    creative_design: "",
    themes_prompts: "",
    links: "",
    // start_date:"",
    // end_date:""
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [firstReviewDate, setfirstReviewDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [secondReviewDate, setsecondReviewDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [openCalendar, setOpenCalender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const { username, userId, brandId } = useSelector((state) => state.user);
  const [fieldErrors, setFieldErrors] = useState({});

  const getDateFormated = (date = new Date()) => {
    //date object
    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${monthList[date.getMonth()]
      } ${date.getDate()},${date.getFullYear()}`;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formCommData, [name]: value.replace(/^\s+/, '')});
  };

  const handleMediaChange = (e) => {
  };

  // const handleDateChange = (event) => {
  //   const { name, value } = event.target;
  //   const date = new Date(value).toISOString();
  //   setFormData({ ...formCommData, [name]: date });
  // };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    event.target.value = '';
    const MEDIA_TYPE = formCommData.media_type
    let allowedExtensions
    // if(MEDIA_TYPE === 'video') {
    //   allowedExtensions = new Set(['mp4'])
    // } else  {
    //   allowedExtensions = new Set(['png', 'svg', 'gif', 'jpeg', 'jpg'])
    // }
    let files = [...newFiles]
    // const files = newFiles.filter((file) => {
    //   let extension = file.name?.split('.').pop().toLowerCase();
    //   return allowedExtensions.has(extension);
    // })

    // if(newFiles.length !== files.length ) {
    //   alert(MEDIA_TYPE === 'video' ? "Only mp4 files are allowed": "Only png, svg, gif and jpeg are allowed");
    //   return
    // }

    const totalCount = selectedFiles.length + files.length;
    if (totalCount > 5) {
      alert("You can only upload a maximum of 5 files");
    } else {
      setSelectedFiles((prev) => [...prev, ...files]);
    }
  };
  const removeFile = (index) => {
    setSelectedFiles((files) => files.filter((file, i) => i !== index));
  };

  const getSignedUrlAndUploadImage = async (files, commissionId) => {
    let response = [];
    if (!files.length) return response;
    let keyNames = files.map((file) => {
      const key = `commission-assets/${brandId}/${commissionId}/${ulid()}`;
      response.push({ name: file.name, key });
      return key;
    });

    const { urls } = await getUploadUrl(keyNames);
    await Promise.all(
      urls.map((url, index) => uploadFileTos3(url, files[index]))
    );
    return response;
  };

  const getSignedUrlAndUploadMedia = async (mediaItems, commissionId) => {
    let response = [];

    if (!mediaItems.length) return response;

    // Create an array of keys and gather responses
    let keyNames = [];

    mediaItems.forEach(({ videoFile, thumbnailFile }) => {
      const _ulid = ulid();
      const videoKey = `commission-assets/${brandId}/${commissionId}/video/${_ulid}`;
      const thumbnailKey = `commission-assets/${brandId}/${commissionId}/video/${_ulid}_thumbnail`;

      response.push({
        videoName: videoFile.name,
        videoKey,
        thumbnailName: thumbnailFile.name,
        thumbnailKey
      });

      keyNames.push(videoKey, thumbnailKey); // Push both keys
    });

    // Get upload URLs for both videos and thumbnails
    const { urls } = await getUploadUrl(keyNames);

    // Ensure we received the expected number of URLs
    if (urls.length !== keyNames.length) {
      throw new Error('The number of upload URLs does not match the number of keys.');
    }

    // Create upload promises for both video files and their corresponding thumbnails
    const uploadPromises = mediaItems.map((item, index) => {
      const videoUrl = urls[index * 2];
      const thumbnailUrl = urls[index * 2 + 1];

      const promises = [];

      // Check if videoUrl is available
      if (videoUrl) {
        promises.push(uploadFileTos3(videoUrl, item.videoFile));
      }

      // Check if thumbnailUrl is available
      if (thumbnailUrl) {
        promises.push(uploadFileTos3(thumbnailUrl, item.thumbnailFile));
      }

      return Promise.all(promises);
    });

    // Await all upload promises
    await Promise.all(uploadPromises);

    return response;
  };


  const validate = () => {
    const errors={};
    const errorMessages = [];

    if (!formCommData.name) {
      errors.name = true;
      errorMessages.push("Name is required");
    }
    if (!formCommData.target_location) {
      errors.target_location = true;
      errorMessages.push("Target location is required");
    }
    // if (!formCommData.media_type) {
    //   alert("Media type is required");
    //   return false;
    // }
    if(!dateRange.startDate || !dateRange.endDate){
      errors.dateRange = true;
      errorMessages.push("Please select date range");
    }
    // if (!firstReviewDate.startDate) {
    //   errors.firstReviewDate = true;
    //   errorMessages.push("First review date is required");
    // }
    // if (!secondReviewDate.startDate) {
    //   errors.secondReviewDate = true;
    //   errorMessages.push("Second review date is required");
    // }
    // if (firstReviewDate.startDate >= secondReviewDate.startDate) {
    //   errors.firstReviewDate = true;
    //  errors.secondReviewDate = true;
    //  errorMessages.push("The First Review Date must be earlier than the Second Review Date");
    // }
    if (!formCommData.content_usage) {
      errors.content_usage = true;
      errorMessages.push("Content usage is required");
    }
    if (!formCommData.campaign_goal) {
      errors.campaign_goal = true;
      errorMessages.push("Campaign goal is required");
    }
    if (!formCommData.creative_design) {
      errors.creative_design = true;
      errorMessages.push("Creative direction is required");
    }
    if (!formCommData.themes_prompts) {
      errors.themes_prompts = true;
      errorMessages.push("Themes and prompts is required");
    }

    if (selectedFiles?.length === 0) {
      errors.selectedFiles = true;
      errorMessages.push("You have to upload minimum 1 file");
    }

    if (selectedFiles?.length > 5) {
      errors.selectedFiles = true;
      errorMessages.push("You can only upload a maximum of 5 files");
    }

    setFieldErrors(errors);

    if(errorMessages.length > 0) {
      alert(errorMessages[0]);
      return false;
    }

    return true;
  };

  const handleCreateCommission = async (e) => {
    try {
      e.preventDefault();

      if (!validate()) return;
      setLoading(true);

      const images = []
      const videos = []
      selectedFiles.forEach((item, index) => {
        if (item.type.includes('video')) {
          videos.push({
            videoFile: item,
            thumbnailFile: thumbnails[item.name]
          })
        } else {
          images.push(item)
        }
      })

      if (videos.length !== Object.keys(thumbnails).length) {
        return showToast('Thumbnails are required for each video')
      }

      let commissionId = ulid();
      const files = await getSignedUrlAndUploadImage(
        images,
        commissionId
      );
      const videoFiles = await getSignedUrlAndUploadMedia(
        videos,
        commissionId
      )
      const requestBody = {
        ...formCommData,
        id: commissionId,
        files,
        videoFiles,
        firstReviewDate: firstReviewDate.startDate ,
        secondReviewDate: secondReviewDate.startDate ,
        status: commissionStatus?.progress,
        start_date: dateRange?.startDate?.toISOString(),
        end_date: dateRange?.endDate?.toISOString(),
        brand_id: brandId,
      };

      await createCommission(requestBody);

      navigate("/commissions", { state: { messageType: "success", message: "Success! We have received your commission details and our team will get back to you soon.", hideBreadCrumb: true } });

      setFormData({
        name: "",
        target_location: "",
        timeline: "",
        media_type: "photography", //default media type
        content_usage: "",
        campaign_goal: "",
        creative_design: "",
        themes_prompts: "",
        links: "",
      });

      setSelectedFiles([]);

    } catch (error) {
      showToast(error?.message || "Something went wrong", 'error')
      setErrorMessage("something went wrong")
      console.error("Error creating commission", error);
    } finally {
      setLoading(false)
    }
  };

  function formatFileSize(size) {
    const units = ["bytes", "KB", "MB", "GB"];
    if (size < 1024) return `${size.toFixed(2)}${units[0]}`;
    let i = 0;
    let formattedSize = size;

    while (formattedSize >= 1024 && i < units.length - 1) {
      formattedSize /= 1024;
      i++;
    }

    return `${formattedSize.toFixed(2)}${units[i]}`;
  }

  const getIcon = (fileName) => {
    let split = fileName.split(".");
    let extension = split[split.length - 1].toLowerCase();;
    return <CommonFile extName={extension} />;
  };


  return (
    <div className="flex mb-5 ">
      <GlobalLoader isLoading={loading} />
      <div className="form-container">
      <Breadcrumb
          paths={[
            { name: "Home", link: "/galleries" },
            { name: "Commissions", link: "/commissions" },
            { name: "Create commission", link: "/commissions/new" },
          ]}
          classs="mb-5"
        />
        <form onSubmit={handleCreateCommission}>
          <div className="form-row">
            <div className="form-group">
              <label className="form-label">Name your commission
              <span style={{ color: fieldErrors.name ? "red" : "black" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control-input "
                name="name"
                value={formCommData.name}
                onChange={handleChange}
                // placeholder="Let’s give the school details for their new assignment"
                placeholder="Lets provide creators the name of your commission or creative brief"
              />
            </div>
          </div>
          <div className="form-row mt-5">
            <div className="form-group">
              <label className="form-label">Target Locations
              <span style={{ color: fieldErrors.target_location ? "red" : "black" }}>*</span>
              </label>
              <textarea
                className="form-control-textarea"
                name="target_location"
                value={formCommData.target_location}
                onChange={handleChange}
                placeholder="Preferred impact regions"
              />
            </div>
            {/* <DatePicker
              open={openCalendar}
              setOPen={setOpenCalender}
              setDateRange={setDateRange}
              selectionRange={dateRange}
            /> */}



            <div className="form-group">
            <label className="form-label mt-1">Media Type</label>
              {/* <DropDown
                className="w-full"
                name="media_type"
                value={formCommData.media_type}
                onChange={handleChange}
                options={[
                  { label: "Photography", value: "photography" },
                  { label: "Art", value: "art" },
                  { label: "Audio", value: "Audio" },
                  { label: "Video", value: "Video" },
                ]}
              /> */}

              <CustomSelect
                className="w-full "
                name="media_type"
                value={formCommData.media_type}
                onChange={handleChange}
                options={[
                  { label: "Photography", value: "photography" },
                  { label: "Art", value: "art" },
                  { label: "Audio", value: "audio" },
                  { label: "Video", value: "video" },
                ]}
              />

              <label className="form-label text-md-end mt-3">Timeline
              <span style={{ color: fieldErrors.dateRange ? "red" : "black" }}>*</span>
              </label>
              {/* <button
                onClick={(e) => {
                  e.preventDefault()
                  setOpenCalender(!openCalendar)
                }}

                className="rounded-lg box-shadow-default w-full cursor-pointer h-[35px] border border-silver flex items-center px-2 mb-2 text-primary-black"
              >
                { `${getDateFormated(dateRange.startDate)} - ${getDateFormated(
                  dateRange.endDate
                )}` }
              </button> */}
              <CustomDateRangePicker
                setDateRange={setDateRange}
                setFrom={setFrom}
                setTo={setTo}
                // selectionRange={dateRange}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                buttonClassName='mb-2'
              />
            </div>
          </div>

           <div className="form-row mt-5">
            <div className="form-group">
              <label className="form-label text-md-end">
              First review date<span style={{ color: fieldErrors.firstReviewDate ? "red" : "black" }}></span>
              </label>

              <CustomDateRangePicker
              setDateRange={setfirstReviewDate}
                //selectionRange={dateRange}
                dateRange={dateRange}
                startDate={firstReviewDate.startDate}
                endDate={firstReviewDate.endDate}
                mode="single" 
                buttonClassName='mb-2'
                to= {to}
                from={from}
              />
            </div>
            <div className="form-group">
              <label className="form-label">
              Second review date<span style={{ color: fieldErrors.secondReviewDate ? "red" : "black" }}></span>
              </label>
              <CustomDateRangePicker
                setDateRange={setsecondReviewDate}
                dateRange={dateRange}
                // selectionRange={dateRange}
                 startDate={secondReviewDate.startDate}
                endDate={secondReviewDate.endDate}
                mode="single"  
                buttonClassName='mb-2'
                to= {to}
                from={from}
              />

            </div>
          </div> 
          <div className="form-row mt-5">
            <div className="form-group">
              <label className="form-label text-md-end">
                How will you use this content?<span style={{ color: fieldErrors.content_usage ? "red" : "black" }}>*</span>
              </label>
              <textarea
                className="form-control-textarea"
                name="content_usage"
                value={formCommData.content_usage}
                onChange={handleChange}
                placeholder="E.g. social media campaigns, annual sustainability report, signage for event and retail, etc."
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                What is the goal of this campaign<span style={{ color: fieldErrors.campaign_goal  ? "red" : "black" }}>*</span>
              </label>
              <textarea
                className="form-control-textarea"
                name="campaign_goal"
                value={formCommData.campaign_goal}
                onChange={handleChange}
                placeholder="E.g. raise awareness of the importance of sustainable living practices. What does sustainability mean for different communities?"
              />
            </div>
          </div>
          <div className="form-row mt-5">
            <div className="form-group">
              <label className="form-label text-md-end">
                Creative direction<span style={{ color: fieldErrors.creative_design ? "red" : "black" }}>*</span>
              </label>
              <textarea
                className="form-control-textarea"
                name="creative_design"
                value={formCommData.creative_design}
                onChange={handleChange}
                placeholder="E.g. use of earthy tones and natural colors is encouraged. No harmful content or disturbing images."
              />
            </div>
            <div className="form-group">
              <label className="form-label">Themes and Prompts
              <span style={{ color: fieldErrors.themes_prompts  ? "red" : "black" }}>*</span>
              </label>
              <textarea
                className="form-control-textarea"
                name="themes_prompts"
                value={formCommData.themes_prompts}
                onChange={handleChange}
                placeholder="E.g. What does ”Leaving the world better than you found it” mean to you?"
              />
            </div>
          </div>
          <div className="form-row mt-5">
            <div className="form-group">
              <label className="upload-control" htmlFor="upload-input">
                <input
                  type="file"
                  multiple
                  id="upload-input"
                  // accept={formCommData?.medsia_type === 'video' ? 'video/*' : 'image/*'}
                  accept="video/mp4,image/png,image/svg+xml,image/gif,image/jpeg,image/jpg,application/pdf,*/*"
                  onChange={handleFileChange}
                />


                <div className="uploadicon">
                  <UploadIcon />
                </div>
                <div className="upload-options">
                  <p className="click">Click to upload</p>
                </div>
                <div className="upload-types">
                  Image,video,pdf etc.
                </div>
              </label>

              {selectedFiles && selectedFiles.length > 0 && (
                <ul
                  style={{ listStyleType: "none", margin: 0, padding: 0 }}
                  className="flex flex-col"
                >
                  {selectedFiles.map((file, index) => (
                    <li className="selected-files my-1" key={file?.name || ""}>
                      <div className="selected-file-grouping">
                        <div className="icons-files">{getIcon(file?.name || "")}</div>
                        <div className="file-details">
                          <div className="filename">{file?.name || ""}</div>
                          <div className="filetype">
                            ({formatFileSize(file?.size || "")})
                          </div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>

                      <ManageThumnail fileName={file.name} isVideo={file.type?.includes('video')} thumbnails={thumbnails} setThumbnails={setThumbnails} videoIndex={index} />

                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="form-row mt-5">
            <div className="form-group">
              <label className="form-label">Links</label>
              <textarea
                className="form-control-textarea"
                name="links"
                value={formCommData.links}
                onChange={handleChange}
                placeholder="Provide any relevant links"
              />
            </div>
          </div>
          <div className="form-row mt-5">
            <div className="form-group">
              <button
                className="button-primary-sw btn-center "
                type="submit"
                disabled={loading}
                style={{ width: "480px" }}
              >
                {loading ? "Submitting..." : "Submit Commission"}
              </button>
            </div>
          </div>
          {errorMessage && (
            <div className="form-row">
              <div className="login-form-group">
                <div className="alert text-red-400">{errorMessage}</div>
              </div>
            </div>
          )}
        </form>
      </div>

    </div>
  );
};

export default CreateCommissionForm;
