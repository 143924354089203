import { useState, useRef, useEffect, cloneElement } from 'react'

export default function CustomTabs({currentTab=0, tabs = [], tabButtonEqualWidth=false, newTabValue=0 }) {
  const [activeTab, setActiveTab] = useState(newTabValue) // Initialize the active tab state
  const [indicatorStyle, setIndicatorStyle] = useState({ left: '0', width: '0' })
  const tabsContainerRef = useRef(null)
  const tabRefs = useRef([])

  useEffect(()=>{
    setActiveTab(newTabValue)
  },[newTabValue])

  useEffect(()=>{
    if(currentTab) setActiveTab(currentTab)
  },[currentTab])

  // Update the indicator position whenever the active tab changes
  useEffect(() => {
    const updateIndicator = () => {
      const activeTabElement = tabRefs.current[activeTab]
      if (activeTabElement && tabsContainerRef.current) {
        const containerLeft = tabsContainerRef.current.getBoundingClientRect().left
        const tabLeft = activeTabElement.getBoundingClientRect().left
        setIndicatorStyle({
          left: `${tabLeft - containerLeft}px`,
          width: `${activeTabElement.offsetWidth}px`
        })
      }
    }

    updateIndicator() // Initial update when the component mounts
    window.addEventListener('resize', updateIndicator) // Update on resize
    return () => window.removeEventListener('resize', updateIndicator) // Clean up on unmount
  }, [activeTab]) // Depend on activeTab so it updates when the active tab changes

  return (
    <div className="w-full p-4">
      <div ref={tabsContainerRef} className="relative flex border-b overflow-x-auto">
        {tabs.map((tab, index) => (
          <button
            key={index}
            ref={el => tabRefs.current[index] = el}
            onClick={() => setActiveTab(index)}
            className={`py-2 px-4 text-base transition-all duration-300 ease-in-out whitespace-nowrap ${tabButtonEqualWidth && 'w-full'}
              ${activeTab === index 
                ? 'text-violet bg-[#f9f5ff]' 
                : 'text-gray-600 hover:text-gray-900'
              }`}
          >
            {tab.title}
          </button>
        ))}
        <div 
          className="absolute bottom-0 h-0.5 bg-violet transition-all duration-300 ease-in-out"
          style={indicatorStyle}
        />
      </div>
      <div className="mt-[48px] text-gray-600">
        {tabs && tabs.length && tabs?.map((tab, index) => (
          <div
            key={index}
            className={`transition-all duration-300 ease-in-out`}
            style={{
              visibility: activeTab === index ? 'visible' : 'hidden', // Hide inactive tabs
              opacity: activeTab === index ? 1 : 0, // Optionally apply fade-in/out effect
              height: activeTab === index ? 'auto' : 0, // Optionally collapse the inactive content
              overflow: 'hidden', // Prevent content from leaking out
            }}
          >
            {cloneElement(tab.content, {activeTab, setActiveTab})}
          </div>
        ))}
      </div>
    </div>
  )
}
