// import React, { useState, useEffect } from 'react';
// import './form.css'

// const InputText = ({disabled=false, isImportant = false, placeholder = 'Enter text', className = '', value, onChange, label, ...props }) => {
//     const [value_, setValue_] = useState(value || '');

//     useEffect(() => {
//         setValue_(value);
//     }, [value]);

//     const handleChange = (e) => {
//         const newValue = e.target.value;
//         if (newValue === '' || newValue.trim() !== '') {
//             setValue_(newValue);
//             if (onChange) {
//                 onChange(e);
//             }
//         }
//     };

//     return (
//         <div className="input-container">
//             {label && (
//                 <label className="input-label">
//                     {label}
//                     {isImportant && <span className="input-important">*</span>}
//                 </label>
//             )}
//             <input
//                 type="text"
//                 placeholder={placeholder}
//                 value={value_}
//                 onChange={handleChange}
//                 disabled={disabled}
//                 {...props}
//                 className={`input-text box-shadow-default ${disabled && 'cursor-not-allowed'} ${className}`}
//             />
//         </div>
//     );
// };

// export default InputText;





import React, { useState, useEffect } from 'react';
import './form.css'
import './inputtext.module.css'

const InputText = ({disabled=false, isImportant = false, placeholder = 'Enter text', className = '', value, onChange, label, type='text', ...props }) => {
    const [value_, setValue_] = useState(value || '');

    useEffect(() => {
        setValue_(value);
    }, [value]);

    const handleChange = (e) => {
        setValue_(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className="input-container">
            {label && (
                <label className={`input-label ${isImportant ? 'imp' : ''}`}>
                    {label}
                </label>
            )}
            <input
                type={type}
                placeholder={placeholder}
                value={value_}
                onChange={handleChange}
                disabled={disabled}
                {...props}
                className={`!border-[#d0d5dd] input-text box-shadow-default ${disabled && 'cursor-not-allowed'}`}
            />
        </div>
    );
};

export default InputText;
