import { useEffect, useState } from "react";
import CheckMark from "../../../icons/CheckMark";
import Tooltip from "../../Tooltip/Tooltip";
import CommentIcon from "./CommentIcon";
import { useSelector } from "react-redux";
import CrossInCircleIcon from "../icons/CrossInCircleIcon";


const MediaActions = ({ showSelect = true, showComment = true, handleCommentClick, handleClick = () => null, data, setSelectedImages = () => null, selectedImages = [], isVideo = false, showDownLable = true }) => {
    const { commissionDetails } = useSelector(state => state.commissionContent)
    const { userType } = useSelector((state) => state.user);
    const [checked, setChecked] = useState(false)
    const handleChecked = (e) => {
        e.stopPropagation()
        if (commissionDetails.isSubmitted || data?.isLicenced || data?.is_rejected) return

        if (!selectedImages.includes(data.id)) {
            setSelectedImages([...selectedImages, data.id])
        }
        else {
            const filterd = selectedImages.filter((obj) => obj !== data.id)
            setSelectedImages(filterd)
        }
    }
    useEffect(() => {
        if (selectedImages.includes(data.id)) setChecked(true)
        else setChecked(false)
    }, [selectedImages])
    return (
        <div onClick={handleClick} className={`flex absolute w-full ${isVideo ? "h-[100px]" : "h-full"} px-[19px] py-[15px] left-0 top-0 ${isVideo ? "flex-row" : "flex-col"} justify-between items-start`} >
            {
                showSelect ?
                    <Tooltip text={data?.isLicenced ? "Can't select licenced image!" : ""} position={'right'}>

                        <span onClick={handleChecked} className= {`${checked ? 'bg-violet flex' : 'bg-white hidden group-hover:flex'} rounded w-[20px] h-[20px]  justify-center items-center  ${commissionDetails.isSubmitted && 'cursor-not-allowed'}`} >
                            <CheckMark color={checked ? 'white' : "#D0D5DD"} />
                        </span>
                    </Tooltip>
                    : <div></div>
            }

            <div className="w-full flex justify-end z-50 gap-1 items-center">
                {data.is_rejected && (
                    <div className="bg-[#F9F5FF] text-[#344054] flex items-center gap-1 text-xs px-2 rounded mr-1">
                        <CrossInCircleIcon
                            color="#344054"
                            size={12}
                            fill="#344054"
                        />
                        <span>Rejected</span>
                    </div>
                )}
                {
                    showComment && !!data?.commentCount && (
                        <span className="cursor-pointer " onClick={handleCommentClick} >
                            <CommentIcon commentsCount={data?.commentCount || 0} />
                        </span>
                    )}
            </div>
        </div>
    )
}

export default MediaActions;