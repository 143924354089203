// awsService.js
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

import AWS from 'aws-sdk';
import defPasswordGen from "../../utils/defPasswordGen";


const awsCofig = {
  accessKeyId: process.env.REACT_APP_COGNITO_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_COGNITO_AWS_SECRET_KEY,
  region: process.env.REACT_APP_COGNITO_REGION
}

AWS.config.update(awsCofig)

const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();


const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
};

const userPool = new CognitoUserPool(poolData);

export const authenticateUser = (username, password) => {
  return new Promise((resolve, reject) => {
    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        // Authentication successful
        resolve({ isAuthenticated: true, isConfirmed: true, idToken: session.idToken });
      },
      onFailure: (error) => {
        // Authentication failed
        if (error.code === "UserNotConfirmedException") {
          console.error("User is not confirmed yet", error);
          resolve({ isAuthenticated: false, isConfirmed: false });
        } else {
          console.error("Authentication failed", error);
          resolve({ isAuthenticated: false, isConfirmed: true });
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // Handle new password requirement (if needed)
        resolve({ isAuthenticated: false, isConfirmed: true });
      },
    });
  });
};

export const signUpUser = async (email, password, attribute_list) => {
  return new Promise((resolve, reject) => {
    const attributeList = attribute_list ? attribute_list : [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
    ];

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        console.log("User registration failed", err);
        reject(err);
        return;
      }

      // const cognitoUser = result.user;
      const cognitoId = result.userSub

      if (result.userConfirmed === false) {
        resolve({ showConfirmationCode: true, isUserExists: false, cognitoId });
      } else {
        resolve({ showConfirmationCode: false, isUserExists: false, cognitoId });
      }
    });

  });
};



export const resetPassword = (username, verificationCode, newPassword) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const forgotPassword = (username) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
      inputVerificationCode: () => {
        resolve();
      },
    });
  });
};

export const confirmRegistration = (username, confirmationCode) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(
      confirmationCode,
      true,
      (confirmationErr, confirmationResult) => {
        if (confirmationErr) {
          console.error("Confirmation error:", confirmationErr);
          reject(confirmationErr);
          // return confirmationErr
        } else {
          resolve(confirmationResult);
        }
      }
    );
  });
};

export const resendConfirmationCode = (username) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error("Error resending confirmation code:", err);
        reject("Error resending confirmation code. Please try again.");
      } else {
        resolve("Confirmation code sent successfully!");

        // Implement the timer logic after successful code resend if needed
      }
    });
  });
};

export const changePassword = (username, oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    const authenticationData = {
      Username: username,
      Password: oldPassword,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        // Authentication successful, proceed to change password
        cognitoUser.changePassword(
          oldPassword,
          newPassword,
          (changePasswordErr, changePasswordResult) => {
            if (changePasswordErr) {
              console.error("Change password error:", changePasswordErr);
              reject(changePasswordErr);
            } else {
              resolve(changePasswordResult);
            }
          }
        );
      },
      onFailure: (authError) => {
        console.error("Authentication failed during change password:", authError);
        reject(authError);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // The user needs to set a new password before proceeding
        cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
          onSuccess: (session) => {
            // New password challenge completed successfully
            resolve(session);
          },
          onFailure: (challengeError) => {
            console.error("New password challenge error:", challengeError);
            reject(challengeError);
          }
        });
      },
    });
  });
};



export const checkUserEmailWithPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve({ emailExists: true, emailVerified: true });
      },

      onFailure: (err) => {
        if (err.code === "UserNotFoundException") {
          resolve({ emailExists: false, emailVerified: false });
        } else if (err.code === "NotAuthorizedException") {
          resolve({ emailExists: true, emailVerified: true });
        } else if (err.code === "UserNotConfirmedException") {
          resolve({ emailExists: true, emailVerified: false });
        } else {
          reject(err);
        }
      },
    });
  });
};





// // Initialize the Cognito Identity Service Provider
// const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
//   region: 'eu-north-1', // e.g., 'us-west-2'
// });

export const checkUserEmail = async (email) => {
  let cognitoId;
  let userType
  try {
    // Fetch user details from Cognito
    const userData = await cognitoIdentityServiceProvider
      .adminGetUser({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || 'your-user-pool-id', // Replace with your User Pool ID
        Username: email,
      })
      .promise();

    cognitoId = userData.UserAttributes.find((attr) => attr.Name === 'sub')?.Value || "";

    userType = userData.UserAttributes.find((attr) => attr.Name === 'locale')?.Value || "";
    // Check if email is verified
    const emailVerified = userData.UserAttributes.find(
      (attr) => attr.Name === 'email_verified'
    )?.Value === 'true';


    return {
      emailExists: true,
      emailVerified,
      cognitoId: cognitoId,
      userType

    };
  } catch (err) {
    if (err.code === 'UserNotFoundException') {
      return {
        emailExists: false,
        emailVerified: false,
        userData: cognitoId,
        userType

      };
    } else {
      // Handle other errors
      throw err;
    }
  }
};



export const deleteUserByEmail = async (email) => {
  try {
    // Fetch user details to get the Username (Cognito ID)
    const userData = await cognitoIdentityServiceProvider
      .adminGetUser({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || 'your-user-pool-id', // Replace with your User Pool ID
        Username: email,
      })
      .promise();

    const cognitoId = userData.UserAttributes.find((attr) => attr.Name === 'sub')?.Value || '';

    // Delete the user
    await cognitoIdentityServiceProvider
      .adminDeleteUser({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || 'your-user-pool-id', // Replace with your User Pool ID
        Username: cognitoId, // Use the Cognito ID (or the email itself if that's the username)
      })
      .promise();

    return { success: true, message: 'User account deleted successfully' };
  } catch (err) {
    if (err.code === 'UserNotFoundException') {
      return { success: false, message: 'User not found' };
    } else {
      // Handle other errors
      throw err;
    }
  }
};


export const confirmUser = async (email, type = 'simple') => {
  // Confirm the user without OTP
  const adminConfirmParams = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    Username: email,
  };

  try {
    if (type === "force") {
      // Set a permanent password and confirm the user without sending OTP
      const confirmResponse = await cognitoIdentityServiceProvider.adminSetUserPassword({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        Username: email,
        Password: defPasswordGen(email || ""),  // Replace with desired permanent password
        Permanent: true,  // Set the password permanently and confirm the user
      }).promise();
      return { confirmResponse }

    }
    const confirmResponse = await cognitoIdentityServiceProvider
      .adminConfirmSignUp(adminConfirmParams)
      .promise();

    // After confirmation, set the email_verified attribute to true
    const adminUpdateUserAttributesParams = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      Username: email,
      UserAttributes: [
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
    };

    const updateResponse = await cognitoIdentityServiceProvider
      .adminUpdateUserAttributes(adminUpdateUserAttributesParams)
      .promise();

    return { confirmResponse, updateResponse };
  } catch (error) {
    console.error('Error confirming user or updating attributes:', error);
    throw error;
  }
};




export const createUserWithoutOtp = async (email, passwordDummy, attributeList) => {
  const params = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    Username: email,  // Replace with the desired username
    TemporaryPassword: passwordDummy,  // Set a temporary password
    UserAttributes: [
      ...attributeList,
      {
        Name: 'email',
        Value: email,   // Replace with user's email
      },
      {
        Name: 'email_verified',
        Value: 'true',
      },

    ],
    MessageAction: 'SUPPRESS',  // Prevents the OTP email from being sent
  };

  try {
    // Step 1: Create the user with a temporary password
    const resp = await cognitoIdentityServiceProvider.adminCreateUser(params).promise();
    const cognitoId = resp.User?.Attributes.find((attr) => attr.Name === 'sub')?.Value || '';

    return { cognitoId }

  } catch (error) {
    console.error('Error creating user:', error);
  }
};

