import React from 'react'

function IncompleteIndicator() {
  return (
    <svg width="32" style={{zIndex:1}} height="33" viewBox="0 0 32 33" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 16.2305C1 7.9462 7.71573 1.23047 16 1.23047C24.2843 1.23047 31 7.9462 31 16.2305C31 24.5147 24.2843 31.2305 16 31.2305C7.71573 31.2305 1 24.5147 1 16.2305Z" stroke="#EAECF0" stroke-width="2"/>
        <circle cx="16" cy="16.2305" r="5" fill="#D0D5DD"/>
    </svg>
  )
}

export default IncompleteIndicator