import { createSlice } from '@reduxjs/toolkit';
import { fetchRevision1, fetchRevision2, fetchFinalWork } from './thunk.actions'; // Import thunks

const initialState = {
  revision1: {
    data: [],
    dataByCreatorName: {}, // New field to group by creator_name
    loading: false,
    error: null,
  },
  revision2: {
    data: [],
    dataByCreatorName: {}, // New field to group by creator_name
    loading: false,
    error: null,
  },
  finalWork: {
    data: [],
    dataByCreatorName: {}, // New field to group by creator_name
    loading: false,
    error: null,
  },
  commissionDetails: {}, // Initialize commissionDetails as an empty object
};

const commissionContentSlice = createSlice({
  name: 'commissionContent',
  initialState,
  reducers: {
    // Setter for manually setting revision data
    setRevisionData: (state, action) => {
      const { revision, data } = action.payload;
      if (state[revision]) {
        state[revision].data = data;
        // Automatically update dataByCreatorName after setting the data
        state[revision].dataByCreatorName = data.reduce((acc, item) => {
          if (!acc[item.creator_name]) {
            acc[item.creator_name] = [item];
          } else {
            acc[item.creator_name].push(item);
          }
          return acc;
        }, {});
      } else {
        console.error(`Revision ${revision} does not exist.`);
      }
    },

    // Setter for manually setting commission details
    setCommissionDetails: (state, action) => {
      state.commissionDetails = {...action.payload,isSubmitted:action.payload?.status==='submitted'?true:false}; // Set the commission details object
    },
  },
  extraReducers: (builder) => {
    // Revision 1 actions
    builder.addCase(fetchRevision1.pending, (state) => {
      state.revision1.loading = true;
      state.revision1.error = null;
    });
    builder.addCase(fetchRevision1.fulfilled, (state, action) => {
      state.revision1.loading = false;
      state.revision1.data = action.payload?.data;
      
      // Group the fetched data by creator_name
      state.revision1.dataByCreatorName = action.payload?.data?.reduce((acc, item) => {
        if (!acc[item.creator_name]) {
          acc[item.creator_name] = [item];
        } else {
          acc[item.creator_name].push(item);
        }
        return acc;
      }, {}) || {};
    });
    builder.addCase(fetchRevision1.rejected, (state, action) => {
      state.revision1.loading = false;
      state.revision1.error = action.error.message;
    });

    // Revision 2 actions
    builder.addCase(fetchRevision2.pending, (state) => {
      state.revision2.loading = true;
      state.revision2.error = null;
    });
    builder.addCase(fetchRevision2.fulfilled, (state, action) => {
      state.revision2.loading = false;
      state.revision2.data = action.payload?.data;

      // Group the fetched data by creator_name
      state.revision2.dataByCreatorName = action.payload?.data?.reduce((acc, item) => {
        if (!acc[item.creator_name]) {
          acc[item.creator_name] = [item];
        } else {
          acc[item.creator_name].push(item);
        }
        return acc;
      }, {}) || {};
    });
    builder.addCase(fetchRevision2.rejected, (state, action) => {
      state.revision2.loading = false;
      state.revision2.error = action.error.message;
    });

    // Final Work actions
    builder.addCase(fetchFinalWork.pending, (state) => {
      state.finalWork.loading = true;
      state.finalWork.error = null;
    });
    builder.addCase(fetchFinalWork.fulfilled, (state, action) => {
      state.finalWork.loading = false;
      state.finalWork.data = action.payload?.data;

      // Group the fetched data by creator_name
      state.finalWork.dataByCreatorName = action.payload?.data?.reduce((acc, item) => {
        if (!acc[item.creator_name]) {
          acc[item.creator_name] = [item];
        } else {
          acc[item.creator_name].push(item);
        }
        return acc;
      }, {}) || {};
    });
    builder.addCase(fetchFinalWork.rejected, (state, action) => {
      state.finalWork.loading = false;
      state.finalWork.error = action.error.message;
    });
  },
});

// Selector function to get revision data based on pageType
export const getRevisionData = (state, pageType) => {
  switch (pageType) {
    case 'revision_1':
      return state.commissionContent.revision1;
    case 'revision_2':
      return state.commissionContent.revision2;
    case 'final_work':
      return state.commissionContent.finalWork;
    default:
      return {
        data: [],
        loading: false,
        error: null,
        dataByCreatorName: {},
      };
  }
};

// Revalidate function to refetch all revisions at the same time
export const revalidate = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const commissionXrefId = state.commissionContent.commissionDetails.cs_id || state.commissionContent.commissionDetails.commissionSchoolXrefId;
    const userType = state.user.userType==='brand' || state.user.userType==='school' ? 'school': 'creator'; // Assuming userType is stored here, modify as needed

    // Dispatch all the fetch actions concurrently
    await Promise.all([
      dispatch(fetchRevision1({ commissionXrefId, userType })),
      dispatch(fetchRevision2({ commissionXrefId, userType })),
      dispatch(fetchFinalWork({ commissionXrefId, userType })),
    ]);
  } catch (error) {
    console.error('Error in revalidate:', error);
  }
};

export const { setRevisionData, setCommissionDetails } = commissionContentSlice.actions;  // Export the setter functions

export default commissionContentSlice.reducer;
