import React, { memo } from "react";


function BorderLessButton({ text = '', style={}, className = '', onClick = ()=>null, Icon, disabled=false }) {
    return (
        <button style={{...style}} disabled={disabled} onClick={onClick} className={`${className} ${disabled ? 'cursor-not-allowed text-slate-500' : 'text-violet'} bg-white flex gap-[3px] py-[14px] px-[10px] items-center text-[14.6px]`}>
            {Icon}
            <span>{text}</span>
        </button>
    )
}

export default memo(BorderLessButton);