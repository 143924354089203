import React, { useState, useRef, useEffect } from 'react';
import classes from "./AutoResizeTextarea.module.css";

const AutoResizeTextarea = ({
  value = '',
  onChange = () => {},
  placeholder = 'Enter text',
  className = '',
  onKeyDown = () => {},
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      // Set a maximum height to prevent the textarea from growing out of the screen
      const maxHeight = 60;
      if (textareaRef.current.scrollHeight > maxHeight) {
        textareaRef.current.style.height = `${maxHeight}px`;
        textareaRef.current.style.overflowY = 'auto';
      }
    }
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`${classes.textarea} ${className}`}
      rows={1}
      onKeyDown={onKeyDown}
    />
  );
};

export default AutoResizeTextarea;
