import React, { useEffect, useState } from "react"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import CommssionGrid from "../../../components/CommissionGrid";
import { useLocation } from "react-router-dom";
import CommissionTabForTable from "../../../components/commissions/CommissionTabForTable";

function CommissionGetPage() {
  const [showBreadCrumb, setShowBreadCrumb] = useState(true)
  const location = useLocation();
  const  hideBreadCrumb  = location.state?.hideBreadCrumb

  useEffect(() => {
    let id
    if (hideBreadCrumb) {
      id = setTimeout(() => setShowBreadCrumb(true), 15000)
      setShowBreadCrumb(false)
    }
    return () => {
      clearTimeout(id)
    }
  }, [])

  return (
    <div className="custom-max-width">
      {showBreadCrumb && <Breadcrumb
        paths={[
          { name: "Home", link: "/galleries" },
          { name: "Commissions", link: "/commissions" },
        ]}
        classs="ms-12"
      />}
      {/* <CommssionGrid /> */}
      <CommissionTabForTable />
    </div>
  );
}

export default CommissionGetPage;
