import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Badge from '../../../components/badge';
import NoCommission from '../../../components/cards/NoCommission';
import EditIcon from '../../../icons/Edit';
import SchoolTabView from '../../../components/CommissionTabView/SchoolTabView';
import useModal from '../../../components/popups/modal/useModal'
import Disclaimer from '../../../components/popups/Disclaimer';
import { acceptRejectProject, getCommissionDetails, getProjectContent, getProjectParticipants, submitProject } from '../../../services/schoolServices';
import { SidebarContext } from '../../../App';
import ContentGallery from '../../../components/ContentGallery';
import { makeGalleryArray } from '../../../utils/galleryUtils';
import LoadingWrapper from '../../../components/loadingWrapper/LoadingWrapper';
import useToast from '../../../hooks/useToast';
import MessageInfoCard from '../../../components/MessageInfoCard';
import NotifyMsg from '../../../components/NotifyMsg';
import CommissionReviewAndContentForSchool from './CommissionReviewAndContentForSchool';
import { setCommissionDetails } from '../../../redux/reducerFiles/commission-content/commissionContentReducer';
import { useDispatch } from 'react-redux';
import CommissionReviewAndContent from '../../../components/commission-content/CommissionReviewAndContent';

const SchoolProjectDetails = () => {
  const navigate = useNavigate();
  const showToast = useToast()
  const { state: ProjectData, isCreator } = useLocation()
  const { open, setOpen, toggle } = useModal()
  const { globalLoader, setGlobalLoader } = useContext(SidebarContext)
  const [initialLoading, setInitialLoading] = useState(true)
  const [isContentLoading, setIsContentLoading] = useState(false)
  const [isCommissionLoading, setIsCommissionLoading] = useState(false)
  const [contentData, setContentData] = useState([])
  const [columns, setColumns] = useState([])

  const { commissionXrefId } = useParams();
  const [commissionData, setCommissionData] = useState({})
  const [participants, setParticipants] = useState([])

  const [showNotify, setShowNotify] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [disable, setDisable] = useState(false)
  const location = useLocation();
  const message = location.state?.message
  const messageType = location.state?.messageType
  const dispatch = useDispatch()
  useEffect(() => {
    setDisable(isNew || !isAccepted || isSubmited);
  }, [isNew, isAccepted, isSubmited]);


  useEffect(() => {

    let id
    if (message) {
      id = setTimeout(() => { setShowNotify(false) }, 15000)
      setShowNotify(true)
    }
    return () => {
      clearTimeout(id)
    }

  }, [message])



  const handleAcceptClick = async (type = 'accept') => {
    try {
      setGlobalLoader(true)
      if (!type) {
        showToast('select correct button', 'error')
      }
      const payload = {
        commission_xref_id: commissionXrefId,
        type,
      }
      const resp = await acceptRejectProject(payload)
      showToast(`Project ${type}ed.`, 'success')
      setGlobalLoader(false)
      await fetchCommissionDetails(commissionXrefId)

    } catch (error) {
      console.log(error)
      setGlobalLoader(false)

    }

  };

  const handleSubmit = async () => {
    try {
      if (isSubmited && !isAccepted) {
        showToast("Already submitted or rejected ", "error")
        setOpen(false)

      } else {
        setGlobalLoader(true)
        const payload = {
          commission_xref_id: commissionXrefId,
          status: "submitted"
        }
        const resp = await submitProject(payload)
        setOpen(false)
        showToast("Submitted successfully", "success")
        await fetchCommissionDetails(commissionXrefId)
        setGlobalLoader(false)

      }
    } catch (error) {
      setGlobalLoader(false)
    }
  }


  const fetchCommissionDetails = async commissionXrefId => {
    try {
      setIsCommissionLoading(true)
      const res = await getCommissionDetails({ commissionXrefId })
      dispatch(setCommissionDetails(res.data))
      setIsAccepted(res?.data?.is_accepted || false)
      setIsSubmited(res?.data?.status == "in_progress" ? false : true)
      setIsNew(res?.data?.isNew || false)
      setCommissionData(res.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsCommissionLoading(false)
      setInitialLoading(false)
    }
  }

  const fetchProjectContents = async () => {
    try {
      setIsContentLoading(true)
      const contentsResp = await getProjectContent(commissionXrefId)
      setContentData(contentsResp?.data || {})
      setColumns(makeGalleryArray(contentsResp?.data || []))
      setIsContentLoading(false)
    } catch (error) {
      console.error({ error })
      setIsContentLoading(false)
    }


  }

  const fetchParticipants = async () => {
    try {
      const participantsResp = await getProjectParticipants(commissionXrefId)
      setParticipants(participantsResp?.data)
    } catch (error) {
      console.error({ error })
    }

  }



  useEffect(() => {
    if (commissionXrefId) {
      setInitialLoading(true)
      fetchProjectContents()
      fetchParticipants()
      fetchCommissionDetails(commissionXrefId)
      setInitialLoading(false)

    }
  }, [commissionXrefId])


  return (
    <>
      {
        !initialLoading ?
          <>
            <div className="bg-white p-6">
              {/* Breadcrumb */}
              <Breadcrumb
                paths={[
                  { name: 'Projects', link: '/school' },
                  { name: commissionData?.name || "Project ", link: '/commissions' },
                ]}
                className={`font-bold px-2`}

              />

              <LoadingWrapper loading={isCommissionLoading}>

                <h1 className="text-3xl font-bold pt-4">{commissionData?.name}</h1>
                <h4 className="text-gray-500 pt-2">Sponsored by {commissionData?.brand_name}</h4>

                {/* Primary button to navigate to upload */}
                {/* <PrimaryButton
                  onClick={() => {

                    disable ? showToast("Can't Submited", 'error') : setOpen(true)
                  }}
                  className={`w-[164.47px] h-[41.92px] absolute top-[125px] right-[20px] px-[14.64px] py-[10.46px] bg-[#7F56D9] rounded-md border-opacity-0  ${disable ? "text-slate-400" : " text-white"} text-m font-semibold rounded-md shadow-md border-t-[1.05px] border-transparent opacity-100 flex items-center justify-center z-100`}
                  text="Submit to swiirl"
                  disabled={disable ? true : false}

                /> */}
                <PrimaryButton
                  onClick={() => {

                    navigate('/school/invite-contributors', { state: { commissionXrefId, commissionToDisplay: commissionData } });
                  }}
                  className={`w-[164.47px] h-[41.92px] absolute top-[125px] right-[20px] px-[14.64px] py-[10.46px] bg-[#7F56D9] rounded-md border-opacity-0  ${disable ? "text-slate-400" : " text-white"} text-m font-semibold rounded-md shadow-md border-t-[1.05px] border-transparent opacity-100 flex items-center justify-center z-100`}
                  text="Invite contributors"
                  disabled={disable ? true : false}



                />

                {/* Badge and Accept/Reject buttons */}



                <div className="flex flex-row gap-4 items-center pt-4 mt-4">
                  {/* Updated Badge */}

                  {
                    commissionData?.isNew ?
                      <Badge text={"New"} type={'error'} />
                      :
                      <Badge text={commissionData?.status === "in_progress" ? "IN PROGRESS" : commissionData?.status?.toUpperCase()} type={commissionData?.status === "in_progress" ? "info" : 'success'} />

                  }

                  <div className="flex flex-row gap-4 pb-3">
                    {/* || !isAccepted  */}
                    {(commissionData?.isNew) ? (
                      <>
                        <div className="w-44">
                          <button
                            type="button"
                            className={`border-solid border-2 p-2 rounded-lg w-full font-bold text-lg ${isAccepted
                              ? 'text-[#98A2B3] border-[#EAECF0] bg-[#FFFFFF]'
                              : 'text-[#6941C6] border-[#D6BBFB]'
                              }`}
                            onClick={() => handleAcceptClick("accept")}
                          // disabled={disable ? true : false}
                          >
                            Accept
                          </button>
                        </div>
                        <div className="w-44">
                          <button
                            type="button"
                            className="border-solid border-2 border-[#D0D5DD] p-2 rounded-lg text-[#344054] w-full font-bold text-lg"
                            // disabled={!commissionData?.isNew}
                            onClick={() => handleAcceptClick("reject")}
                          >
                            Reject project
                          </button>
                        </div>
                      </>
                    ) : (

                      !disable &&
                      <div className="w-44">
                        <button
                          type="button"
                          className="border-solid border-2 border-slate-400 p-2 rounded-lg text-slate-400 w-full font-bold text-lg"
                          disabled={!commissionData?.isNew}
                        >
                          {isAccepted ? 'Accepted' : 'Rejected'}
                        </button>
                      </div>
                    )}
                  </div>

                </div>

                {showNotify && <NotifyMsg messageType={messageType} message={message} className="mb-5" />}
                <SchoolTabView
                  commissionToDisplay={commissionData}
                  fromContentPage={false}
                  disableContributorsButton={disable ? true : false}
                  participants={participants} setParticipantCount={setParticipants}
                />
              </LoadingWrapper>


              {/* Uploaded content section */}
              <div className="flex flex-row justify-between mb-6 items-center mt-5">
                <p className="font-semibold text-2xl">Uploaded content</p>
                {/* <PrimaryButton
                  onClick={() => navigate('/school/project/upload?type=add', { state: { commissionXrefId: commissionXrefId, type: "add" } })}
                  className={`w-[144.47px] h-[41.92px] absoluted top-[125px] right-[20px] px-8 py-2 bg-[#7F56D9]  ${disable ? "text-slate-400" : " text-white"} text-m font-semibold rounded-md shadow-md border-t-[1.05px] border-transparent opacity-100 flex items-center justify-center z-100`}
                  text="Add content"
                  disabled={disable ? true : false}

                /> */}

              </div>
              
              {/* {contentData && contentData.length > 0 &&
                <MessageInfoCard className="col-9 relative w-100 mb-2" heading={'Submitting your ready project to Swiirl '} subHeading={'Once all contributors have added their art, please submit it to Swiirl. You will no longer be able to add art to a submitted project.'} />
              } */}

              <CommissionReviewAndContent isAccepted={isAccepted}  />

            </div>
            {
              open &&
              <Disclaimer
                open={open}
                setOpen={setOpen}
                toggle={toggle}
                onClick={handleSubmit}
                disable={globalLoader}
                primaryButtonLabel="Submit"
              />
            }
          </>
          : null
      }
    </>
  );
};

export default SchoolProjectDetails;
