import React from "react";

function ExpandImageIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.377441"
        y="0.972656"
        width="40"
        height="40"
        rx="8"
        fill="#EEEBF5"
      />
      <path
        d="M22.3774 18.9727L29.3774 11.9727M29.3774 11.9727H23.3774M29.3774 11.9727V17.9727M18.3774 22.9727L11.3774 29.9727M11.3774 29.9727H17.3774M11.3774 29.9727L11.3774 23.9727"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ExpandImageIcon;
