import CompleteIndicator from "./CompleteIndicator";
import CurrentIndicator from "./CurrentIndicator";
import IncompleteIndicator from "./IncompleteIndicator";

export default function TimelineTracker({steps = []}) {

    
  return (
    <div className="w-full mx-auto">
      <div className="relative w-full">
        <div className="relative flex justify-between">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center relative flex-1">
              {index < steps.length - 1 && (
                <div 
                  className={`absolute top-[2.75rem] left-[50%] w-[100%] h-0.5 ${step.status === 'completed' ? 'bg-violet':'bg-gray-200'}`} 
                  style={{
                    transform: 'translateX(-1rem)',
                  }}
                />
              )}
              
              
              {!!step.date ? <div className="text-sm text-gray-500 mb-2">{step.date}</div> : <div className="text-sm text-gray-500 mb-2">&nbsp;</div>}

              {/* Circle indicator */}
              {step.status === 'completed' ? (
                <CompleteIndicator />
              ) : step.status === 'current' ? (
                <CurrentIndicator />
              ) : (
                <IncompleteIndicator />
              )}

              <div className="mt-4 text-center max-w-[200px]">
                <p className="font-medium text-gray-900">{step.title}</p>
                <p className="text-sm text-gray-500 mt-1">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

