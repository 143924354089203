import React, { createContext, useContext, useState } from "react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchText, setSearchText] = useState("");
  const [favFilterState, setFavFilterState] = useState("all");

  return (
    <SearchContext.Provider value={{ searchText, setSearchText, favFilterState, setFavFilterState }}>
      {children}
    </SearchContext.Provider>
  );
};
export const useSearch = () => useContext(SearchContext);
