import React, { useEffect, useMemo, useRef, useState } from "react";
import AutoResizeTextarea from "./AutoResizeTextarea";
import { SendIcon, SendLoader } from "./SendIcon";
import { useSelector } from "react-redux";
import { getComments, postComments } from "../../services/brandServices";
import useToast from "../../hooks/useToast";
import LoadingWrapper from "../../components/loadingWrapper/LoadingWrapper";
import CustomTabs from "../custom-tabs/CustomTabs";
import EditIcon from "../../icons/EditIcon";
import OutllinedPrimaryBtn from "../buttons/OutllinedPrimaryBtn";
import BorderLessButton from "../buttons/BorderLessButton";
import { useLocation, useNavigate } from "react-router-dom";

const CommentSection = ({
  handleCommentClick,
  setShowComments,
  showComments,
  content_id,
  commission_school_xref_id,
  sender_id,
  currentSquare,
  setCurrentSquare,
  squaresHistory,
  setSquaresHistory,
  creatorData = {},
  newTabValue,
}) => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentLoader, setCommentLoader] = useState(false);
  const showToast = useToast();
  const commentsEndRef = useRef(null);
  const { userType } = useSelector((state) => state.user);
  const { fullName, profileUrl } = useSelector((state) => state.user);

  useEffect(() => {
    if (showComments) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showComments]);

  const handleTextChange = (e) => {
    setText(e.target.value); // Update the text when user types
  };

  const [comments, setComments] = useState([]);

  const fetchComments = async (content_id) => {
    setCommentLoader(true);
    try {
      const res = await getComments(content_id);
      const commentsData = Array.isArray(res.comments) ? res.comments : [];
      const formattedComments = commentsData.map((comment) => ({
        commentId: comment.comment_id,
        sender: {
          name: comment.sender.name,
          profileImg: comment.sender.profileImg,
        },
        reciever: {},
        mediaType: "text",
        files: [],
        text: comment.text || "No comment text available",
        loading: false, // Set loading state for existing comments
      }));

      const squares = [];
      const lines = [];
      const circles = [];

      for (const comment of commentsData) {
        if (comment.squares && comment.squares.length > 0) {
          const data = comment.squares.map((item) => ({
            ...item,
            commentId: comment.comment_id,
          }));
          squares.push(...data);
        }
        if (comment.lines && comment.lines.length > 0) {
          const data = comment.lines.map((item) => ({
            points: [...item.points],
            commentId: comment.comment_id,
          }));
          lines.push(...data);
        }
        if (comment.circles && comment.circles.length > 0) {
          const data = comment.circles.map((item) => ({
            ...item,
            commentId: comment.comment_id,
          }));
          circles.push(...data);
        }
      }

      setSquaresHistory(squares);

      setComments(formattedComments);
      setCommentLoader(false);
    } catch (error) {
      setCommentLoader(false);
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    if (content_id) {
      fetchComments(content_id);
    }
  }, [content_id]);

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comments]);

  const handleSendClick = async (e) => {
    e.preventDefault();

    if (!text.trim()) {
      return;
    }

    const newComment = {
      sender: {
        name: fullName,
        profileImg: profileUrl,
      },
      reciever: {},
      mediaType: "text",
      files: [],
      text: text,
      loading: true,
      commentId: comments.length + 1, // Set the comment's loading state to true
    };

    // setCurrentSquare([])

    // Optimistically update UI with loading state
    setComments([...comments, newComment]);
    // setSquaresHistory(([...comments, newComment]))
    setText("");
    setLoading(true);

    const payload = {
      content_id: content_id,
      commission_school_xref_id: commission_school_xref_id,
      sender_id: sender_id,
      text: text,
      files: [],
      sender_type: "brand",
      media_type: "text",
      squares: currentSquare,
    };

    try {
      const response = await postComments({ payload });

      if (response.errorMessage) {
        showToast("Error while commenting", "error");
        return;
      }

      // Find and update the comment's loading state to false
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.text === text ? { ...comment, loading: false } : comment
        )
      );
      await fetchComments(content_id);
      setLoading(false);
    } catch (error) {
      console.error("Error sending comment:", error);
      setLoading(false);
    } finally {
      setCurrentSquare([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent the default new line behavior when pressing Enter
      e.preventDefault();
      // Trigger the send button click
      handleSendClick(e);
    }
  };

  const tabs = useMemo(
    () => [
      {
        title: "Comments",
        content: (
          <>
            <div className="flex flex-col h-[60vh]">
              <LoadingWrapper loading={commentLoader}>
                <div className="flex items-start gap-3 flex-col h-full overflow-y-auto p-4">
                  {comments.map((comment, i) => {
                    return (
                      <CommentItem
                        handleCommentClick={handleCommentClick}
                        key={i}
                        comment={comment}
                      />
                    );
                  })}
                  <div ref={commentsEndRef} />
                </div>
              </LoadingWrapper>
            </div>

            {/* textarea */}
            {userType === "brand" && (
              <div className="w-full py-3 pb-4">
                <div className="w-full px-2 flex flex-row gap-2 border justify-between p-3 rounded-md border-[#D0D5DD]">
                  <img
                    className="size-12 rounded-full object-cover"
                    src={profileUrl}
                    alt=""
                  />
                  <div>
                    <AutoResizeTextarea
                      value={text}
                      onChange={handleTextChange}
                      onKeyDown={handleKeyDown}
                      placeholder="Write your feedback"
                    />
                  </div>
                  <button
                    onClick={handleSendClick}
                    className={`mx-auto my-auto`}
                  >
                    <SendIcon />
                  </button>
                </div>
              </div>
            )}
          </>
        ),
      },
      {
        title: "Info",
        content: (
          <ContentCreatorInfoTab
            creatorData={{
              creatorBio: creatorData?.creator_bio,
              creatorInspiration: creatorData?.inspiration,
            }}
          />
        ),
      },
    ],
    [commentLoader, comments, text, creatorData?.creator_bio]
  );

  return (
    <div
      className={`fixed top-0 right-0 w-96 h-full bg-white transform transition-transform duration-300 ease-in-out ${
        showComments ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="w-full h-full">
        <div className="flex flex-col bg-red">
          <div className="p-4 border-b flex items-center justify-between w-full">
            <h2 className="text-lg font-semibold">Comments and Info</h2>
            <button
              onClick={() => setShowComments(false)}
              className="p-2 hover:bg-gray-100 rounded-full"
              aria-label="Close comments"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <CustomTabs newTabValue={newTabValue} tabs={tabs} tabButtonEqualWidth={true} />
        </div>
      </div>
    </div>
  );
};

export default CommentSection;

function CommentItem({ handleCommentClick, comment }) {
  return (
    <div
      className="flex flex-row gap-2 hover:cursor-pointer w-full hover:bg-slate-100/50 p-1 rounded-sm"
      onClick={() => handleCommentClick(comment.commentId)}
    >
      <img
        src={comment?.sender?.profileImg}
        alt={`Profile-img`}
        className="min-w-8 min-h-8 max-w-8 max-h-8 rounded-full object-cover"
      />
      <div className="max-w-fit">
        <p className="text-sm text-gray-600 break-all">
          {comment?.loading ? (
            <div className="opacity-50">{comment?.text}</div>
          ) : (
            <div>
              {comment?.text ||
                "Here is my feedback and comments to this creator and the director."}
            </div>
          )}
        </p>
      </div>
    </div>
  );
}

function ContentCreatorInfoTab({ creatorData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = useSelector((state) => state.user);

  const mediaForReviewCurrentIndex = location.state?.mediaForReviewCurrentIndex;
  const contentDetail =
    location.state?.mediaForReview?.[mediaForReviewCurrentIndex];
  const isSubmited =
    location.state?.commissionToDisplay.status !== "in_progress";

  const handleEdit = (toEdit) => {
    navigate(`/${userType}/project/upload?type=edit`, {
      state: {
        contentData: { ...contentDetail, type: "edit", isSubmited },
        pageProps: { pageTitle: "Edit" },
      },
    });
  };

  const Section = ({ title, content, editType }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => {
      setIsExpanded((prev) => !prev);
    };
    return (
      <div>
        <div className="flex items-center justify-between w-full py-4">
          <h6 className="text-primary-black bold">{title}</h6>
          {userType !== "brand" && (
            <OutllinedPrimaryBtn
              label={
                <div className="flex items-center gap-2 w-full">
                  <EditIcon />
                  <div className="pt-1">Edit</div>
                </div>
              }
              className="border-violet text-violet px-2 py-1 rounded-lg w-auto"
              type="button"
              onClick={() => handleEdit(editType)}
            />
          )}
        </div>
        <p className="my-3 relative">
          {isExpanded
            ? content
            : `${content?.slice(0, 200)}${content?.length > 200 ? "..." : ""}`}
          {!isExpanded && content && content.length > 200 && (
            <div className="absolute top-0 h-full w-full bg-gradient-to-b from-transparent to-white" />
          )}
        </p>
        {content && content?.length > 200 && (
          <div className="w-full">
            <BorderLessButton
              text={isExpanded ? "Read less" : "Read more"}
              className="mx-auto"
              onClick={toggleExpansion}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 h-[60vh] overflow-y-auto">
      <Section
        title="Creator Bio"
        content={creatorData?.creatorBio}
        editType="bio"
      />
      <Section
        title="Inspiration"
        content={creatorData?.creatorInspiration}
        editType="inspiration"
      />
    </div>
  );
}
