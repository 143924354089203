import React from 'react'
import ReplaceIcon from './icons/ReplaceIcon';
import UpdateForNextVersionIcon from './icons/UpdateForNextVersionIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


function CreatorOptions({contentDetail}) {
    const {commissionDetails} = useSelector(state=>state.commissionContent)
    const navigate = useNavigate()
    const location = useLocation()
    const {userType} = useSelector(state=>state.user)
    const imageUrl = contentDetail?.thumbnail_url || contentDetail?.url;
    const originalImageUrl = contentDetail?.url
    const imageName = contentDetail?.id + ".jpeg"
    const commissionToDisplay = location.state?.commissionToDisplay || {};
    const isSubmited = commissionToDisplay?.status === "in_progress" ? false : true;
    const contentVersionType = location.state?.contentVersionType || ''
    
    function handleReplaceImage() {
        navigate(`/${userType}/project/upload?type=edit`, {
            state: { contentData: { ...contentDetail, type: "edit", isSubmited }, actionType: 'replace_image', pageProps: { pageTitle: 'Replace Image' } },
        });
    }

    function handleUpdateForNextVersion() {
        navigate(`/${userType}/project/upload?type=edit`, {
            state: { contentData: { ...contentDetail, type: "edit", isSubmited }, actionType: 'update_for_next_version', pageProps: { pageTitle: 'Update Image for Next Version' }, contentVersionType },
        });
    }

    return (
        <ul className='mb-0 flex gap-4 items-center'>
            <li className={`flex items-center gap-3 p-2 rounded-full  ${commissionDetails.isSubmitted ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => handleReplaceImage({ imageUrl: originalImageUrl || imageUrl, imageName })}>
                <ReplaceIcon /> <span className='text-xl'>Replace image</span>
            </li>
            <li className={`flex items-center gap-3 p-2 rounded-full  ${commissionDetails.isSubmitted ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => handleUpdateForNextVersion({ imageUrl: originalImageUrl || imageUrl, imageName })}>
                <UpdateForNextVersionIcon /> <span className='text-xl'>Update for next version</span>
            </li>
            <span className="font-medium text-xl">{contentDetail?.creator_name || ""}</span>
        </ul>
    )
}

export default CreatorOptions