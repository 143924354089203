import React from 'react'
import { useSelector } from 'react-redux';
import { AuthScreenProvider } from '../contexts/AuthScreenContext';
import Login from '../pages/auth/login/Login';
import Home from '../pages/brand/homePage/Home';
import CreatorCollectionsPage from '../pages/Creator/my-work/show-creator-content/CreatorCollectionsPage';
import PrivateRoute from '../HOC/PrivateRouteLayout';
import RootLayout from '../pages/Root';
import SchoolHomePage from '../pages/school/home/SchoolHomePage';
import ProjectListing from '../pages/Creator/project/ProjectListing';
import { Navigate } from 'react-router-dom';

function HomeComponent() {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    // const [role, setRole] = useState('')
    // const navigate = useNavigate()
    const userRole = localStorage.getItem("userRole");
    const isInvitedBySchool = localStorage.getItem("isInvitedBySchool");
    const redirectRoute = useSelector(state=>state.user.redirectRoute)
    let component = null
    if (!isLoggedIn) {
      return (
        <AuthScreenProvider>
          <Login />
        </AuthScreenProvider>
      ) //if not logged in showing login compoent without any wrappers
    }
  
    //selecting component based on role
    switch (userRole) {
      case "brand":
        component = <Home />
        break;
      case "creator":
        // component = <ProjectListing />
       component = redirectRoute === '/creator/projects' ? <Navigate to={redirectRoute} /> : <CreatorCollectionsPage />
        break;
      case "school":
        component = <SchoolHomePage />
        break;
      default:
        component = <Home />
        break;
    }
    //return the component by wraping in Layout components (privetroute and rootlayout)
    return <PrivateRoute>
      <RootLayout>
        {component}
      </RootLayout>
    </PrivateRoute>
  
  
    // return isLoggedIn ? (userRole == "creator" ? <PrivateRoute>
    //   <RootLayout>
    //     <CreatorCollectionsPage />
    //   </RootLayout>
    // </PrivateRoute> :
    //   <PrivateRoute>
    //     <RootLayout>
    //       <Home />
    //     </RootLayout>
    //   </PrivateRoute>)
    //   : <Login />
  
  }

export default HomeComponent