import React from 'react'

function CompleteIndicator() {
  return (
    <svg width="33" height="33" style={{zIndex:1}} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_210_4550)">
        <path d="M0.5 16.2305C0.5 7.39391 7.66344 0.230469 16.5 0.230469C25.3366 0.230469 32.5 7.39391 32.5 16.2305C32.5 25.067 25.3366 32.2305 16.5 32.2305C7.66344 32.2305 0.5 25.067 0.5 16.2305Z" fill="#F9F5FF" />
        <path d="M1.5 16.2305C1.5 7.9462 8.21573 1.23047 16.5 1.23047C24.7843 1.23047 31.5 7.9462 31.5 16.2305C31.5 24.5147 24.7843 31.2305 16.5 31.2305C8.21573 31.2305 1.5 24.5147 1.5 16.2305Z" fill="#7F56D9" />
        <path d="M1.5 16.2305C1.5 7.9462 8.21573 1.23047 16.5 1.23047C24.7843 1.23047 31.5 7.9462 31.5 16.2305C31.5 24.5147 24.7843 31.2305 16.5 31.2305C8.21573 31.2305 1.5 24.5147 1.5 16.2305Z" stroke="#7F56D9" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2952 10.0837L13.7485 19.297L11.2152 16.5904C10.7485 16.1504 10.0152 16.1237 9.48184 16.497C8.96184 16.8837 8.81518 17.5637 9.13518 18.1104L12.1352 22.9904C12.4285 23.4437 12.9352 23.7237 13.5085 23.7237C14.0552 23.7237 14.5752 23.4437 14.8685 22.9904C15.3485 22.3637 24.5085 11.4437 24.5085 11.4437C25.7085 10.217 24.2552 9.13702 23.2952 10.0704V10.0837Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_210_4550">
          <path d="M0.5 16.2305C0.5 7.39391 7.66344 0.230469 16.5 0.230469C25.3366 0.230469 32.5 7.39391 32.5 16.2305C32.5 25.067 25.3366 32.2305 16.5 32.2305C7.66344 32.2305 0.5 25.067 0.5 16.2305Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CompleteIndicator
    
